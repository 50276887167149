import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Pagination from "../../components/Pagination";
import { NftContract } from "../../utils/readContract";
import nftABI from "../../utils/nft.json";
import { BrowserProvider, Contract, formatUnits, parseUnits, JsonRpcProvider } from "ethers";
import { Context } from "../../context/contex";
import {
 formatNumber,
 shortenAddress,
 showAlert,
 searchArrayBool,
 generateNonce,
 copy,
} from "../../utils/helpers";
import axios from "axios";
import { ethers } from "ethers";

import "../services/services.css";

const contract = "0xEa3d89683BEEb93D600Ba42128f409dA899DfFe5";

function Marketplace() {
 const [approved, setApproved] = useState(false);
 const [name, setName] = useState("");
 const [symbol, setSymbol] = useState("");
 const [newNft, setNewNft] = useState([]);
 const [newProfileNft, setNewProfileNft] = useState([]);
 const [quantity, setQuantity] = useState("");

 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [paginationProfile, setPaginationProfile] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [popularNft, setPopularNft] = useState([]);
 const [myNft, setMyNft] = useState([]);

 const [supply, setSupply] = useState(0);
 const clickRef = useRef(null);
 const [price, setPrice] = useState("");

 const [query, setQuery] = useState("");
 const [results, setResults] = useState([]);
 const [isLoading, setIsLoading] = useState(false);

 const {
  address,

  isConnected,
  walletProvider,
  apiURL,
  domain,
  setLoading,
  settings,
  user,
  getUser,
  tokenPrice,
  nftContract,
  gamer,
  getGamer,
 } = useContext(Context);

 const isApproved = async () => {
  if (!isConnected) return;
  try {
   const contract = await NftContract(nftContract);

   const apr = await contract.isApprovedForAll(address, nftContract);
   // console.log("APPR", apr);

   setApproved(apr);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  isApproved();
 }, [address, nftContract]);

 const getSupply = async () => {
  try {
   const contract = await NftContract(nftContract);

   const res = await contract.totalSupply();
   // console.log("SSS", res.toString());

   setSupply(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSupply();
 }, [nftContract]);

 const getName = async () => {
  try {
   const contract = await NftContract(nftContract);

   const res = await contract.name();
   console.log("CNAME", res.toString());

   setName(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getName();
 }, [nftContract]);

 const getSymbol = async () => {
  try {
   const contract = await NftContract(nftContract);

   const res = await contract.symbol();
   //  console.log("SSS", res.toString());

   setSymbol(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSymbol();
 }, [nftContract]);

 const getNewNft = async (page = 1, limit = 8) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_new_nft18",
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    ///  console.log("NEW", data);
    setNewNft(data.records);
    setPagination(data.pagination);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getNewNft(1);
 }, []);

 const getPopularNft = async () => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_nft18" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularNft(data);
    console.log("All top:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getPopularNft();
 }, []);

 useEffect(() => {
  if (query.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", query);
    //formData.append("type", type);

    formData.append("action", "search_nft18");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setResults([]);
  }
 }, [query]);

 const handleLike = async (nftId) => {
  if (!nftId || nftId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this NFT.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_nft18",
    nft: nftId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    //   await getUser(address);
    await getNewNft();
    await getPopularNft();
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const getPercent = (quantity, supply) => {
  const result = (50 / +supply) * +quantity;
  return result % 1 === 0 ? result : result.toFixed(2);
 };

 async function moveNFT(nftId, from, to, nonce, signature, price) {
  try {
   let signer;
   let cont;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   cont = new Contract(nftContract, nftABI, signer);

   const data = await cont.buyNFT(nftId, from, to, nonce, signature);
   //  console.log("APPROVED", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      await buyNft(from, nftId, price);
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("MOVE ERROR", error);

   showAlert({
    title: "ERROR!",
    text: "There was a problem moving the NFT to your wallet. Please try again or contact support.",
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 }

 const signMessage = async (owner, nftId, price) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!nftId) {
   return showAlert({
    title: "Error!",
    text: "NFT is missing.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+user.credits < +price && +gamer.credits < +price) {
   return showAlert({
    title: "Error!",
    text:
     "You do not have enough v18plus in your account for this purchase. Please top up and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (owner === address) {
   return showAlert({
    title: "Error!",
    text: "You cannot buy your own NFT.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  try {
   const wallet = new ethers.Wallet(settings.project_id);
   const nonce = generateNonce();
   // Create message hash
   const messageHash = ethers.solidityPackedKeccak256(
    ["uint256", "address", "address", "string"],
    [nftId, owner, address, nonce]
   );

   // Sign the message hash
   const signature = await wallet.signMessage(ethers.toBeArray(messageHash));
   // setSignedMessage(signature);
   // console.log("SIG", signature);
   // Call smart contract function with the signed message and balance
   await moveNFT(nftId, owner, address, nonce, signature, price);
  } catch (error) {
   console.error("Error signing message:", error);
  }
 };

 const buyNft = async (from, nftId, price) => {
  // Construct form data
  const formData = new FormData();
  formData.append("from", from);
  formData.append("to", address);

  formData.append("price", price);
  formData.append("id", nftId);

  formData.append("action", "buy_nft18");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   // Handle response
   if (response.data.success) {
    close();
    await getNewNft();
    await getPopularNft();
    await getUser(address);
    await getGamer(address);
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 const close = () => {
  const buttons = document.querySelectorAll(".close-btn");
  buttons.forEach((button) => button.click());
 };

 const getNewProfileNft = async (page = 1, limit = 8) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_new_profile_nft",
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setNewProfileNft(data.records);
    setPaginationProfile(data.pagination);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getNewProfileNft(1);
 }, []);

 const likeProfileNft = async (nftId) => {
  if (!nftId || nftId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this NFT.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_profile_nft",
    nft: nftId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    //   await getUser(address);
    await getNewProfileNft(1);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const getPercentProfileNft = (quantity, supply) => {
  const result = (50 / +supply) * +quantity;
  return result % 1 === 0 ? result : result.toFixed(2);
 };

 const buyProfileNft = async (
  name,
  symbol,
  creator,
  nftId,
  quantity,
  price,
  supply,
  owned,
  image
 ) => {
  // Check if wallet is connected
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!quantity || isNaN(quantity) || quantity === 0) {
   return showAlert({
    title: "Error!",
    text: `Quantity is invalid.`,
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+quantity > +supply || +quantity > +supply - +owned) {
   return showAlert({
    title: "Error!",
    text: "Quantity exceeds available supply.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+user.credits < +quantity * +price && +gamer.credits < +quantity * +price) {
   return showAlert({
    title: "Error!",
    text:
     "You do not have enough v18plus in your account for this purchase. Please top up and try again or reduce the quantity.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (creator === address) {
   return showAlert({
    title: "Error!",
    text: "You cannot buy your own NFT.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  // Construct form data
  const formData = new FormData();
  formData.append("name", name);
  formData.append("symbol", symbol);

  formData.append("wallet", address);
  formData.append("quantity", quantity);
  formData.append("amount", String(+quantity * +price));
  formData.append("percent", getPercentProfileNft(quantity, supply));
  formData.append("creator", creator);
  formData.append("nft_id", nftId);
  formData.append("image_link", image);

  formData.append("action", "buy_profile_nft");
  setLoading(true);
  try {
   // Debug FormData contents
   // console.log("FormData:", [...formData]);

   // Make the API call
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   // Handle response
   if (response.data.success) {
    await getUser(address);
    await getNewProfileNft();
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="carousel-container">
       <img
        className="d-block w-100 carousel-image"
        src={domain + "assets/images/slides/invest-in-a-creator.jpg"}
       />
      </div>
      {/*
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: `url(${domain}assets/images/nft_18.png)`,
        }}
       >
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <div className="row d-flex align-items-center">
          <div className="col-md-6">
           <h1 style={{ fontWeight: "800", fontSize: "40px" }}>{symbol} Marketplace</h1>
           <div className="description">
            All NFTs are unique and only <b>one copy</b> exists off-chain and on-chain. You can buy,
            sell/re-sell them here or on any marketplace supporting NFTs on
            <b>Binance Smart Chain.</b> Your owned NFTs will also be available as
            <b>Ai models</b> to use around this website.
           </div>
          </div>
          <div className="col-md-6">
           <video
            controls
            width="100%"
            // height="660px"

            onError={(e) => console.error("Error loading video:", e)}
            className="mt-3"
           >
            <source src={domain + "uploads/explainer/nft.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
           </video>
          </div>
          <div className="col-md-12">
           <div className="d-flex mt-5 text-white">
            {symbol} contract:
            <input
             className="form-control"
             style={{
              backgroundColor: "rgba(0, 0, 0, .3)",
              color: "#fff",
              //width: "100%",
              height: "60px",
              border: "#aaa 1px solid",
             }}
             type="text"
             value={contract}
             readOnly
            />
            <button className="btn btn-custom" onClick={() => copy(contract)}>
             <i className="fa fa-clone" aria-hidden="true"></i>
            </button>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      */}
      <div className="site-content layout-1">
       <div className="container">
        <div className="row">
         <div className="col-md-2" />
         <div className="col-md-8">
          <input
           placeholder="Search NFTs by creator's wallet or NFT ID"
           className="form-control my-5"
           style={{
            backgroundColor: "#000",
            color: "#fff",
            width: "100%",
            height: "60px",
            border: "#aaa 1px solid",
           }}
           type="search"
           value={query}
           onChange={(e) => setQuery(e.target.value)}
          />
          {!isLoading && (
           <div>
            {results.length > 0 &&
             results.map((p, i) => (
              <>
               <div className="card nft-card">
                <div className="card-body">
                 <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                  <img
                   src={domain + p.image_paths}
                   className="card-img-topa"
                   alt={name}
                   width={150}
                  />
                  <div>
                   <h5 className="card-title">
                    {name} #{p.id}
                   </h5>

                   <p className="price">
                    {formatNumber(p.price)} v18plus{" "}
                    <span className="text-primary">
                     ($
                     {formatNumber(Math.round(tokenPrice * p.price))})
                    </span>
                   </p>
                   <div className="text-center mt-1">
                    <button
                     className="btn btn-custom"
                     data-toggle="modal"
                     data-target={`#searchModal-${i}`}
                     disabled={!isConnected || address === p.wallet || p.owned_supply >= p.supply}
                    >
                     Buy this NFT
                    </button>
                   </div>
                  </div>
                 </div>
                </div>
               </div>
               {/*BUY NFT modal*/}
               <div
                className="modal fade"
                id={`searchModal-${i}`}
                tabIndex={-1}
                role="dialog"
                aria-labelledby={`sModalLabel-${i}`}
                aria-hidden="true"
               >
                <div className="modal-dialog modal-md" role="document">
                 <div className="modal-content">
                  <div className="modal-header">
                   <h3 className="modal-title" id={`sModalLabel-${i}`}>
                    Buy NFT
                   </h3>
                   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                   </button>
                  </div>
                  <div className="modal-body">
                   You are buying
                   <b>
                    {" "}
                    {name} #{p.id}
                   </b>{" "}
                   for {formatNumber(p.price)} v18plus ($
                   {formatNumber(Math.round(tokenPrice * p.price))}) and your v18plus balance is{" "}
                   {formatNumber(user.credits)} ($
                   {formatNumber(Math.round(tokenPrice * user.credits))}
                   ). Hit the PROCEED button to continue.{" "}
                   <b>
                    We will charge from your airdrop token balance if you have enough to cover the
                    cost.
                   </b>
                  </div>

                  <div className="modal-footer">
                   <button
                    type="button"
                    className="btn btn-custom"
                    onClick={() => signMessage(p.wallet, p.id, p.price)}
                   >
                    Proceed
                   </button>

                   <button
                    type="button"
                    className="btn btn-secondary close-btn"
                    data-dismiss="modal"
                   >
                    Close
                   </button>
                  </div>
                 </div>
                </div>
               </div>
              </>
             ))}
           </div>
          )}
          {isLoading && <p>Loading...</p>}
         </div>
         <div className="col-md-2" />
        </div>
       </div>

       <div className="container-fluid">
        <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
         <div className="col-md-5">
          <div className="text-center">
           <h1 style={{ fontWeight: "700", fontSize: "60px", fontFamily: "Jura" }}>LATEST DROPS</h1>
          </div>
         </div>
         <div className="col-md-7">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
        </div>

        {/*NFTS START*/}
        <div>
         <div className="row mt-4">
          {newNft.length > 0 ? (
           newNft.map((p, i) => (
            <div key={i} className="col-md-3">
             <div className="card nft-card">
              <img src={domain + p.image_paths} className="card-img-top" alt="NFT" />
              <div className="card-body">
               <div className="d-flex justify-content-between align-items-center">
                <div>
                 <img src={domain + p.profile_photo} className="avatar" alt="Avatar" />
                 <span className="badge badge-secondary">
                  By{" "}
                  <a href={`profile?user=${!p.username ? p.wallet : p.username}`} target="_blank">
                   {!p.username ? shortenAddress(p.wallet) : p.username}
                  </a>{" "}
                  {p.verified === "yes" && (
                   <i className="fa fa-check-circle text-success" aria-hidden="true" />
                  )}
                 </span>
                </div>
               </div>
               <h5 className="card-title">
                {name} #{p.id}
               </h5>

               <p className="price">
                {formatNumber(p.price)} v18plus{" "}
                <span className="text-primary">
                 ($
                 {formatNumber(Math.round(tokenPrice * p.price))})
                </span>
               </p>
               <div
                className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
               >
                <button
                 onClick={() => handleLike(p.id)}
                 className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
                >
                 <i className="fa fa-heart" />{" "}
                 {searchArrayBool(p.liked_by_users, address) ? "Unlike" : "Like"}
                </button>{" "}
                <button
                 className="text-primary ml-2"
                 data-toggle="modal"
                 data-target={`#likeModal-${i}`}
                >
                 {Math.round(formatNumber(p.like_count))}
                </button>
               </div>

               <div className="text-center mt-1">
                <button
                 className="btn btn-custom"
                 data-toggle="modal"
                 data-target={`#buyModal-${i}`}
                 disabled={!isConnected || address === p.wallet}
                >
                 Buy this NFT
                </button>
               </div>
              </div>
             </div>
             {/*BUY NFT modal*/}
             <div
              className="modal fade"
              id={`buyModal-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`buyModalLabel-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-md" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`likeModalLabel-${i}`}>
                  Buy NFT
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 You are buying
                 <b>
                  {" "}
                  {name} #{p.id}
                 </b>{" "}
                 for {formatNumber(p.price)} v18plus ($
                 {formatNumber(Math.round(tokenPrice * p.price))}) and your v18plus balance is{" "}
                 {formatNumber(user.credits)} ($
                 {formatNumber(Math.round(tokenPrice * user.credits))}
                 ). Hit the PROCEED button to continue.{" "}
                 <b>
                  We will charge from your airdrop token balance if you have enough to cover the
                  cost.
                 </b>
                </div>

                <div className="modal-footer">
                 <button
                  type="button"
                  className="btn btn-custom"
                  onClick={() => signMessage(p.wallet, p.id, p.price)}
                 >
                  Proceed
                 </button>

                 <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
             {/*LIKES modal*/}
             <div
              className="modal fade"
              id={`likeModal-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`likeModalLabel-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-sm" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`likeModalLabel-${i}`}>
                  NFT Likes
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 {p.liked_by_users.length > 0 &&
                  p.liked_by_users.map((u, index) => (
                   <div key={index} className="d-flex align-items-center p-2">
                    <img
                     src={domain + u.profile_photo}
                     style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                     }}
                    />
                    <span className="ml-1">
                     {" "}
                     <a
                      href={`profile?user=${!u.username ? u.wallet : u.username}`}
                      style={{ color: "#fff" }}
                      target="_blank"
                     >
                      {!u.username ? shortenAddress(u.wallet) : u.username}
                     </a>{" "}
                    </span>
                   </div>
                  ))}
                </div>

                <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
            </div>
           ))
          ) : (
           <div className="text-center">
            <img src="assets/images/fly.gif" height={100} />
            <p>No NFTs here yet...</p>
           </div>
          )}
         </div>

         {/* Repeat for other cards */}
         {newNft.length > 0 && (
          <Pagination
           currentPage={pagination.current_page}
           totalPages={pagination.total_pages}
           onPageChange={(page) => getNewNft(page)}
          />
         )}
        </div>
        {/*NFTS ENDS*/}
       </div>

       <div className="container-fluid">
        <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
         <div className="col-md-7">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
         <div className="col-md-5">
          <div className="text-center">
           <h1 style={{ fontWeight: "700", fontSize: "60px", fontFamily: "Jura" }}>
            TRENDING NFTs
           </h1>
          </div>
         </div>
        </div>
        <div>
         <div className="row mt-4">
          {popularNft.length > 0 ? (
           popularNft.map((p, i) => (
            <div key={i} className="col-md-3">
             <div className="card nft-card">
              <img src={domain + p.image_paths} className="card-img-top" alt="NFT" />
              <div className="card-body">
               <div className="d-flex justify-content-between align-items-center">
                <div>
                 <img src={domain + p.profile_photo} className="avatar" alt="Avatar" />
                 <span className="badge badge-secondary">
                  By{" "}
                  <a href={`profile?user=${!p.username ? p.wallet : p.username}`} target="_blank">
                   {!p.username ? shortenAddress(p.wallet) : p.username}
                  </a>{" "}
                  {p.verified === "yes" && (
                   <i className="fa fa-check-circle text-success" aria-hidden="true" />
                  )}
                 </span>
                </div>
               </div>
               <h5 className="card-title">
                {name} #{p.id}
               </h5>

               <p className="price">
                {formatNumber(p.price)} v18plus{" "}
                <span className="text-primary">
                 ($
                 {formatNumber(Math.round(tokenPrice * p.price))})
                </span>
               </p>
               <div
                className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
               >
                <button
                 onClick={() => handleLike(p.id)}
                 className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
                >
                 <i className="fa fa-heart" />{" "}
                 {searchArrayBool(p.liked_by_users, address) ? "Unlike" : "Like"}
                </button>{" "}
                <button
                 className="text-primary ml-2"
                 data-toggle="modal"
                 data-target={`#plikeModal-${i}`}
                >
                 {Math.round(formatNumber(p.like_count))}
                </button>
               </div>

               <div className="text-center mt-1">
                <button
                 className="btn btn-custom"
                 data-toggle="modal"
                 data-target={`#pbuyModal-${i}`}
                 disabled={!isConnected || address === p.wallet}
                >
                 Buy this NFT
                </button>
               </div>
              </div>
             </div>
             {/*BUY NFT modal*/}
             <div
              className="modal fade"
              id={`pbuyModal-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`pbuyModalLabel-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-md" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`pbuyModalLabel-${i}`}>
                  Buy NFT
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 You are buying
                 <b>
                  {" "}
                  {name} #{p.id}
                 </b>{" "}
                 for {formatNumber(p.price)} v18plus ($
                 {formatNumber(Math.round(tokenPrice * p.price))}) and your v18plus balance is{" "}
                 {formatNumber(user.credits)} ($
                 {formatNumber(Math.round(tokenPrice * user.credits))}
                 ). Hit the PROCEED button to continue.{" "}
                 <b>
                  We will charge from your airdrop token balance if you have enough to cover the
                  cost.
                 </b>
                </div>

                <div className="modal-footer">
                 <button
                  type="button"
                  className="btn btn-custom"
                  onClick={() => signMessage(p.wallet, p.id, p.price)}
                 >
                  Proceed
                 </button>

                 <button type="button" className="btn btn-secondary close-btn" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
             {/*LIKES modal*/}
             <div
              className="modal fade"
              id={`plikeModal-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`plikeModalLabel-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-sm" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`plikeModalLabel-${i}`}>
                  NFT Likes
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 {p.liked_by_users.length > 0 &&
                  p.liked_by_users.map((u, index) => (
                   <div key={index} className="d-flex align-items-center p-2">
                    <img
                     src={domain + u.profile_photo}
                     style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                     }}
                    />
                    <span className="ml-1">
                     {" "}
                     <a
                      href={`profile?user=${!u.username ? u.wallet : u.username}`}
                      style={{ color: "#fff" }}
                      target="_blank"
                     >
                      {!u.username ? shortenAddress(u.wallet) : u.username}
                     </a>{" "}
                    </span>
                   </div>
                  ))}
                </div>

                <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
            </div>
           ))
          ) : (
           <div className="text-center">
            <img src="assets/images/fly.gif" height={100} />
            <p>No NFTs here yet...</p>
           </div>
          )}

          {/* Repeat for other cards */}
         </div>
        </div>
       </div>

       <div className="container-fluid">
        <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
         <div className="col-md-6">
          <div className="text-center">
           <h1 style={{ fontWeight: "700", fontSize: "50px", fontFamily: "Jura" }}>
            INVEST IN A CREATOR
           </h1>
          </div>
         </div>
         <div className="col-md-6">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
        </div>
        <div>
         <div className="row mt-4">
          {newProfileNft.length > 0 ? (
           newProfileNft.map((p, i) => (
            <div key={i} className="col-md-3">
             <div className="card nft-card">
              <img src={domain + p.image} className="card-img-top" alt="NFT" />
              <div className="card-body">
               <div className="d-flex justify-content-between align-items-center">
                <div>
                 <img src={domain + p.profile_photo} className="avatar" alt="Avatar" />
                 <span className="badge badge-secondary">
                  By{" "}
                  <a href={`profile?user=${!p.username ? p.wallet : p.username}`} target="_blank">
                   {!p.username ? shortenAddress(p.wallet) : p.username}
                  </a>{" "}
                  {p.verified === "yes" && (
                   <i className="fa fa-check-circle text-success" aria-hidden="true" />
                  )}
                 </span>
                </div>
               </div>
               <h5 className="card-title">
                {p.name} ({p.symbol})
               </h5>
               <p className="card-text">
                {p.owned_supply} / {p.supply} holders
               </p>
               <p className="price">
                {formatNumber(p.price)} v18plus{" "}
                <span className="text-primary">
                 ($
                 {formatNumber(Math.round(tokenPrice * p.price))})
                </span>
               </p>
               <div
                className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
               >
                <button
                 onClick={() => likeProfileNft(p.id)}
                 className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
                >
                 <i className="fa fa-heart" />{" "}
                 {searchArrayBool(p.liked_by_users, address) ? "Unlike" : "Like"}
                </button>{" "}
                <button
                 className="text-primary ml-2"
                 data-toggle="modal"
                 data-target={`#likeModalPNFT-${i}`}
                >
                 {Math.round(formatNumber(p.like_count))}
                </button>
               </div>

               <div className="text-center mt-1">
                <button
                 className="btn btn-custom"
                 data-toggle="modal"
                 data-target={`#buyModalPNFT-${i}`}
                 disabled={!isConnected || address === p.wallet}
                >
                 Buy this NFT
                </button>
               </div>
              </div>
             </div>
             {/*BUY NFT modal*/}
             <div
              className="modal fade"
              id={`buyModalPNFT-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`buyModalLabelPNFT-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-md" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`likeModalLabelPNFT-${i}`}>
                  Buy NFT
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 You are buying<b> {p.name}</b> ({p.symbol}) for {formatNumber(p.price)} v18plus ($
                 {formatNumber(Math.round(tokenPrice * p.price))}) each and your v18plus balance is{" "}
                 {formatNumber(user.credits)} ($
                 {formatNumber(Math.round(tokenPrice * user.credits))}
                 ). Enter the quantity you want to buy and hit the PROCEED button.{" "}
                 <b>
                  We will charge from your airdrop token balance if you have enough to cover the
                  cost.
                 </b>
                 <div className="my-4">
                  You can buy up to {+p.supply - +p.owned_supply} {p.symbol} max.
                  <input
                   placeholder="Quantity"
                   className="form-control my-5"
                   style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    width: "100%",
                    height: "60px",
                    border: "#aaa 1px solid",
                   }}
                   type="number"
                   value={quantity}
                   onChange={(e) => setQuantity(e.target.value)}
                  />
                  {quantity && (
                   <>
                    You will earn {getPercent(quantity, p.supply)}% share from{" "}
                    {!p.username ? shortenAddress(p.wallet) : p.username}
                    's subscription fees
                   </>
                  )}
                 </div>
                </div>

                <div className="modal-footer">
                 {quantity && (
                  <>
                   <button
                    type="button"
                    className="btn btn-custom"
                    onClick={() =>
                     buyProfileNft(
                      p.name,
                      p.symbol,
                      p.wallet,
                      p.id,
                      quantity,
                      p.price,
                      p.supply,
                      p.owned_supply,
                      p.image
                     )
                    }
                   >
                    Proceed to pay {formatNumber(+p.price * +quantity)} v18plus
                   </button>
                  </>
                 )}
                 <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
             {/*LIKES modal*/}
             <div
              className="modal fade"
              id={`likeModalPNFT-${i}`}
              tabIndex={-1}
              role="dialog"
              aria-labelledby={`likeModalLabelPNFT-${i}`}
              aria-hidden="true"
             >
              <div className="modal-dialog modal-sm" role="document">
               <div className="modal-content">
                <div className="modal-header">
                 <h3 className="modal-title" id={`likeModalLabelPNFT-${i}`}>
                  NFT Likes
                 </h3>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                 </button>
                </div>
                <div className="modal-body">
                 {p.liked_by_users.length > 0 &&
                  p.liked_by_users.map((u, index) => (
                   <div key={index} className="d-flex align-items-center p-2">
                    <img
                     src={domain + u.profile_photo}
                     style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                     }}
                    />
                    <span className="ml-1">
                     {" "}
                     <a
                      href={`profile?user=${!u.username ? u.wallet : u.username}`}
                      style={{ color: "#fff" }}
                      target="_blank"
                     >
                      {!u.username ? shortenAddress(u.wallet) : u.username}
                     </a>{" "}
                    </span>
                   </div>
                  ))}
                </div>

                <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                 </button>
                </div>
               </div>
              </div>
             </div>
            </div>
           ))
          ) : (
           <div className="text-center">
            <img src="assets/images/fly.gif" height={100} />
            <p>No NFTs here yet...</p>
           </div>
          )}
         </div>
         {/* Repeat for other cards */}
         {newProfileNft.length > 0 && (
          <Pagination
           currentPage={paginationProfile.current_page}
           totalPages={paginationProfile.total_pages}
           onPageChange={(page) => getNewProfileNft(page)}
          />
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}
export default Marketplace;

import React, { useState, useContext, useEffect, useRef } from "react";
import { Context } from "../context/contex";
import { formatNumber, showAlert, calculateTokenDeduction } from "../utils/helpers";
import axios from "axios";

import CryptoJS from "crypto-js";
import { Card, Button, Row, Col, Container } from "react-bootstrap";

function NewSwap() {
 const [matId, setMatId] = useState("");
 const [matType, setMatType] = useState("");
 const [stage, setStage] = useState("");
 const [uploadProgress, setUploadProgress] = useState(0);
 const [cost, setCost] = useState(0);
 const [duration, setDuration] = useState(0);

 const [originalFaces, setOriginalFaces] = useState([]);

 const [replacementFaces, setReplacementFaces] = useState([]);
 const [balance, setBalance] = useState(null);
 const [output, setOutput] = useState({});

 const [swapPhotoPath, setSwapPhotoPath] = useState(null);

 const [selectedOriginals, setSelectedOriginals] = useState([]);
 const [facePairs, setFacePairs] = useState([]);

 const {
  address,
  tokenPrice,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  loading,
  setLoading,
  settings,
  user,
  payForService,
  domain,
  gamer,
 } = useContext(Context);

 const apiKey = "5d5gj6428aa9ss65g1s6f92s1b4s59th";
 const secretKey = "6d3gh9b3g6ew3s6s9gbd37bb9be5d2nm";
 const ts = Date.now() + 10000;
 const swapBase = "https://api.swaptech.net/";

 const generateSig = (uri, method, query = "", body = "") => {
  if (method === "GET") {
   const msg = Date.now() + method + uri + query;
   const hash = CryptoJS.HmacSHA256(msg, secretKey).toString(CryptoJS.enc.Hex);
   return hash;
  } else if (method === "POST") {
   const msg = Date.now() + method + uri + query + JSON.stringify(body);
   const hash = CryptoJS.HmacSHA256(msg, secretKey).toString(CryptoJS.enc.Hex);
   return hash;
  }
 };

 const getUserImages = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_generated_images",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    // refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setReplacementFaces(data);
    // console.log("IMAGES:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserImages(address);
 }, [address, isConnected]);

 const refreshSwap = () => {
  setSwapPhotoPath("");
  setStage("");
  setMatId("");
  setMatType("");
  setOriginalFaces([]);
  setFacePairs([]);
  setSelectedOriginals([]);
  setOutput({});
  setCost(0);
  setDuration(0);
 };

 const handleSwapUpload = async (e) => {
  const content = e.target.files[0];
  if (!content) {
   showAlert({
    title: "Error!",
    text: "Please select an image or a video to upload",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (content.type.startsWith("video/")) {
   const valid = await validateVideo(content);
   if (!valid) {
    showAlert({
     title: "Error!",
     text:
      "Your video must be less than 120 seconds and should have a reasonably small file size to avoid delays.",
     icon: "error",
     confirmButtonText: "Ok",
    });
    return;
   }
   const duration = await getVideoDuration(content);
   if (duration > 0) {
    setDuration(duration);
   }
  } else {
   setDuration(0);
  }

  setMatId("");
  setMatType("");
  setStage("");
  setOriginalFaces([]);
  setFacePairs([]);
  setSelectedOriginals([]);
  setOutput({});
  setLoading(true);

  const formData = new FormData();
  formData.append("image", content);
  formData.append("action", "upload_swap_face");

  try {
   setStage("UPLOADING ORIGINAL FACE");
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });

   if (response.data.path) {
    setStage("ANALYZING CONTENT");

    const payload = {
     fileId: null,
     fileUrl: response.data.path,
     ttl: 3600000,
     callbackUrl: null,
     clientRequestId: String(Date.now()),
    };
    // console.log("PAYLOAD", payload);

    const dataS = {
     payload: JSON.stringify(payload),
    };
    // console.log("DATAS", dataS);

    const headers = {
     X_API_KEY: apiKey,
     X_API_SIGN: generateSig("/v1/materials", "POST", "", dataS),
     X_API_TS: ts,
     "Content-Type": "application/json",
    };
    // console.log("HEADERS", headers);

    const materialRes = await axios.post(`${swapBase}v1/materials`, dataS, { headers });

    if (materialRes.data.code === 0 && materialRes.data.data.materialId) {
     const materialId = materialRes.data.data.materialId;

     setMatId(materialId);
     // console.log("GOT MAT ID", materialId);

     // Start polling for status
     pollStatus(response.data.path, materialId);
    } else {
     // console.log("NO MAT ID", materialRes.data);
     setStage("");
     showAlert({
      title: "Error!",
      text: materialRes.data.msg || "Failed to retrieve material ID.",
      icon: "error",
      confirmButtonText: "Ok",
     });
     setLoading(false);
    }
   } else {
    setStage("");
    showAlert({
     title: "Error!",
     text: response.data.error,
     icon: "error",
     confirmButtonText: "Ok",
    });
    setLoading(false);
   }
  } catch (error) {
   setStage("");
   console.error("Error uploading the file:", error);
   showAlert({
    title: "Error!",
    text: "Error uploading the file. Please try again later.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   setLoading(false);
  }
  setUploadProgress(0);
 };

 // Poll for the preprocessing status
 const pollStatus = async (path, materialId, attempt = 0, maxAttempts = 100) => {
  if (attempt >= maxAttempts) {
   showAlert({
    title: "Timeout",
    text: "Processing took too long. Please try again with a smaller video file size.",
    icon: "warning",
    confirmButtonText: "Ok",
   });
   setStage("");

   return;
  }

  if (!materialId) {
   showAlert({
    title: "Timeout",
    text: "Invalid material ID",
    icon: "warning",
    confirmButtonText: "Ok",
   });
   return;
  }
  setLoading(true);
  try {
   const faceCheckHeaders = {
    X_API_KEY: apiKey,
    X_API_SIGN: generateSig(`/v1/materials/${materialId}`, "GET"),
    X_API_TS: ts,
    "Content-Type": "application/json",
   };
   setStage(`DETECTING FACES - ${attempt} of ${maxAttempts} attempts.`);

   const faceData = await axios.get(`${swapBase}v1/materials/${materialId}`, {
    headers: faceCheckHeaders,
   });
   const resp = faceData.data;
   // console.log("FACE RESP", resp);
   if (resp.code === 0 && resp.data.status === "PREPROCESSED_SUCCEED") {
    // console.log("DETECTED FACES", resp.data.parsedFaces);
    setStage(`DETECTED ${resp.data.parsedFaces.length} FACES`);

    setOriginalFaces(resp.data.parsedFaces);
    setMatType(resp.data.materialType);
    setSwapPhotoPath(path);
   } else if (resp.errorCode === 100 || (resp.data && resp.data.status !== "PREPROCESSED_FAILED")) {
    // Status is still processing, retry after delay
    //  console.log("RETRY");

    setTimeout(() => pollStatus(path, materialId, attempt + 1), 5000);
   } else if (resp.data && resp.data.status === "PREPROCESSED_FAILED") {
    console.log("FAILED", resp.data);
    setStage("");
    showAlert({
     title: "Error",
     text: resp.data.failReason || "Face detection failed.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   } else {
    //  console.log("RESP", resp);
    setStage("");
    showAlert({
     title: "Error",
     text: resp.errorMsg || "Material ID might be wrong.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error checking face data:", error);
  }
  setLoading(false);
 };

 // Toggle original face selection
 const handleOriginalSelection = (index) => {
  setSelectedOriginals((prev) =>
   prev.includes(index) ? prev.filter((selectedIndex) => selectedIndex !== index) : [...prev, index]
  );
 };

 // Handle replacement face selection
 const handlePairSelection = (originalIndex, replacementUrl) => {
  setFacePairs((prevPairs) => {
   const exists = prevPairs.some((pair) => pair.index === originalIndex);

   if (exists) {
    // Replace the existing pair
    return prevPairs.map((pair) =>
     pair.index === originalIndex ? { index: originalIndex, faceUrl: replacementUrl } : pair
    );
   } else {
    // Add a new pair
    return [...prevPairs, { index: originalIndex, faceUrl: replacementUrl }];
   }
  });
 };

 const removePair = (originalIndex) => {
  setFacePairs((prevPairs) => prevPairs.filter((pair) => pair.index !== originalIndex));
 };

 const validateVideo = (file) => {
  return new Promise((resolve, reject) => {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";

   videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;

    if (duration > 120) {
     resolve(false); // Video is too long
    } else {
     resolve(true); // Video duration is valid
    }
   };

   videoElement.onerror = function () {
    reject(new Error("Error loading video metadata"));
   };

   videoElement.src = URL.createObjectURL(file);
  });
 };

 const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";

   videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;

    if (duration > 0) {
     resolve(duration); // Resolve with the video duration
    } else {
     resolve(0); // Resolve with 0 if duration is invalid
    }
   };

   videoElement.onerror = function () {
    reject(new Error("Error loading video metadata"));
   };

   videoElement.src = URL.createObjectURL(file);
  });
 };

 useEffect(() => {
  if (facePairs.length === 0 || !matType || (matType === "VIDEO" && duration === 0)) {
   setCost(0);
   return;
  }
  const c = calculateTokenDeduction(
   tokenPrice,
   matType === "VIDEO" ? settings.swap_face_fee : +settings.swap_image_fee * facePairs.length,
   duration
  );
  setCost(c);
 }, [facePairs, matType, duration]);

 const startSwap = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+cost <= 0) {
   showAlert({
    title: "Error!",
    text: "Could not calculate cost.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +cost && +gamer.credits < +cost) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for swap. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!matId || !matType) {
   showAlert({
    title: "Error!",
    text: "Original image/video is missing. Please re-upload.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (facePairs.length === 0 || facePairs.length > 6) {
   showAlert({
    title: "Error!",
    text: "Invalid face pairs. It should contain a minimum or 1 pair and a maximum of 6 pairs.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  setLoading(true);

  try {
   setLoading(true);
   setStage("FACE SWAP STARTED");

   const payload = {
    materialId: matId,
    facePairs: facePairs,
    ttl: 3600000,
    priority: "HIGH",
    clientRequestId: String(Date.now()),
    callbackUrl: null,
   };

   // console.log("SWAP PAYLOAD", payload);

   const dataS = {
    payload: JSON.stringify(payload),
   };
   // console.log("DATAS", dataS);

   const headers = {
    X_API_KEY: apiKey,
    X_API_SIGN: generateSig("/v1/tasks", "POST", "", dataS),
    X_API_TS: ts,
    "Content-Type": "application/json",
   };
   // console.log("HEADERS", headers);

   const materialRes = await axios.post(`${swapBase}v1/tasks`, dataS, { headers });

   if (materialRes.data.code === 0 && materialRes.data.data.taskId) {
    const taskId = materialRes.data.data.taskId;

    // setMatId(materialId);
    // console.log("GOT TASK ID", taskId);

    // Start polling for status
    pollSwapStatus(taskId);
   } else {
    // console.log("NO SWAP ID", materialRes.data);
    setStage("");
    showAlert({
     title: "Error!",
     text: materialRes.data.msg || "Failed to retrieve task ID.",
     icon: "error",
     confirmButtonText: "Ok",
    });
    setLoading(false);
   }
  } catch (error) {
   setStage("");
   console.error("Error uploading the file:", error);
   showAlert({
    title: "Error!",
    text: "Error swapping the faces. Please try again later or reduce the number of face pairs.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   setLoading(false);
  }
 };

 const pollSwapStatus = async (materialId, attempt = 0, maxAttempts = 100) => {
  if (attempt >= maxAttempts) {
   showAlert({
    title: "Timeout",
    text: "Processing took too long. Please try again with a smaller image/video file size.",
    icon: "warning",
    confirmButtonText: "Ok",
   });
   setStage("");

   return;
  }

  if (!materialId) {
   showAlert({
    title: "Timeout",
    text: "Invalid task ID",
    icon: "warning",
    confirmButtonText: "Ok",
   });
   return;
  }
  setLoading(true);
  try {
   const faceCheckHeaders = {
    X_API_KEY: apiKey,
    X_API_SIGN: generateSig(`/v1/tasks/${materialId}`, "GET"),
    X_API_TS: ts,
    "Content-Type": "application/json",
   };
   setStage(`FETCHING SWAP RESULT - ${attempt} of ${maxAttempts} attempts.`);

   const faceData = await axios.get(`${swapBase}v1/tasks/${materialId}`, {
    headers: faceCheckHeaders,
   });
   const resp = faceData.data;
   // console.log("FACE SWAP RESP", resp);
   if (resp.code === 0 && resp.data.status === "SUCCEED") {
    // console.log("FINAL FACES", resp.data.fileUrl);

    const d = await payForService(cost);
    if (d) {
     setOutput(resp.data);
     setStage(``);
     setCost(0);
    }

    // console.log("OUTPUT", resp.data);
   } else if (resp.errorCode === 100 || (resp.data && resp.data.status !== "FAILED")) {
    // Status is still processing, retry after delay
    //  console.log("RETRY");

    setTimeout(() => pollSwapStatus(materialId, attempt + 1), 5000);
   } else if (resp.data && resp.data.status === "FAILED") {
    console.log("FAILED", resp.data);
    setStage("");
    showAlert({
     title: "Error",
     text: resp.data.failReason || "Face swap failed.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   } else {
    //  console.log("RESP", resp);
    setStage("");
    showAlert({
     title: "Error",
     text: resp.errorMsg || "Task ID might be wrong.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error checking face data:", error);
  }
  setLoading(false);
 };

 const downloadAndSave = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!output.fileUrl || !matType) {
   showAlert({
    title: "Error!",
    text: "File URL or type is missing. This content can not be saved.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  setLoading(true);
  try {
   // Step 2: Create a FormData object
   const formData = new FormData();

   formData.append("action", "upload_and_save_swap");
   formData.append("wallet", address);
   formData.append("type", matType);
   formData.append("file_url", output.fileUrl);

   // Step 3: Upload and save the file URL in one step
   const serverResponse = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (serverResponse.data.success) {
    showAlert({
     title: "Success",
     text: serverResponse.data.message,
     icon: "success",
     confirmButtonText: "Ok",
    });
    refreshSwap();
    // console.log("File uploaded and saved successfully:", serverResponse.data.message);
   } else {
    showAlert({
     title: "Error",
     text: serverResponse.data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
    console.error("Failed to upload and save file:", serverResponse.data);
   }
  } catch (error) {
   console.error("An error occurred:", error.message);
  }
  setLoading(false);
 };

 const getAccountBalance = async () => {
  setLoading(true);
  try {
   const faceCheckHeaders = {
    X_API_KEY: apiKey,
    X_API_SIGN: generateSig(`/v1/accounts/me/balance`, "GET"),
    X_API_TS: ts,
    "Content-Type": "application/json",
   };

   const faceData = await axios.get(`${swapBase}v1/accounts/me/balance`, {
    headers: faceCheckHeaders,
   });
   const resp = faceData.data;
   if (resp.code === 0 && resp.data.balance) {
    console.log("BALANCE", resp.data.balance);
    setBalance(resp.data.balance);
   } else {
    console.log("NO BAL", resp);
   }
  } catch (error) {
   console.error("Error checking face data:", error);
  }
  setLoading(false);
 };

 useEffect(() => {
  getAccountBalance();
 }, []);

 const handleDownload = (Url, type) => {
  const link = document.createElement("a");
  link.href = Url;
  if (type === "VIDEO") {
   link.download = "swapped-video.mp4";
  } else {
   link.download = "swapped-photo.jpg";
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
 };

 return (
  <div>
   <div className="container-fluid">
    <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
     <div className="col-md-5">
      <div className="text-center">
       <h1 style={{ fontWeight: "700", fontSize: "60px", fontFamily: "Jura" }}>HOW IT WORKS</h1>
      </div>
     </div>
     <div className="col-md-7">
      <div style={{ border: "2px #000 solid" }}></div>
     </div>
    </div>

    <div className="row my-5 swap-how">
     <div className="col-md-4">
      <h3>Step 1</h3>
      <div className="text-center rounded">
       Upload the image or video containing the face(s) you want to replace.
      </div>
     </div>
     <div className="col-md-4">
      <h3>Step 2</h3>
      <div className="text-center rounded">
       From the detected faces, select the ones to replace and also pick the faces to replace them
       with from your Ai models.
      </div>
     </div>
     <div className="col-md-4">
      <h3>Step 3</h3>
      <div className="text-center rounded">Press the START SWAP button and wait for the magic.</div>
     </div>
    </div>
   </div>
   <div className="container">
    <div className="tab-content">
     <div id="images" className="container tab-pane active">
      <ul className="stylish-list">
       <li>
        <b className="text-danger">PLEASE NOTE:</b> Uploaded videos should be compressed into small
        file sizes and the video must be less than 120 seconds. The entire swap process may take up
        to 10 minutes to finish.
       </li>
      </ul>
      <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
       <div className="col-md-8">
        <div style={{ border: "2px #000 solid" }}></div>
       </div>
       <div className="col-md-4">
        <div className="">
         <h1 style={{ fontWeight: "700", fontSize: "60px", fontFamily: "Jura" }}>FACE SWAP</h1>
        </div>
       </div>
      </div>
      <div className="row my-4 p-2 rounded alert-danger mx-md-5 mx-sm-1">
       <div className="col-md-12">
        <h3>IMPORTANT NOTICE</h3>
        <p>
         AI is still in its early stages, and there may be instances where the generated content
         could be offensive to human dignity or depict faces that appear youthful. Do not upload
         such content to <b>Modeland</b> or create NFTs from it, as this would violate community and
         platform rules. Such content will be identified and removed by moderators and detected by
         Modeland's AI CheckHub.
        </p>
       </div>
      </div>
      <div className="form-container">
       <>
        {stage && <div className="alert-info text-center my-4">{stage}</div>}
        {uploadProgress > 0 && (
         <div className="col-md-12 my-4">
          <div className="progress">
           <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${uploadProgress}%` }}
            aria-valuenow={uploadProgress}
            aria-valuemin="0"
            aria-valuemax="100"
           >
            {uploadProgress}%
           </div>
          </div>
         </div>
        )}
        <div className="row">
         <div className="col-md-6 p-3">
          <div className="d-flex align-items-center justify-content-center">
           {swapPhotoPath ? (
            <div>
             {matType === "PHOTO" && (
              <img src={swapPhotoPath} style={{ height: "300px", width: "auto" }} />
             )}
             {matType === "VIDEO" && (
              <video
               className="mt-3"
               style={{
                maxWidth: "60%",
                height: "auto",
               }}
               controls
               autoPlay={true}
               muted
               src={swapPhotoPath}
              />
             )}
            </div>
           ) : (
            <div className="form-group text-center">
             <label htmlFor="oFace" style={{ cursor: "pointer" }}>
              <i
               className="fa fa-cloud-upload"
               aria-hidden="true"
               style={{ fontSize: "50px", cursor: "pointer" }}
              ></i>
              <p>Choose original face</p>
             </label>
             <div className="custom-file">
              <input
               // accept="image/*"
               type="file"
               className="custom-file-input"
               id="oFace"
               required
               onChange={handleSwapUpload}
              />
             </div>
            </div>
           )}
          </div>
         </div>
         <div className="col-md-6 p-3">
          <div className="d-flex align-items-center justify-content-center">
           {Object.keys(output).length > 0 ? (
            <div className="text-center">
             {(output.materialType === "PHOTO" || output.materialType === "GIF") && (
              <img src={`${output.fileUrl}`} style={{ height: "300px", width: "auto" }} />
             )}

             {output.materialType === "VIDEO" && (
              <video
               className="mt-3"
               style={{
                maxWidth: "60%",
                height: "auto",
               }}
               controls
               autoPlay={true}
               muted
               src={output.fileUrl}
              />
             )}
             <div
              className="text-center my-4"
              style={{ display: "flex", gap: 10, justifyContent: "center" }}
             >
              <div className="">
               <button className="btn btn-primary m-2" onClick={downloadAndSave}>
                {" "}
                <i className="fa fa-cloud mr-2a" aria-hidden="true" /> SAVE TO PROFILE
               </button>
              </div>
              <div className="">
               <button
                className="btn btn-secondary m-2"
                onClick={() => handleDownload(output.fileUrl, output.materialType)}
               >
                {" "}
                <i className="fa fa-download mr-2a" aria-hidden="true" /> DOWNLOAD
               </button>
              </div>
             </div>
            </div>
           ) : (
            <div className="form-group text-center">
             <label>
              <i
               className="fa fa-film"
               aria-hidden="true"
               style={{ fontSize: "50px", cursor: "pointer" }}
              ></i>
             </label>
             <p></p>
             <div className="custom-file">
              <label className="custom-file-label">Output will show up here</label>
             </div>
            </div>
           )}
          </div>
         </div>
         {originalFaces.length > 0 && (
          <div className="col-md-12" style={{ borderTop: "3px red dotted" }}>
           <Row>
            {/* Original Faces Section */}
            <Col md={6}>
             <h4>Original Faces</h4>
             <div
              style={{
               display: "flex",
               flexWrap: "wrap",
               gap: "10px",
               height: "auto",
               maxHeight: "300px",
               justifyContent: "center",
               overflowY: "auto",
               alignItems: "center",
              }}
             >
              {originalFaces.map((face) => (
               <>
                <div>
                 <img
                  key={face.index}
                  onClick={() => handleOriginalSelection(face.index)}
                  src={face.url}
                  alt={`Face ${face.index}`}
                  style={{
                   cursor: "pointer",
                   width: "80px",
                   height: "80px",
                  }}
                  className={`frame-image ${
                   selectedOriginals.includes(face.index) ? "selected-original" : ""
                  }`}
                 />
                 Face #{face.index}
                 {selectedOriginals.includes(face.index) && (
                  <div>
                   <strong>Select Replacement for face #{face.index}</strong>
                   <div
                    style={{
                     display: "flex",
                     flexWrap: "wrap",
                     gap: "10px",
                     height: "auto",
                     maxHeight: "300px",
                     justifyContent: "center",
                     overflowY: "auto",
                     alignItems: "center",
                    }}
                   >
                    {replacementFaces.length > 0 &&
                     replacementFaces.map((replacement) => (
                      <div key={replacement.id}>
                       <Card
                        onClick={() =>
                         handlePairSelection(face.index, domain + replacement.image_paths)
                        }
                        style={{
                         cursor: "pointer",
                         border: "1px solid #ccc",
                        }}
                       >
                        <Card.Img
                         variant="top"
                         src={domain + replacement.image_paths}
                         style={{ height: "80px", height: "80px" }}
                         className={
                          facePairs.some(
                           (pair) =>
                            pair.index === face.index &&
                            pair.faceUrl === domain + replacement.image_paths
                          )
                           ? "selected-replacement"
                           : ""
                         }
                        />
                        <Card.Body>
                         <Card.Text>NFT18 #{replacement.id}</Card.Text>
                        </Card.Body>
                       </Card>
                      </div>
                     ))}
                    {replacementFaces.length === 0 && (
                     <div className="text-center">
                      <img src="assets/images/fly.gif" height={100} alt="No images" />
                      <p>
                       No Ai models here yet...{" "}
                       <a className="text-danger" href="/my-ai-models" target="_blank">
                        create a new model first
                       </a>
                      </p>
                     </div>
                    )}
                   </div>
                  </div>
                 )}
                </div>
               </>
              ))}
             </div>
            </Col>

            {/* Selected Pairs Section */}
            <Col md={6} className="text-center">
             <h4>Selected Pairs</h4>
             <ul>
              {facePairs.map((pair, idx) => (
               <li key={idx} className="mb-3">
                <span>
                 <img src={originalFaces[pair.index].url} height={80} />{" "}
                 <i
                  className="fa fa-long-arrow-right gradient-icon"
                  aria-hidden="true"
                  style={{ fontSize: "25px" }}
                 />{" "}
                 <img src={pair.faceUrl} height={80} />{" "}
                 <Button variant="danger" size="sm" onClick={() => removePair(pair.index)}>
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                 </Button>
                </span>
               </li>
              ))}
             </ul>
            </Col>
           </Row>
          </div>
         )}
         {+cost > 0 && (
          <div
           className="alert-info w-100 text-center p-3 my-3 rounded"
           style={{
            border: "1px red solid",
           }}
          >
           <i className="text-warning fa fa-info-circle" aria-hidden="true"></i> You will be charged{" "}
           <strong className="text-danger">{cost} </strong>
           v18plus for this service.{" "}
           <b>
            We will charge from your airdrop token balance if you have enough to cover the cost.
           </b>
          </div>
         )}
         <div className="col-md-12" style={{ display: "flex", justifyContent: "center", gap: 20 }}>
          <button className="btn btn-custom btn-block" onClick={startSwap}>
           Start the Swap
          </button>
          <button className="btn btn-secondary btn-block" onClick={refreshSwap}>
           Refresh
          </button>
         </div>
        </div>
       </>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}

export default NewSwap;

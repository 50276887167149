import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { ReadContract } from "../../utils/readContract";
import "../styles/post-video.css";
import "./profile.css";
import usePagination from "../../components/usePagination";
import Pagination2 from "../../components/Pagination2";
import { Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
 copy,
} from "../../utils/helpers";
import axios from "axios";
import Post from "../../components/Post";
import { NftContract } from "../../utils/readContract";
import { useQueryParam } from "../../components/useQueryParam";
import nftABI from "../../utils/nft.json";

import { ethers, parseUnits } from "ethers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { BrowserProvider, Contract } from "ethers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
function Profile() {
 const [userVideos, setUserVideos] = useState([]);
 const [userFans, setUserFans] = useState([]);
 const [userCreators, setUserCreators] = useState([]);
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [profile, setProfile] = useState({});
 const [profileOwner, setProfileOwner] = useState(false);
 const [canClaim, setCanClaim] = useState(false);
 const [text, setText] = useState("");
 const [inboxText, setInboxText] = useState("");
 const [vtype, setVtype] = useState("all");
 const [videoCount, setVideoCount] = useState(0);
 const [photoCount, setPhotoCount] = useState(0);
 const [myNft, setMyNft] = useState([]);

 const [unread, setUnread] = useState(0);
 const [alerts, setAlerts] = useState([]);
 const [isFan, setIsFan] = useState(false);
 const [creditGiftProfile, setCreditGiftProfile] = useState("");
 const [creditGift, setCreditGift] = useState({});

 const [inboxImages, setInboxImages] = useState([]);

 const [images, setImages] = useState([]);
 const [senders, setSenders] = useState([]);
 const [tickets, setTickets] = useState([]);
 const [ticket, setTicket] = useState([]);
 const [activeTicket, setActiveTicket] = useState({});
 const [conversation, setConversation] = useState([]);
 const [activeChat, setActiveChat] = useState({});
 const [comments, setComments] = useState({});
 const [emojiPickers, setEmojiPickers] = useState({});
 const [param, setParam] = useState(null);
 const query = useQueryParam("user");
 const [name, setName] = useState("");
 const [symbol, setSymbol] = useState("");
 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
  walletProvider,
  controlContract,
  nftContract,
  gamer,
  getGamer,
 } = useContext(Context);
 const MySwal = withReactContent(Swal);

 useEffect(() => {
  if (!query) {
   setParam(null);
  } else {
   setParam(query);
  }
 }, [query]);

 const sendMessage = async () => {
  if (address === profile.wallet) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", profile.wallet);
  formData.append("chat", text);

  images.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setText("");
   setImages([]);
   // fetchMessages();
   // console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 const sendMessageInbox = async () => {
  if (address === activeChat.sender) {
   showAlert({
    title: "Error!",
    text: "You cannot start a conversation with yourself.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  const formData = new FormData();
  formData.append("action", "send_message");
  formData.append("sender", address);
  formData.append("receiver", activeChat.sender);
  formData.append("chat", inboxText);

  inboxImages.forEach((image, index) => {
   formData.append(`files[]`, image);
  });

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "multipart/form-data",
   },
  });

  if (response.data.success) {
   setInboxText("");
   setInboxImages([]);
   fetchMessagesInbox();
   // console.log("sent", response.data);
  } else {
   showAlert({
    title: "Error!",
    text: "Message sending failed",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.log("not sent", response);
  }
 };

 useEffect(() => {
  if (Object.keys(activeChat).length == 0) {
   setConversation([]);
   return;
  }
  fetchMessagesInbox();
 }, [address]);

 const canWithdraw = async () => {
  try {
   const contract = await ReadContract(controlContract);

   const time = await contract.canWithdraw();
   //  console.log("ENDED", time);

   setCanClaim(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  canWithdraw();
 }, [controlContract]);

 const getProfile = async (wallet) => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_user_info", wallet: wallet }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setProfile(data[0]);

    //console.log("Fetched profile:", data[0]);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param) {
   setProfile({});
  } else {
   getProfile(param);
  }
 }, [param, user]);

 const getUserVideos = async (vtype = "all", page = 1, limit = 16) => {
  // if (!param ||  == null) return;
  setLoading(true);
  try {
   const details = {
    action: "get_user_videos",
    wallet: param,
    page: page,
    limit: limit,
    vtype: vtype,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   if (isConnected && address) {
    details.user = address;
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserVideos(data.records);
    setPagination(data.pagination);

    setPhotoCount(data.summary.total_photos);
    setVideoCount(data.summary.total_videos);

    //  console.log("All videos:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserVideos(vtype);
 }, [param, profile, vtype, address]);

 const getUserAlerts = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_notifications",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAlerts(data);
    //  console.log("ALERTS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserAlerts(address);
 }, [address, isConnected]);

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };
   if (profile.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!param || param == null) return;
  getUserFans(param);
 }, [param, profile]);

 const getUserCreators = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_creators",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserCreators(data);
    // console.log("All creators:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!address || !isConnected) return;
  getUserCreators(address);
 }, [address, isConnected]);

 useEffect(() => {
  console.log("isConnected:", isConnected);
  console.log("profile.wallet:", profile.wallet);
  console.log("address:", address);

  if (
   isConnected &&
   profile.wallet &&
   address &&
   profile.wallet.toLowerCase().trim() === address.toLowerCase().trim()
  ) {
   setProfileOwner(true);
  } else {
   setProfileOwner(false);
  }
 }, [isConnected, profile, address]);

 const updateRead = async (senderId, receiverId) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender", senderId);
  formData.append("receiver", receiverId);
  formData.append("action", "update_unread_chat");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   //setConversation(response.data.messages);
   // setActiveChat(chat);
   //  console.log("UPDATE", response.data.status);
  }
 };

 const fetchMessages = async (senderId, receiverId, chat) => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", senderId);
  formData.append("receiver_id", receiverId);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   setActiveChat(chat);
   updateRead(senderId, receiverId);
   fetchSenders();
   //   console.log("THESENDER", response);
  }
 };

 const fetchMessagesInbox = async () => {
  // console.log("THE", chat);
  const formData = new FormData();
  formData.append("sender_id", activeChat.sender);
  formData.append("receiver_id", address);
  formData.append("action", "get_messages");

  const response = await axios.post(apiURL, formData, {
   headers: {
    "Content-Type": "application/x-www-form-urlencoded",
   },
  });
  // console.log("THE", response);

  if (response.data.success) {
   setConversation(response.data.messages);
   //  updateRead(senderId, receiverId);
   //  setActiveChat(chat);
   //   console.log("THESENDER", response);
  }
 };

 const fetchSenders = async () => {
  if (!isConnected) {
   return;
  }

  const formData = new FormData();
  formData.append("receiver_wallet", address);
  formData.append("action", "get_chat_head");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
   });

   if (response.data.length > 0) {
    // console.log("senders:", response.data);

    setSenders(response.data);
   } else {
    setSenders([]);
   }
  } catch (error) {
   console.error("Error:", error);
  }
 };

 useEffect(() => {
  fetchSenders();
 }, [isConnected, address]);

 useEffect(() => {
  const fetchUnread = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_chat");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     //  console.log("UNREAD:", response.data);

     setUnread(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnread(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnread();
  const interval = setInterval(() => {
   fetchUnread();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  if (userFans.length === 0) {
   return;
  }
  setIsFan(searchArray(userFans, address));
 }, [userFans]);

 const signMessage = async () => {
  if (!canClaim) {
   return showAlert({
    title: "ERROR!",
    text: "Withdrawals are currently disabled.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits === 0) {
   return showAlert({
    title: "ERROR!",
    text: "You have no v18plus tokens to withdraw.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }

  const confirm = window.confirm(
   `Press Ok to confirm withdrawal of ${formatNumber(
    user.credits
   )} v18plus to your connected wallet address. You must have enough BNB in your wallet for gas fees.`
  );
  if (!confirm) return;

  try {
   const wallet = new ethers.Wallet(settings.project_id);
   const nonce = generateNonce();
   // Create message hash
   const messageHash = ethers.solidityPackedKeccak256(
    ["address", "uint256", "string"],
    [address, parseUnits(user.credits, 18), nonce]
   );

   // Sign the message hash
   const signature = await wallet.signMessage(ethers.toBeArray(messageHash));
   // setSignedMessage(signature);
   // console.log("SIG", signature);
   // Call smart contract function with the signed message and balance
   await withdraw(signature, nonce);
  } catch (error) {
   console.error("Error signing message:", error);
  }
 };

 const handleSubscribeProfile = async (creator, amount, duration = 30) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +amount && +gamer.credits < +amount) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
     duration: duration,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUserVideos(vtype);
    await getUserFans(param);
    await getUser(address);
    await getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const sendGiftProfile = async () => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!creditGiftProfile) {
   return showAlert({
    title: "ERROR!",
    text: "Enter v18plus amount.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +creditGiftProfile && +gamer.credits < +creditGiftProfile) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "gift_credit",
     from: address,
     to: profile.wallet,
     amount: creditGiftProfile,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUser(address);
    await getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const handleDelete = async (vid) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirmDelete = window.confirm("Are you sure you want to delete this post?");
  if (!confirmDelete) return;

  const formData = new FormData();
  formData.append("vid", vid);
  formData.append("action", "delete_post");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    await getUserVideos(vtype);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while deleting the video. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 const cancelRenewal = async (creator) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirm = window.confirm(
   "Are you sure you want to disable automatic renewal for this creator? Press OK to confirm."
  );
  if (!confirm) return;

  const formData = new FormData();
  formData.append("creator", creator);
  formData.append("fan", address);

  formData.append("action", "cancel_auto_renewal");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    await getUserCreators(address);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred ",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 const deleteComment = async (id) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const confirmDelete = window.confirm("Are you sure you want to delete this comment?");
  if (!confirmDelete) return;

  const formData = new FormData();
  formData.append("post_id", id);
  formData.append("action", "delete_comment");

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   if (response.data.success) {
    await getUserVideos(vtype);
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while deleting the video. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 const handleLike = async (videoId) => {
  if (!videoId || videoId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this post.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_video",
    vid: videoId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    getUserVideos(vtype);
    // setUserFans(data);
    // console.log("LIKED:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const likeNFT = async (nftId) => {
  if (!nftId || nftId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this NFT.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_nft18",
    nft: nftId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   // console.log("NO LIKE:", data);
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    //   await getUser(address);
    await getMyNft(profile.wallet);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 // Handle emoji picker toggle for a specific post
 const toggleEmojiPicker = (postId) => {
  setEmojiPickers((prev) => ({
   ...prev,
   [postId]: !prev[postId],
  }));
 };

 // Handle emoji selection for a specific post
 const onEmojiClick = (postId, event, emojiObject) => {
  const emoji = event.emoji;
  setComments((prev) => ({
   ...prev,
   [postId]: (prev[postId] || "") + emoji,
  }));
 };

 // Handle comment input change for a specific post
 const handleCommentChange = (postId, value) => {
  setComments((prev) => ({
   ...prev,
   [postId]: value,
  }));
 };

 const giftChange = (postId, value) => {
  setCreditGift((prev) => ({
   ...prev,
   [postId]: value,
  }));
 };

 const handleCommentSubmit = async (postId) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!comments[postId]) {
   return showAlert({
    title: "Error!",
    text: "Please type a comment.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const formData = new FormData();
  formData.append("post_id", postId);
  formData.append("wallet", address);
  formData.append("comment", comments[postId]);

  formData.append("action", "post_comment");
  //setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   //  console.log("COMMENT", response);
   if (response.data.success) {
    await getUserVideos(vtype);
    setComments((prev) => ({
     ...prev,
     [postId]: "",
    }));
    setEmojiPickers({});
   } else {
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  //setLoading(false);
 };

 const handleSubscribe = async (creator, amount, sidebar = 0) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +amount && +gamer.credits < +amount) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (sidebar === 1) {
   const confirm = window.confirm(
    `A monthly subscription fee of ${formatNumber(
     amount
    )} will be debited from your balance. Press Ok to proceed.`
   );
   if (!confirm) return;
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    //await getUserFans(creator);
    await getUserVideos(vtype);
    await getUser(address);
    await getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const sendGift = async (id, wallet) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!creditGift[id]) {
   return showAlert({
    title: "ERROR!",
    text: "Enter v18plus amount.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (+user.credits < +creditGift[id] && +gamer.credits < +creditGift[id]) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "gift_credit",
     from: address,
     to: wallet,
     amount: creditGift[id],
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    await getUser(address);
    await getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
    //  setIsFan(true);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const payCreator = async (postId, price, wallet) => {
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }

  if (+user.credits < +price && +gamer.credits < +price) {
   return showAlert({
    title: "ERROR!",
    text: "Insufficient v18plus balance. Top up v18plus and try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  const confirm = window.confirm(
   `A content price of ${formatNumber(
    price
   )} will be debited from your balance. We will charge from your airdrop token balance if you have enough to cover the cost. Press Ok to proceed.`
  );
  if (!confirm) return;
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "pay_for_post",
     from: address,
     to: wallet,
     amount: price,
     post_id: postId,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
    showAlert({
     title: "HEADS UP!",
     text: data.error,
     icon: "warning",
     confirmButtonText: "OK",
    });
   } else {
    await getUser(address);
    await getGamer(address);
    await getUserVideos(vtype);
    showAlert({
     title: "SUCCESSFUL!",
     text: data.message,
     icon: "success",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 const getMyNft = async (wallet) => {
  if (!wallet) return;
  setLoading(true);
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({ action: "get_user_nft18", wallet: wallet }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );
   const data = response.data;
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setMyNft(data.filter((l) => l.listed === "yes"));
    // console.log("My nft:", data);
   }
  } catch (error) {
   console.error("Error:", error.response ? error.response.data : error.message);
  } finally {
   setLoading(false);
  }
 };

 useEffect(() => {
  if (!profile) return;
  getMyNft(profile.wallet);
 }, [profile]);

 const getName = async () => {
  try {
   const contract = await NftContract(nftContract);

   const res = await contract.name();
   // console.log("SSS", res.toString());

   setName(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getName();
 }, [nftContract]);

 const getSymbol = async () => {
  try {
   const contract = await NftContract(nftContract);

   const res = await contract.symbol();
   //  console.log("SSS", res.toString());

   setSymbol(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSymbol();
 }, [nftContract]);

 const signAndBuyNFT = async (owner, nftId, price) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!nftId) {
   return showAlert({
    title: "Error!",
    text: "NFT is missing.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+user.credits < +price && +gamer.credits < +price) {
   return showAlert({
    title: "Error!",
    text:
     "You do not have enough v18plus in your account for this purchase. Please top up and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (owner === address) {
   return showAlert({
    title: "Error!",
    text: "You cannot buy your own NFT.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  const result = await MySwal.fire({
   title: "HEADS UP!",
   text: `You will pay ${formatNumber(price)} v18plus for this NFT.`,
   icon: "info",
   showCancelButton: true,
   confirmButtonText: "Yes, PROCEED!",
   cancelButtonText: "Cancel",
  });

  if (result.isConfirmed) {
   try {
    const wallet = new ethers.Wallet(settings.project_id);
    const nonce = generateNonce();
    // Create message hash
    const messageHash = ethers.solidityPackedKeccak256(
     ["uint256", "address", "address", "string"],
     [nftId, owner, address, nonce]
    );

    // Sign the message hash
    const signature = await wallet.signMessage(ethers.toBeArray(messageHash));
    // setSignedMessage(signature);
    // console.log("SIG", signature);
    // Call smart contract function with the signed message and balance
    await moveNFT(nftId, owner, address, nonce, signature, price);
   } catch (error) {
    console.error("Error signing message:", error);
   }
  }
 };

 async function moveNFT(nftId, from, to, nonce, signature, price) {
  try {
   let signer;
   let cont;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   cont = new Contract(nftContract, nftABI, signer);

   const data = await cont.buyNFT(nftId, from, to, nonce, signature);
   //  console.log("APPROVED", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      await buyNft(from, nftId, price);
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("MOVE ERROR", error);

   showAlert({
    title: "ERROR!",
    text: "There was a problem moving the NFT to your wallet. Please try again or contact support.",
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 }

 const buyNft = async (from, nftId, price) => {
  // Construct form data
  const formData = new FormData();
  formData.append("from", from);
  formData.append("to", address);

  formData.append("price", price);
  formData.append("id", nftId);

  formData.append("action", "buy_nft18");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   // Handle response
   if (response.data.success) {
    close();
    await getGamer(address);
    await getUser(address);
    await getMyNft(profile.wallet);
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 const close = () => {
  const buttons = document.querySelectorAll(".close-btn");
  buttons.forEach((button) => button.click());
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    {param && (
     <div id="main-content" style={{ background: "#1e1e1e" }}>
      <div className="content-area">
       <div className="container-fluid">
        <div className="profile-section">
         <div className="cover-container">
          <img className="cover-photo" src={domain + profile.cover_photo} alt="Cover Photo" />
          <div className="profile-photo-container">
           <img
            className="profile-photo"
            src={domain + profile.profile_photo}
            alt="Profile Photo"
           />
          </div>
         </div>

         <div className="profile-details">
          <Row className="profile-namea w-100 text-center">
           <Col xs={12} className="profile-icons-right">
            <div className="profile-right-btn">
             {!profileOwner && profile.is_creator === "yes" && (
              <>
               {!isFan ? (
                <button
                 className="btn btn-success"
                 data-toggle="modal"
                 data-target="#subModal"
                 data-backdrop="static"
                >
                 Subscribe
                </button>
               ) : (
                <button data-toggle="modal" data-target="#msgModal" className="btn">
                 <img src="assets/images/svg/chat.svg" /> <span>DM</span>
                </button>
               )}
              </>
             )}
             {profileOwner && (
              <button className="btn" onClick={signMessage}>
               <img src="assets/images/svg/withdraw.svg" /> <span>Withdraw</span>
              </button>
             )}
             {profileOwner && profile.is_creator === "yes" && (
              <a href="/post-fan-video" className="btn ">
               <img src="assets/images/svg/add.svg" /> <span>Create</span>
              </a>
             )}

             {!profileOwner && (
              <button
               className="btn right-icon-and-text"
               data-toggle="modal"
               data-target="#giftModal"
              >
               <img src="assets/images/svg/dollar.svg" /> <span>Tip</span>
              </button>
             )}
            </div>
           </Col>
          </Row>
          <h2 className="mt-5">
           {!profile.username ? shortenAddress(profile.wallet) : profile.username}{" "}
           {profile.verified === "yes" && (
            <i className="fa fa-check-circle text-primary" aria-hidden="true" />
           )}
          </h2>
          <div className="my-4" style={{ display: "flex", gap: 20 }}>
           <h4>{userFans.length} Fans</h4>
           <h4>{parseInt(formatNumber(videoCount))} Videos</h4>
           <h4>{parseInt(formatNumber(photoCount))} Photos</h4>
          </div>
          <p className="divide">{profile.bio}</p>

          <div className="mt-5" style={{ display: "flex", gap: 10 }}>
           {profile.instagram != null && (
            <a href={profile.instagram} target="_blank">
             <img height={50} src={domain + "assets/images/instagram.avif"} />
            </a>
           )}
           {profile.tiktok != null && (
            <a href={profile.tiktok} target="_blank">
             <img height={50} src={domain + "assets/images/tiktok.avif"} />
            </a>
           )}
           {profile.x != null && (
            <a href={profile.x} target="_blank">
             <img height={50} src={domain + "assets/images/x.avif"} />
            </a>
           )}
          </div>
         </div>
        </div>
        {user && isConnected && profileOwner && (
         <Row className="my-5">
          <Col md={2}></Col>
          <Col className="alert-info p-5" md={8}>
           <h3>Your referral link is:</h3>
           <div
            className="bg-white p-1 d-flex"
            style={{
             gap: 15,
             justifyContent: "center",
             alignItems: "center",
             fontWeight: "bolder",
            }}
           >
            <span>{domain + "?ref=" + user.id}</span>
            <i
             className="fa fa-clone text-primary"
             style={{ cursor: "pointer" }}
             onClick={() => copy(domain + "?ref=" + user.id)}
             aria-hidden="true"
            ></i>
           </div>
           <div className="text-center pt-2">
            You will earn<b> {settings.ref_bonus}% in v18plus</b> whenever your referrals make a
            purchase on this website. They only need to visit they website once using your referral
            link and we will take care of the rest.
           </div>
          </Col>
          <Col md={2}></Col>
         </Row>
        )}
        <div className="content row my-5">
         {myNft.length > 0 && (
          <div className="col-12">
           <h2 className="text-secondary"> MY NFT COLLECTIONS</h2>
           <Swiper
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
            autoplay
            //effect="fade"
            pagination={{ clickable: true }}
            // slidesPerView={3}
            loop={true}
            breakpoints={{
             640: {
              slidesPerView: 1,
              spaceBetween: 20,
             },
             768: {
              slidesPerView: 2,
              spaceBetween: 30,
             },
             1024: {
              slidesPerView: 3,
              spaceBetween: 30,
             },
            }}
            className="my-5"
           >
            {myNft.length > 0 ? (
             myNft.map((p, i) => (
              <>
               <SwiperSlide key={`nft${i}`}>
                <div className="card nft-card">
                 <img src={domain + p.image_paths} className="card-img-top" alt="NFT" />
                 <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                   <div>
                    <img src={domain + p.profile_photo} className="avatar" alt="Avatar" />
                    <span className="badge badge-secondary">
                     By{" "}
                     <a
                      href={`profile?user=${!p.username ? p.wallet : p.username}`}
                      target="_blank"
                      className="text-warning"
                     >
                      {!p.username ? shortenAddress(p.wallet) : p.username}
                     </a>{" "}
                     {p.verified === "yes" && (
                      <i className="fa fa-check-circle text-success" aria-hidden="true" />
                     )}
                    </span>
                   </div>
                  </div>
                  <h5 className="card-title">
                   {name} #{p.id}
                  </h5>

                  <p className="price">
                   {formatNumber(p.price)} v18plus{" "}
                   <span className="text-primary">
                    ($
                    {formatNumber(Math.round(tokenPrice * p.price))})
                   </span>
                  </p>
                  {/*
                  <div
                   className={`${
                    searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"
                   }`}
                  >
                   <button
                    onClick={() => likeNFT(p.id)}
                    className={`${
                     searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"
                    }`}
                   >
                    <i className="fa fa-heart" />{" "}
                    {searchArrayBool(p.liked_by_users, address) ? "Unlike" : "Like"}
                   </button>{" "}
                   <button
                    className="text-primary ml-2"
                    data-toggle="modal"
                    data-target={`#plikeModal-${i}`}
                   >
                    {Math.round(formatNumber(p.like_count))}
                   </button>
                  </div>
            */}
                  <div className="text-center mt-1">
                   <button
                    className="btn btn-custom"
                    onClick={() => signAndBuyNFT(p.wallet, p.id, p.price)}
                    disabled={!isConnected || address === p.wallet}
                   >
                    Buy this NFT
                   </button>
                  </div>
                 </div>
                </div>
                {/*BUY NFT modal*/}
                <div
                 className="modal fade"
                 id={`pbuyModal-${i}`}
                 tabIndex={-1}
                 role="dialog"
                 aria-labelledby={`pbuyModalLabel-${i}`}
                 aria-hidden="true"
                >
                 <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                   <div className="modal-header">
                    <h3 className="modal-title" id={`pbuyModalLabel-${i}`}>
                     Buy NFT
                    </h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">×</span>
                    </button>
                   </div>
                   <div className="modal-body">
                    You are buying
                    <b>
                     {" "}
                     {name} #{p.id}
                    </b>{" "}
                    for {formatNumber(p.price)} v18plus ($
                    {formatNumber(Math.round(tokenPrice * p.price))}) and your v18plus balance is{" "}
                    {formatNumber(user.credits)} ($
                    {formatNumber(Math.round(tokenPrice * user.credits))}
                    ). Hit the PROCEED button to continue.
                   </div>

                   <div className="modal-footer">
                    <button
                     type="button"
                     className="btn btn-custom"
                     onClick={() => signMessage(p.wallet, p.id, p.price)}
                    >
                     Proceed
                    </button>

                    <button
                     type="button"
                     className="btn btn-secondary close-btn"
                     data-dismiss="modal"
                    >
                     Close
                    </button>
                   </div>
                  </div>
                 </div>
                </div>
               </SwiperSlide>
              </>
             ))
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No NFTs here yet...</p>
             </div>
            )}
           </Swiper>
          </div>
         )}
        </div>
       </div>
       <div className="site-content layout-1">
        <div className="container">
         {/*!profileOwner && profile.is_creator === "yes" && !isFan && (
          <Row className="mb-5">
           <Col md={12}>
            <h5>Monthly Subscription</h5>
           </Col>
           <Col md={12}>
            <button
             className="btn btn-custom btn-block"
             style={{ fontWeight: "700" }}
             data-toggle="modal"
             data-target="#subModal"
            >
             SUBSCRIBE FOR {formatNumber(profile.content_price)} V18PLUS
            </button>
           </Col>
           <Col md={12} className="mt-4">
            <h5>Yearly Subscription</h5>
           </Col>
           <Col md={12}>
            <button
             className="btn btn-custom btn-block"
             style={{ fontWeight: "700" }}
             data-toggle="modal"
             data-target="#yearSubModal"
            >
             SUBSCRIBE FOR {formatNumber(+profile.content_price * 11)} V18PLUS{" "}
             <sup>1 month free</sup>
            </button>
           </Col>
          </Row>
         )*/}
         {/* Tabs Section */}
         <div className="tabs-section">
          <ul className="nav nav-tabs pb-5" id="myTab" role="tablist">
           <li className="nav-item">
            <a
             className="nav-link active"
             id="timeline-tab"
             data-toggle="tab"
             href="#timeline"
             role="tab"
             aria-controls="timeline"
             aria-selected="true"
            >
             Timeline
            </a>
           </li>
           {profile.is_creator === "yes" && (
            <li className="nav-item">
             <a
              className="nav-link"
              id="fans-tab"
              data-toggle="tab"
              href="#fans"
              role="tab"
              aria-controls="fans"
              aria-selected="false"
             >
              Fans
             </a>
            </li>
           )}
           {profileOwner && (
            <>
             <li className="nav-item">
              <a
               className="nav-link"
               id="creators-tab"
               data-toggle="tab"
               href="#creators"
               role="tab"
               aria-controls="My Creators"
               aria-selected="false"
              >
               My Creators
              </a>
             </li>

             <li className="nav-item">
              <a
               className="nav-link"
               id="inbox-tab"
               data-toggle="tab"
               href="#inbox"
               role="tab"
               aria-controls="inbox"
               aria-selected="false"
              >
               Inbox{" "}
               {unread > 0 && (
                <sup>
                 <span class="badge badge-danger">{unread}</span>
                </sup>
               )}
              </a>
             </li>
             {/*
             <li className="nav-item">
              <a
               className="nav-link"
               id="ticket-tab"
               data-toggle="tab"
               href="#ticket"
               role="tab"
               aria-controls="ticket"
               aria-selected="false"
              >
               Tickets{" "}
               {unread > 0 && (
                <sup>
                 <span class="badge badge-danger">{unread}</span>
                </sup>
               )}
              </a>
             </li>
           */}
             <li className="nav-item">
              <a
               className="nav-link"
               id="notice-tab"
               data-toggle="tab"
               href="#notifications"
               role="tab"
               aria-controls="notifications"
               aria-selected="false"
              >
               Alerts
              </a>
             </li>
            </>
           )}
           <li className="nav-item">
            <a
             className="nav-link"
             id="profile-tab"
             data-toggle="tab"
             href="#profile"
             role="tab"
             aria-controls="profile"
             aria-selected="false"
            >
             Profile
            </a>
           </li>
          </ul>
          <div className="tab-content" id="myTabContent">
           <div
            className="tab-pane fade show active"
            id="timeline"
            role="tabpanel"
            aria-labelledby="timeline-tab"
           >
            {/* Timeline content */}
            {/* Timeline content */}
            <Row>
             <Col md={1}></Col>
             <Col md={10}>
              <Post
               key="all"
               post={userVideos}
               handleDelete={handleDelete}
               handleLike={handleLike}
               handleCommentChange={handleCommentChange}
               comments={comments}
               deleteComment={deleteComment}
               type="all"
               pages={pagination}
               toggleEmojiPicker={toggleEmojiPicker}
               handleCommentSubmit={handleCommentSubmit}
               emojiPickers={emojiPickers}
               onEmojiClick={onEmojiClick}
               creditGift={creditGift}
               giftChange={giftChange}
               sendGift={sendGift}
               handleSubscribe={handleSubscribe}
               getAllPosts={getUserVideos}
               payCreator={payCreator}
              />
             </Col>
             <Col md={1}></Col>
            </Row>

            {/* Add more posts as needed */}
           </div>
           <div className="tab-pane fade" id="fans" role="tabpanel" aria-labelledby="fans-tab">
            {/* Fans content */}
            {userFans.length > 0 ? (
             <div className="fans-list">
              {userFans.map((fan, i) => (
               <div key={"uf_" + i} className="fan-item">
                <a
                 href={`profile?user=${!fan.username ? fan.wallet : fan.username}`}
                 target="_blank"
                >
                 <img src={domain + fan.profile_photo} alt="Fan Avatar" className="fan-avatar" />
                 <p className="fan-username">
                  {!fan.username ? shortenAddress(fan.wallet) : fan.username}{" "}
                  {fan.verified === "yes" && (
                   <i className="fa fa-check-circle" aria-hidden="true" />
                  )}
                 </p>
                </a>
               </div>
              ))}

              {/* Add more fan items as needed */}
             </div>
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No fans here yet...</p>
             </div>
            )}
           </div>
           <div
            className="tab-pane fade"
            id="creators"
            role="tabpanel"
            aria-labelledby="creators-tab"
           >
            {/* Fans content */}
            {userCreators.length > 0 ? (
             <div className="row" style={{ maxHeight: "600px", overflow: "auto" }}>
              {userCreators.map((fan, i) => (
               <>
                <Col key={"uc-" + i} xs={4} style={{ background: "#eee", borderRadius: "4px" }}>
                 <Row>
                  <Col md={4}>
                   {" "}
                   <img
                    src={domain + fan.profile_photo}
                    alt="Fan Avatar"
                    className="fan-avatar"
                    style={{ width: "100%", height: "80px", objectFit: "cover" }}
                   />
                  </Col>
                  <Col md={8}>
                   <div>
                    <b>Creator:</b> {!fan.username ? shortenAddress(fan.wallet) : fan.username}{" "}
                    {fan.verified === "yes" && (
                     <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                   </div>
                   <div>
                    <b>Expires:</b> {formatLocalTime(fan.expiry_date)}
                   </div>
                   <div>
                    <b>Status:</b>{" "}
                    {fan.is_active === 1 ? (
                     <span className="text-success">Active</span>
                    ) : (
                     <span className="text-danger">Inactive</span>
                    )}
                   </div>
                   <Row className="text-center">
                    <Col md={4} className="my-2">
                     <a
                      href={`profile?user=${!fan.username ? fan.wallet : fan.username}`}
                      target="_blank"
                     >
                      View
                     </a>
                    </Col>
                    {fan.is_active === 1 && fan.renew === 1 && (
                     <Col className="my-2">
                      <button onClick={() => cancelRenewal(fan.fan_of)} className="text-danger">
                       Cancel renewal
                      </button>
                     </Col>
                    )}
                   </Row>
                  </Col>
                 </Row>
                </Col>
               </>
              ))}

              {/* Add more fan items as needed */}
             </div>
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No creators here yet...</p>
             </div>
            )}
           </div>
           <div className="tab-pane fade" id="inbox" role="tabpanel" aria-labelledby="inbox-tab">
            {/* INBOX content */}
            {isConnected && (
             <div className="chat-container">
              <div className="chat-sidebar">
               <div className="chat-sidebar-header">
                <h3>Conversations</h3>
               </div>
               <div className="chat-sidebar-content">
                {senders.length > 0 ? (
                 senders.map((s, i) => (
                  <div
                   key={i}
                   className={`chat-user ${activeChat.sender === s.sender && "hover"}`}
                   onClick={() => fetchMessages(s.sender, address, s)}
                  >
                   <img src={domain + s.profile_photo} alt="User Avatar" className="chat-avatar" />
                   <div className="chat-user-info">
                    <p className="chat-username">
                     {!s.username ? shortenAddress(s.sender) : s.username}{" "}
                     {s.verified === "yes" && (
                      <i className="fa fa-check-circle" aria-hidden="true" />
                     )}
                     {s.is_read === 0 && (
                      <sup>
                       <span class="badge badge-danger">NEW</span>
                      </sup>
                     )}
                    </p>
                    <p className="chat-last-message">Since {formatLocalTime(s.add_date)}</p>
                   </div>
                  </div>
                 ))
                ) : (
                 <div className="text-center">
                  <img src="assets/images/fly.gif" height={100} />
                  <p>You have no messages yet...</p>
                 </div>
                )}

                {/* Add more chat users as needed */}
               </div>
              </div>
              <div className="chat-main">
               <div className="chat-header">
                {Object.keys(activeChat).length > 0 && (
                 <>
                  <img
                   src={domain + activeChat.profile_photo}
                   alt="Chat User Avatar"
                   className="chat-avatar"
                  />
                  <p className="chat-username">
                   {" "}
                   {!activeChat.username
                    ? shortenAddress(activeChat.sender)
                    : activeChat.username}{" "}
                   {activeChat.verified === "yes" && (
                    <i className="fa fa-check-circle" aria-hidden="true" />
                   )}
                  </p>
                 </>
                )}
               </div>

               <div className="chat-content">
                {conversation.length > 0 &&
                 conversation.map((msg, index) => (
                  <div
                   key={index}
                   className={`chat-message ${
                    msg.receiver === address ? "chat-message-sent" : "chat-message-received"
                   }`}
                  >
                   {msg.message && <p>{msg.message}</p>}
                   {msg.images.length > 0 &&
                    msg.images.map((image, imgIndex) => (
                     <div key={imgIndex}>
                      <img
                       className="my-2"
                       src={domain + image}
                       alt={`image-${imgIndex}`}
                       height={200}
                      />
                     </div>
                    ))}
                   <span className="chat-timestamp">{formatLocalTime(msg.add_date)}</span>
                  </div>
                 ))}

                {/* Add more chat messages as needed */}
               </div>
               {conversation.length > 0 && (
                <div className="chat-footer">
                 <input
                  type="text"
                  className="chat-input"
                  placeholder="Type a message..."
                  value={inboxText}
                  onChange={(e) => setInboxText(e.target.value)}
                 />
                 <input
                  type="file"
                  multiple
                  onChange={(e) => setInboxImages([...inboxImages, ...e.target.files])}
                  id="inbox-images"
                  className="file-input"
                 />
                 <label className="custom-file-label" htmlFor="inbox-images">
                  <i className="fa fa-camera" aria-hidden="true"></i>
                 </label>
                 <button onClick={sendMessageInbox} className="chat-send-button">
                  Send
                 </button>
                </div>
               )}
              </div>
             </div>
            )}
           </div>
           {/* TICKETS */}
           <div className="tab-pane fade" id="ticket" role="tabpanel" aria-labelledby="ticket-tab">
            {/* INBOX content */}
            <div className="chat-container">
             <div className="chat-sidebar">
              <div className="chat-sidebar-header">
               <h3>Support Tickets</h3>
              </div>
              <div className="chat-sidebar-content">
               {senders.length > 0 ? (
                senders.map((s, i) => (
                 <div
                  key={i}
                  className={`chat-user ${activeChat.sender === s.sender && "hover"}`}
                  onClick={() => fetchMessages(s.sender, address, s)}
                 >
                  <img src={domain + s.profile_photo} alt="User Avatar" className="chat-avatar" />
                  <div className="chat-user-info">
                   <p className="chat-username">
                    {!s.username ? shortenAddress(s.sender) : s.username}{" "}
                    {s.verified === "yes" && (
                     <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                    {s.is_read === 0 && (
                     <sup>
                      <span class="badge badge-danger">NEW</span>
                     </sup>
                    )}
                   </p>
                   <p className="chat-last-message">Since {formatLocalTime(s.add_date)}</p>
                  </div>
                 </div>
                ))
               ) : (
                <div className="text-center">
                 <img src="assets/images/fly.gif" height={100} />
                 <p>You have no messages yet...</p>
                </div>
               )}

               {/* Add more chat users as needed */}
              </div>
             </div>
             <div className="chat-main">
              <div className="chat-header">
               {Object.keys(activeChat).length > 0 && (
                <>
                 <img
                  src={domain + activeChat.profile_photo}
                  alt="Chat User Avatar"
                  className="chat-avatar"
                 />
                 <p className="chat-username">
                  {" "}
                  {!activeChat.username
                   ? shortenAddress(activeChat.sender)
                   : activeChat.username}{" "}
                  {activeChat.verified === "yes" && (
                   <i className="fa fa-check-circle" aria-hidden="true" />
                  )}
                 </p>
                </>
               )}
              </div>

              <div className="chat-content">
               {conversation.length > 0 &&
                conversation.map((msg, index) => (
                 <div
                  key={index}
                  className={`chat-message ${
                   msg.receiver === address ? "chat-message-sent" : "chat-message-received"
                  }`}
                 >
                  {msg.message && <p>{msg.message}</p>}
                  {msg.images.length > 0 &&
                   msg.images.map((image, imgIndex) => (
                    <div key={imgIndex}>
                     <img
                      className="my-2"
                      src={domain + image}
                      alt={`image-${imgIndex}`}
                      height={200}
                     />
                    </div>
                   ))}
                  <span className="chat-timestamp">{formatLocalTime(msg.add_date)}</span>
                 </div>
                ))}

               {/* Add more chat messages as needed */}
              </div>
              {conversation.length > 0 && (
               <div className="chat-footer">
                <input
                 type="text"
                 className="chat-input"
                 placeholder="Type a message..."
                 value={inboxText}
                 onChange={(e) => setInboxText(e.target.value)}
                />
                <input
                 type="file"
                 multiple
                 onChange={(e) => setInboxImages([...inboxImages, ...e.target.files])}
                 id="inbox-images"
                 className="file-input"
                />
                <label className="custom-file-label" htmlFor="inbox-images">
                 <i className="fa fa-camera" aria-hidden="true"></i>
                </label>
                <button onClick={sendMessageInbox} className="chat-send-button">
                 Send
                </button>
               </div>
              )}
             </div>
            </div>
           </div>
           <div
            className="tab-pane fade"
            id="notifications"
            role="tabpanel"
            aria-labelledby="notifications-tab"
           >
            {/* Profile content */}
            {/* Notifications content */}
            <div className="notifications-list">
             {alerts.length > 0 ? (
              alerts.map((a, i) => (
               <div key={i} className="notification-item">
                <i className={`fa ${a.icon} notification-icon`} />
                <div className="notification-content">
                 <p className="notification-message">{a.text}</p>
                 <span className="notification-timestamp">{formatLocalTime(a.date)}</span>
                </div>
               </div>
              ))
             ) : (
              <div className="text-center">
               <img src="assets/images/fly.gif" height={100} />
               <p>No notifications here yet...</p>
              </div>
             )}

             {/* Add more notification items as needed */}
            </div>
           </div>
           <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
           >
            {/* Profile content */}
            <div className="profile-card-container">
             <div className="profile-card-header">
              <img
               src={domain + profile.profile_photo}
               alt="Profile Avatar"
               className="profile-card-avatar"
              />
              <div className="profile-card-info">
               <h2 className="profile-card-name">
                {!profile.username ? shortenAddress(profile.wallet) : profile.username}{" "}
                {profile.verified === "yes" && (
                 <i className="fa fa-check-circle" aria-hidden="true" />
                )}
               </h2>
               <p className="profile-card-username">{shortenAddress(profile.wallet)}</p>
               {profileOwner && (
                <button
                 onClick={() => window.open(`/edit-profile`, "_blank", "noopener,noreferrer")}
                 className="btn btn-edit-profile-card"
                >
                 EDIT PROFILE
                </button>
               )}
              </div>
             </div>
             <div className="profile-card-body">
              <div className="profile-card-item">
               <span className="profile-card-label">Username:</span>
               <span className="profile-card-value">{profile.username && profile.username}</span>
              </div>
              {profileOwner && (
               <div className="profile-card-item">
                <span className="profile-card-label">Email:</span>
                <span className="profile-card-value">{user.email}</span>
               </div>
              )}
              <div className="profile-card-item">
               <span className="profile-card-label">Total Fans:</span>
               <span className="profile-card-value">{userFans.length}</span>
              </div>
              <div className="profile-card-item">
               <span className="profile-card-label">Total Posts:</span>
               <span className="profile-card-value">{userVideos.length}</span>
              </div>
              <div className="profile-card-item">
               <span className="profile-card-label">Total Earned:</span>
               <span className="profile-card-value">${formatNumber(profile.total_earned)}</span>
              </div>

              {/* Add more profile details as needed */}
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    )}
    <Footer />
    {/*GIFT modal*/}
    <div
     className="modal fade"
     id={`giftModal`}
     tabIndex={-1}
     role="dialog"
     aria-labelledby={`gModalLabel`}
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title text-white" id={`gModalLabel`}>
         Send Gift
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body text-white">
        Your v18plus balance is {formatNumber(user.credits)} ($
        {formatNumber(Math.round(tokenPrice * user.credits))}
        ). Enter the v18plus amount you want to gift to{" "}
        {!profile.username ? shortenAddress(profile.wallet) : profile.username} and hit the PROCEED
        button.{" "}
        <b>We will charge from your airdrop token balance if you have enough to cover the cost.</b>
        <div className="my-4">
         <input
          placeholder="Amount"
          className="form-control my-5"
          style={{
           backgroundColor: "#000",
           color: "#fff",
           width: "100%",
           height: "60px",
           border: "#aaa 1px solid",
          }}
          type="number"
          value={creditGiftProfile}
          onChange={(e) => setCreditGiftProfile(e.target.value)}
         />
         {creditGiftProfile && (
          <div className="text-white">
           {formatNumber(creditGiftProfile)} v18plus = $
           {formatNumber(Math.round(tokenPrice * creditGiftProfile))}
          </div>
         )}
        </div>
       </div>

       <div className="modal-footer">
        {creditGiftProfile && (
         <>
          <button type="button" className="btn btn-custom" onClick={sendGiftProfile}>
           Proceed
          </button>
         </>
        )}
        <button type="button" className="btn btn-secondary" data-dismiss="modal">
         Close
        </button>
       </div>
      </div>
     </div>
    </div>

    <div
     className="modal fade"
     id="subModal"
     tabIndex={-1}
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title text-white" id="myModalLabel">
         Subscribe to {!profile.username ? shortenAddress(profile.wallet) : profile.username}
         {"'s "} Secret Corner
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body text-white">
        {!profile.username ? shortenAddress(profile.wallet) : profile.username} charges{" "}
        <b>
         {formatNumber(profile.content_price)} v18plus ($
         {formatNumber(Math.round(tokenPrice * profile.content_price))}){" "}
        </b>{" "}
        monthly. In turn, you will have unlimited access to all of their private content and also be
        able to chat with them while your subscription is still active.{" "}
        <b>We will charge from your airdrop token balance if you have enough to cover the cost.</b>
       </div>
       {!isFan && (
        <div className="modal-footer">
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
          No, thanks
         </button>
         {isConnected ? (
          <button
           type="button"
           className="btn btn-custom"
           onClick={() => handleSubscribeProfile(profile.wallet, profile.content_price)}
          >
           Yes, let's do it!
          </button>
         ) : (
          <button disabled={true} type="button" className="btn btn-custom">
           Connect your wallet first.
          </button>
         )}
        </div>
       )}
      </div>
     </div>
    </div>

    <div
     className="modal fade"
     id="yearSubModal"
     tabIndex={-1}
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title text-white" id="myModalLabel">
         Subscribe to {!profile.username ? shortenAddress(profile.wallet) : profile.username}
         {"'s "} Secret Corner
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body text-white">
        {!profile.username ? shortenAddress(profile.wallet) : profile.username} charges{" "}
        <b>
         {formatNumber(profile.content_price)} v18plus ($
         {formatNumber(Math.round(tokenPrice * profile.content_price))}){" "}
        </b>{" "}
        monthly and you will get 1 month free for a yearly subscription. In turn, you will have
        unlimited access to all of their private content and also be able to chat with them while
        your subscription is still active.{" "}
        {isConnected && (
         <p>
          Your current v18plus balance is:{" "}
          <b>
           {formatNumber(user.credits)} ($
           {formatNumber(Math.round(tokenPrice * user.credits))}) and you will pay{" "}
           {formatNumber(+profile.content_price * 11).toString()} v18plus yearly.
          </b>
         </p>
        )}
       </div>
       {!isFan && (
        <div className="modal-footer">
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
          No, thanks
         </button>
         {isConnected ? (
          <button
           type="button"
           className="btn btn-custom"
           onClick={() => handleSubscribeProfile(profile.wallet, profile.content_price * 11, 365)}
          >
           Yes, PROCEED
          </button>
         ) : (
          <button disabled={true} type="button" className="btn btn-custom">
           Connect your wallet first.
          </button>
         )}
        </div>
       )}
      </div>
     </div>
    </div>

    {/*Message modal*/}
    <div
     className="modal fade"
     id="msgModal"
     tabIndex={-1}
     role="dialog"
     aria-labelledby="msgModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-md" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h3 className="modal-title" id="msgModalLabel">
         Message {!profile.username ? shortenAddress(profile.wallet) : profile.username}
        </h3>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">×</span>
        </button>
       </div>
       <div className="modal-body">
        <textarea
         style={{
          background: "#111",
          height: "150px",
          color: "#fff",
          width: "100%",
         }}
         placeholder="Type your message here"
         onInput={(e) => setText(e.target.value)}
         value={text}
        ></textarea>
        <input
         type="file"
         multiple
         onChange={(e) => setImages([...images, ...e.target.files])}
         accept="image/*"
         //  value={images}
        />
       </div>
       {isFan && (
        <div className="modal-footer">
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
          I'm done
         </button>
         {isConnected ? (
          <button type="button" className="btn btn-custom" onClick={sendMessage}>
           Send
          </button>
         ) : (
          <button disabled={true} type="button" className="btn btn-custom">
           Connect your wallet first.
          </button>
         )}
        </div>
       )}
      </div>
     </div>
    </div>
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Profile;

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

import { Card, Modal, Carousel, Row, Col, Button } from "react-bootstrap";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
} from "../utils/helpers";
const chunkArray = (array, size) => {
 const result = [];
 for (let i = 0; i < array.length; i += size) {
  result.push(array.slice(i, i + size));
 }
 return result;
};
const Sidebar = ({ handleSubscribe, payCreator, trendingVideos, freeVideos, newestVideos }) => {
 const [isSticky, setIsSticky] = useState(false);
 const [isHidden, setIsHidden] = useState(false);
 const [topCreators, setTopCreators] = useState([]);
 const [showModal, setShowModal] = useState(false);
 const [selection, setSelection] = useState({});

 const [randCreators, setRandCreators] = useState([]);
 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
 } = useContext(Context);

 useEffect(() => {
  const handleScroll = () => {
   const sidebar = document.querySelector(".suggestions-sidebar");
   const triggerPoint = document.querySelector(".all-posts-tab").offsetTop;
   const triggerFooter = document.querySelector(".footer-stop").offsetTop;

   // Check if scroll position has crossed the trigger point
   if (window.scrollY > triggerPoint) {
    setIsSticky(true);
   } else {
    setIsSticky(false);
   }
   if (window.scrollY > triggerFooter) {
    setIsHidden(true);
   } else {
    setIsHidden(false);
   }
  };

  // Attach the scroll event listener
  window.addEventListener("scroll", handleScroll);
  return () => {
   // Clean up the scroll event listener
   window.removeEventListener("scroll", handleScroll);
  };
 }, []);

 const getTopCreators = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_top_creators" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setTopCreators(chunkArray(data, 4));
    // console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getTopCreators();
 }, []);

 const getRandCreators = async () => {
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({ action: "get_random_creators" }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setRandCreators(data);
    console.log("RAND CREATORS:", data);
   }
  } catch (error) {
   console.error("Error:", error.response ? error.response.data : error.message);
  }
 };

 useEffect(() => {
  getRandCreators();
 }, []);

 const handleVideoClick = (videoUrl, user, link) => {
  setSelection({ videoUrl, user, link });

  setShowModal(true); // Open the modal
 };

 const handleCloseModal = () => {
  setShowModal(false); // Close the modal
  setSelection({}); // Clear the selected video
 };

 return (
  <>
   {randCreators.length > 0 ? (
    <div className={`side-right`}>
     <h5 className="mb-3">SUGGESTIONS</h5>
     <Carousel indicators={false}>
      {randCreators.map((group, index) => (
       <Carousel.Item key={"car_" + index}>
        <div className="carousel-list">
         {group.map((user, i) => (
          <a
           key={"grp_" + i}
           href={`profile?user=${!user.username ? user.poster : user.username}`}
           style={{ color: "#fff" }}
           target="_blank"
          >
           <Row
            key={`user-${i}`}
            className="list-group-item"
            style={{
             backgroundImage: `url(${domain + "assets/images/creator-bg.png"})`,
             backgroundAttachment: "scroll",
             backgroundSize: "cover",
             backgroundRepeat: "no-repeat",
             backgroundPosition: "center center",
            }}
           >
            <Col className="d-flex align-items-center">
             <div className="me-3">
              <img
               src={`${domain + user.profile_photo}`}
               alt={user.username}
               className="rounded-circle"
              />
             </div>
             <div className="text-white">
              <strong>
               {!user.username ? shortenAddress(user.poster) : user.username}{" "}
               {user.verified === "yes" && (
                <i className="fa fa-check-circle text-primary" aria-hidden="true" />
               )}
              </strong>

              <br />
              <small className="text-white">
               Fans: {user.fans_count} | Posts: {user.posts_count}
              </small>
             </div>
            </Col>
           </Row>
          </a>
         ))}
        </div>
       </Carousel.Item>
      ))}
     </Carousel>
     {trendingVideos.length > 0 && (
      <div className="my-5">
       <a href="/trending-posts">
        <h5 className="mb-3">TRENDING VIDEOS</h5>
       </a>

       <Carousel>
        {trendingVideos.map((group, i) => (
         <Carousel.Item key={`trend-${i}`}>
          <Row className="g-3">
           {group.map((v, index) => (
            <Col xs={6} key={`tv-${index}`} className="video-item">
             <div className="post-header">
              <div className="user-info d-flex">
               <a href={`profile?user=${!v.username ? v.poster : v.username}`} target="_blank">
                <img
                 src={domain + v.profile_photo}
                 className="rounded-circle"
                 style={{ height: "20px", width: "20px" }}
                 alt="Profile"
                />
               </a>
               <div>
                <p className="user-name">
                 <a
                  href={`profile?user=${!v.username ? v.poster : v.username}`}
                  style={{ color: "#111" }}
                  target="_blank"
                 >
                  {!v.username ? shortenAddress(v.poster) : v.username}
                 </a>
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle text-primary" aria-hidden="true" />
                 )}
                </p>
               </div>
              </div>
             </div>
             {v.unlock ? (
              <video
               className="d-block w-100 carousel-media"
               src={domain + v.video}
               // controls={v.unlock}
               width="100%"
               onClick={() =>
                handleVideoClick(
                 domain + v.video,
                 !v.username ? shortenAddress(v.poster) : v.username,
                 `profile?user=${!v.username ? v.poster : v.username}`
                )
               } // Open modal on click
               style={{ cursor: "pointer" }}
              />
             ) : (
              <img
               src={domain + v.cover_photo}
               className={`d-block w-100 carousel-media ${!v.unlock ? "blurred" : ""}`}
               alt="Cover"
              />
             )}
             {!v.unlock && (
              <>
               {v.payment_type === "subscribers" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => handleSubscribe(v.poster, v.content_price, 1)}
                >
                 <i className="fa fa-unlock-alt" aria-hidden="true"></i> Subscribe
                </div>
               )}
               {v.payment_type === "pay" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => payCreator(v.id, v.price, v.poster)}
                >
                 <i className="fa fa-eye" aria-hidden="true"></i> for {formatNumber(v.price)}{" "}
                 v18plus
                </div>
               )}
              </>
             )}
            </Col>
           ))}
          </Row>
         </Carousel.Item>
        ))}
       </Carousel>
      </div>
     )}
     {freeVideos.length > 0 && (
      <div className="my-5">
       <a href="/free-posts">
        <h5 className="mb-3">FREE VIDEOS</h5>
       </a>

       <Carousel>
        {freeVideos.map((group, i) => (
         <Carousel.Item key={`trend-${i}`}>
          <Row className="g-3">
           {" "}
           {/* Add spacing between rows */}
           {group.map((v, index) => (
            <Col xs={6} key={`tv-${index}`} className="video-item">
             <div className="post-header">
              <div className="user-info d-flex">
               <a href={`profile?user=${!v.username ? v.poster : v.username}`} target="_blank">
                {" "}
                <img
                 src={domain + v.profile_photo}
                 className="rounded-circle"
                 style={{ height: "20px", width: "20px" }}
                />
               </a>
               <div>
                {" "}
                <p className="user-name">
                 <a
                  href={`profile?user=${!v.username ? v.poster : v.username}`}
                  style={{ color: "#111" }}
                  target="_blank"
                 >
                  {!v.username ? shortenAddress(v.poster) : v.username}
                 </a>{" "}
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle text-primary" aria-hidden="true" />
                 )}
                </p>
               </div>
              </div>
             </div>
             {v.unlock ? (
              <video
               className="d-block w-100 carousel-media"
               src={domain + v.video}
               // controls={v.unlock}
               width="100%"
               onClick={() =>
                handleVideoClick(
                 domain + v.video,
                 !v.username ? shortenAddress(v.poster) : v.username,
                 `profile?user=${!v.username ? v.poster : v.username}`
                )
               } // Open modal on click
               style={{ cursor: "pointer" }}
              />
             ) : (
              <img src={domain + v.cover_photo} className={`d-block w-100 carousel-media `} />
             )}
             {!v.unlock && (
              <>
               {v.payment_type === "subscribers" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => handleSubscribe(v.poster, v.content_price, 1)}
                >
                 <i className="fa fa-unlock-alt" aria-hidden="true"></i> Subscribe
                </div>
               )}
               {v.payment_type === "pay" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => payCreator(v.id, v.price, v.poster)}
                >
                 <i className="fa fa-eye" aria-hidden="true"></i> for {formatNumber(v.price)}{" "}
                 v18plus
                </div>
               )}
              </>
             )}
            </Col>
           ))}
          </Row>
         </Carousel.Item>
        ))}
       </Carousel>
      </div>
     )}
     {newestVideos.length > 0 && (
      <div className="my-5">
       <a href="#">
        <h5 className="mb-3">NEWEST VIDEOS</h5>
       </a>

       <Carousel>
        {newestVideos.map((group, i) => (
         <Carousel.Item key={`trend-${i}`}>
          <Row className="g-3">
           {" "}
           {/* Add spacing between rows */}
           {group.map((v, index) => (
            <Col xs={6} key={`tv-${index}`} className="video-item">
             <div className="post-header">
              <div className="user-info d-flex">
               <a href={`profile?user=${!v.username ? v.poster : v.username}`} target="_blank">
                {" "}
                <img
                 src={domain + v.profile_photo}
                 className="rounded-circle"
                 style={{ height: "20px", width: "20px" }}
                />
               </a>
               <div>
                {" "}
                <p className="user-name">
                 <a
                  href={`profile?user=${!v.username ? v.poster : v.username}`}
                  style={{ color: "#111" }}
                  target="_blank"
                 >
                  {!v.username ? shortenAddress(v.poster) : v.username}
                 </a>{" "}
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle text-primary" aria-hidden="true" />
                 )}
                </p>
               </div>
              </div>
             </div>
             {v.unlock ? (
              <video
               className="d-block w-100 carousel-media"
               src={domain + v.video}
               // controls={v.unlock}
               width="100%"
               onClick={() =>
                handleVideoClick(
                 domain + v.video,
                 !v.username ? shortenAddress(v.poster) : v.username,
                 `profile?user=${!v.username ? v.poster : v.username}`
                )
               } // Open modal on click
               style={{ cursor: "pointer" }}
              />
             ) : (
              <img
               src={domain + v.cover_photo}
               className={`d-block w-100 carousel-media  ${!v.unlock ? "blurred" : ""}`}
              />
             )}
             {!v.unlock && (
              <>
               {v.payment_type === "subscribers" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => handleSubscribe(v.poster, v.content_price, 1)}
                >
                 <i className="fa fa-unlock-alt" aria-hidden="true"></i> Subscribe
                </div>
               )}
               {v.payment_type === "pay" && (
                <div
                 className="mt-2"
                 style={{ cursor: "pointer", color: "red" }}
                 onClick={() => payCreator(v.id, v.price, v.poster)}
                >
                 <i className="fa fa-eye" aria-hidden="true"></i> for {formatNumber(v.price)}{" "}
                 v18plus
                </div>
               )}
              </>
             )}
            </Col>
           ))}
          </Row>
         </Carousel.Item>
        ))}
       </Carousel>
      </div>
     )}
     {topCreators.length > 0 && (
      <div className="my-5">
       <h5 className="mb-3">TOP CREATORS</h5>

       <Carousel>
        {topCreators.map((group, i) => (
         <Carousel.Item key={`trend-${i}`}>
          <Row className="g-3">
           {" "}
           {/* Add spacing between rows */}
           {group.map((v, index) => (
            <Col style={{ cursor: "pointer" }} xs={6} key={`tv-${index}`}>
             <div
              className="creator-card"
              onClick={() => window.open(`profile?user=${v.username ? v.username : v.wallet}`, "")}
             >
              <div className="card-image">
               <img
                src={domain + v.profile_photo}
                className="card-img-top card-img-home"
                alt="User image"
               />
               <div className="card-overlay">
                <span className="likes">
                 {Math.round(formatNumber(v.fans_count))}{" "}
                 <i className="fa fa-heart text-danger" aria-hidden="true"></i>
                </span>
                <span className="age">
                 {" "}
                 {Math.round(formatNumber(v.posts_count))}{" "}
                 <i className="fa fa-th text-warning" aria-hidden="true"></i>
                </span>
               </div>
              </div>
              <div className="card-footer">
               <div className="card-name">
                <span>{!v.username ? shortenAddress(v.wallet) : v.username}</span>

                <span className="verified">
                 {" "}
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle verified" aria-hidden="true" />
                 )}
                </span>
               </div>
              </div>
             </div>
            </Col>
           ))}
          </Row>
         </Carousel.Item>
        ))}
       </Carousel>
      </div>
     )}
    </div>
   ) : (
    ""
   )}
   {/* Modal for Playing Video */}
   <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
     <Modal.Title className="text-white">
      Playing Video by{" "}
      <a href={selection.link} className="text-warning">
       {" "}
       {selection.user}
      </a>
     </Modal.Title>
    </Modal.Header>
    <Modal.Body>
     {selection.videoUrl && (
      <video src={selection.videoUrl} controls autoPlay style={{ width: "100%" }} />
     )}
    </Modal.Body>
    <Modal.Footer>
     <Button variant="secondary" onClick={handleCloseModal}>
      Close
     </Button>
    </Modal.Footer>
   </Modal>
  </>
 );
};

export default Sidebar;

import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Context } from "../context/contex";
import { handleStopAndReset, playExplainer } from "../utils/helpers";
import "../pages/styles/style.css";
import Slider from "../components/Slider";

import AOS from "aos";
import "aos/dist/aos.css";
function About() {
    const { apiURL, domain } = useContext(Context);
    const exvRef = useRef(null);
    const watchRef = useRef(null);
    const fanRef = useRef(null);
    const aiRef = useRef(null);
    const swapRef = useRef(null);
    const nftRef = useRef(null);

    const watchVid = useRef(null);
    const fanVid = useRef(null);
    const aiVid = useRef(null);
    const swapVid = useRef(null);
    const nftVid = useRef(null);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }, []);

    const playVideo = (videoRef) => {
        videoRef.current.play();
    };

    const pauseVideo = (videoRef) => {
        videoRef.current.pause();
        videoRef.current.currentTime = 0; // Reset video to start
    };

    return (
        <div>
            <div id="wrapper-container">
                <Header />
                <Slider />
                <div
                    id="main-content"
                    style={{
                        backgroundColor: "#1e1e1e",
                    }}
                >
                    <div className="content container-fluid my-1 p-sm-0 p-md-4 p-lg-5">
                        <div className="row d-flex align-items-center" data-aos="fade-right">
                            <div className="col-md-6">
                                <img src={`${domain}assets/images/icon-trans.png`} width="60%" />
                            </div>
                            <div className="col-md-6 text-secondary">
                                <h1 className="mt-3" style={{ fontSize: "35px", fontWeight: 700 }}>
                                    ABOUT
                                </h1>
                                <div className="about my-3" style={{ fontSize: "20px" }}>
                                    <p>
                                        {" "}
                                        Welcome to <b>Modeland.ai</b>, the cutting-edge platform that revolutionizes how creators
                                        and fans connect in a safe, innovative, and rewarding environment.
                                    </p>
                                </div>
                                <div className="about my-3" style={{ fontSize: "20px" }}>
                                    <p>
                                        At <b>Modeland.ai</b>, we empower individuals to explore their creativity and build their
                                        personal brands using advanced AI tools. Our platform is designed for creators who want to
                                        share exclusive content, monetize their work, and engage with their audiences in new and
                                        meaningful ways.
                                    </p>
                                </div>
                                {/*
        <button
         className="btn btn-custom"
         data-toggle="modal"
         data-target="#intro"
         onClick={() => playExplainer(exvRef)}
        >
         <i className="fa fa-play" aria-hidden="true"></i> WATCH THIS VIDEO TO LEARN MORE
        </button>
        */}
                            </div>
                        </div>
                    </div>

                    <section
                        className="py-5 px-3 overlay-section"
                        style={{
                            backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
                            backgroundRepeat: "no-repeat",
                            backgroundAttachment: "fixed",
                            backgroundSize: "cover",
                            borderBottomLeftRadius: "50% 40px",
                            borderBottomRightRadius: "50% 40px",
                            overflow: "hidden",
                            borderTopLeftRadius: "50% 40px",
                            borderTopRightRadius: "50% 40px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="container">
                            <div className="content row my-5">
                                <div className="col-12 text-center my-5">
                                    <h1 className="display-4 text-white" data-aos="fade-right" style={{ textTransform: "none" }}>
                                        What Makes Modeland.ai Unique?
                                    </h1>
                                </div>
                                <div data-aos="fade-left" className="col-md-4 text-center">
                                    <div className="advantage-box">
                                        <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
                                            AI-Powered Customization
                                        </h4>
                                        Utilize AI-driven tools to create, enhance, and personalize your content, ensuring every
                                        post reflects your unique vision and style.
                                    </div>
                                </div>

                                <div data-aos="fade-left" className="col-md-4 text-center">
                                    <div className="advantage-box">
                                        <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
                                            Fair Rewards
                                        </h4>
                                        Earn directly from your content through a transparent system powered by blockchain
                                        technology. With our native token, <b>ai18plus</b>, you get rewarded for every piece of
                                        value you generate.
                                    </div>
                                </div>

                                <div data-aos="fade-left" className="col-md-4 text-center">
                                    <div className="advantage-box">
                                        <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
                                            Privacy First
                                        </h4>
                                        Protect your identity with AI face-swap technology and NFT ID verification. Express
                                        yourself freely, without compromising your privacy or security.
                                    </div>
                                </div>
                            </div>
                            <div className="content row my-5">
                                <div data-aos="fade-left" className="col-md-4 text-center">
                                    <div className="advantage-box">
                                        <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
                                            Exclusive Community
                                        </h4>
                                        Join a network of creators and fans who appreciate high-quality, authentic content in a
                                        respectful and inclusive environment.
                                    </div>
                                </div>

                                <div data-aos="fade-left" className="col-md-4 text-center">
                                    <div className="advantage-box">
                                        <h4 className="mb-3" style={{ fontWeight: "bolder" }}>
                                            Watch & Earn
                                        </h4>
                                        Fans can engage with content and earn rewards through our interactive "Watch to Earn"
                                        feature, creating a win-win experience for everyone involved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="content container-fluid mt-5">
                        <div className="row d-flex align-items-center" data-aos="fade-left">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 text-secondary my-5" style={{ fontSize: "22px" }}>
                                <b>Modeland.ai</b> is more than a platform—it's a movement that empowers creativity, builds
                                trust, and creates lasting value for creators and fans alike. Whether you're a seasoned
                                professional or someone exploring a new opportunity, Modeland.ai is the perfect space to
                                grow, create, and thrive. Join us and redefine the future of digital creativity.
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <div id="back-to-top" className="btn-back-to-top">
                <i className="ion ion-ios-arrow-thin-up" />
            </div>
            {/*modals*/}
            <div
                className="modal fade"
                id="intro"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(exvRef)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={exvRef}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/intro.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}
            <div
                className="modal fade"
                id="watch"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(watchVid)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={watchVid}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/watch2earn.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}
            <div
                className="modal fade"
                id="fan"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(fanVid)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={fanVid}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/just-fans.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}
            <div
                className="modal fade"
                id="ai"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(aiVid)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={aiVid}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/porn-face.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}
            <div
                className="modal fade"
                id="swap"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(swapVid)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={swapVid}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/face-swap.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}
            <div
                className="modal fade"
                id="nft"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="intro"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleStopAndReset(nftVid)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <video
                                ref={nftVid}
                                controls
                                width="100%"
                                // height="660px"

                                onError={(e) => console.error("Error loading video:", e)}
                                className="mt-3"
                            >
                                <source src={domain + "uploads/explainer/nft.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/*===========================*/}

            {/*END MODALS*/}
        </div>
    );
}

export default About;

import React, { useState, useEffect, useContext, useRef } from "react";
import "./ScratchGame.css";
import axios from "axios";
import { Context } from "../../context/contex";
import SpinnerOverlay from "../../components/Spinner";
import { formatNumber, showAlert, searchArray } from "../../utils/helpers";

import { Card, ListGroup, Carousel, Row, Col } from "react-bootstrap";
const imagesList = [
 "/game/1.jpg",
 "/game/2.png",
 "/game/3.jpg",
 "/game/4.jpg",
 "/game/5.jpg",
 "/game/6.jpg",
 "/game/7.png",
 "/game/8.png",
 "/game/9.jpg",
 "/game/10.jpg",
 "/game/11.jpg",
 "/game/12.jpg",
 "/game/13.jpg",
 "/game/14.jpg",
 "/game/15.jpg",
 "/game/16.jpg",
 "/game/17.jpg",
 "/game/18.jpg",
];

const ScratchGame = () => {
 const [boxes, setBoxes] = useState([]);
 const [credits, setCredits] = useState(0);
 const [tries, setTries] = useState(0);
 const [stage, setStage] = useState("age");
 const [gameTokens, setGameTokens] = useState({});

 const [scratched, setScratched] = useState(new Array(6).fill(false));
 const [scratching, setScratching] = useState(new Array(6).fill(false));
 const {
  address,
  isConnected,
  apiURL,
  user,
  getUser,
  loading,
  setLoading,
  gamer,
  getGamer,
  generateDate,
 } = useContext(Context);
 const musicRef = useRef(null);
 const coinRef = useRef(null);
 const laughRef = useRef(null);
 const clapRef = useRef(null);

 useEffect(() => {
  generateBoxes();
 }, []);

 const generateUTCHour = () => {
  const now = new Date();
  return String(now.getUTCHours()).padStart(2, "0");
 };

 const getGameTokens = async () => {
  setLoading(true);
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "get_game_tokens_by_date",
     date: generateDate(),
     hour: generateUTCHour(),
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setGameTokens(data.data);
    // console.log("TOKENS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getGameTokens();
 }, []);

 const recordGame = async (credits) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (+gameTokens?.tokens_left <= 0) {
   return showAlert({
    title: "Error!",
    text: "No more tokens left for the day. Come back tomorrow.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (+gameTokens?.hour_tokens <= 0) {
   return showAlert({
    title: "Error!",
    text: `No more tokens left for this hour. Come back at ${generateUTCHour() + 1}:00 UTC.`,
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (+gamer.tries >= 222) {
   return showAlert({
    title: "Error!",
    text: "You have reached the maximum tries for the day. Please come back tomorrow.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "insert_game_record",
     wallet: address,
     day: generateDate(),
     hour: generateUTCHour(),
     credits: credits,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    showAlert({
     title: "Error!",
     text: data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
    console.error("Error:", data.message);
   } else {
    await getGamer(address);
    await getGameTokens();
    if (+credits > 0) {
     setCredits(credits);
     setStage("win");
     clapRef.current.play();
    }
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };
 const generateBoxes = () => {
  const number = Math.floor(Math.random() * 46) + 5;
  const randomIndex = Math.floor(Math.random() * 6);

  const newBoxes = Array(6)
   .fill(null)
   .map((_, index) => {
    return index === randomIndex
     ? { type: "number", value: number }
     : { type: "image", value: imagesList[Math.floor(Math.random() * imagesList.length)] };
   });

  setBoxes(newBoxes);
  setScratched(new Array(6).fill(false));
  setScratching(new Array(6).fill(false));
 };

 const handleScratch = (index) => {
  if (!scratched[index] && !scratching[index]) {
   // Start scratching animation
   setScratching((prev) => {
    const updated = [...prev];
    updated[index] = true;
    return updated;
   });

   // Reveal content after scratching animation
   setTimeout(async () => {
    setScratched((prev) => {
     const updated = [...prev];
     updated[index] = true;
     return updated;
    });

    // Stop scratching animation
    setScratching((prev) => {
     const updated = [...prev];
     updated[index] = false;
     return updated;
    });

    // Update credits if the box contains a number
    if (boxes[index].type === "number") {
     await recordGame(boxes[index].value);
     coinRef.current.play();
     // setCredits((prev) => prev + boxes[index].value);
    } else {
     await recordGame(0);
     laughRef.current.play();
    }
   }, 2000); // 2-second scratching animation
  }
 };

 const doContinue = () => {
  generateBoxes();
  setStage("game");
 };

 const doNext = () => {
  musicRef.current.play();
  setStage("me");
 };

 return (
  <div className="sbody">
   <audio ref={musicRef} src="/game/music.mp3"></audio>
   <audio ref={coinRef} src="/game/coin.mp3"></audio>
   <audio ref={laughRef} src="/game/laugh.mp3"></audio>
   <audio ref={clapRef} src="/game/clap.mp3"></audio>

   <SpinnerOverlay loading={loading} />

   <nav className="navbar">
    <div className="navbar-logo">
     <a href="/">
      <img src="/game/logo.png" />
     </a>
    </div>
    <div className="navbar-items">
     <div className="navbar-item">
      <img src="/game/coin.png" alt="Credits" className="navbar-icon" />
      <span>{gamer.credits}</span>
     </div>
     <div className="navbar-item">
      <img src="/game/clock.png" alt="Tries" className="navbar-icon" />
      <span>{gamer.tries} / 3</span>
     </div>
     <w3m-button balance="hide" size="small" />
    </div>
   </nav>
   {stage == "age" && (
    <Row className="d-flex align-items-center mt-5 justify-content-center">
     <Col md={9}>
      <img src="/game/age18.gif" className="w-100 d-block my-5" />
     </Col>
     <Col md={3}>
      <button className="btn btn-success btn-block" onClick={doNext}>
       Yes, sure
      </button>
      &nbsp;
      <button className="btn btn-danger btn-block" onClick={() => (window.location.href = "/")}>
       Nope, sorry
      </button>
     </Col>
    </Row>
   )}
   {stage == "me" && (
    <Row className="">
     <Col md={12}>
      <img src="/game/question.gif" className="w-100 d-block my-5" />
     </Col>
     <Col md={12} className="text-center">
      <button className="btn btn-success btn-block mb-1" onClick={() => setStage("instruction")}>
       Those free tokens please
      </button>
      &nbsp;
      <button
       className="btn btn-danger btn-block mb-1"
       onClick={() => (window.location.href = "/")}
      >
       You, you, you!
      </button>
     </Col>
    </Row>
   )}
   {stage == "instruction" && (
    <Row className="">
     <Col md={12}>
      <img src="/game/instruction.png" className="w-100 d-block my-5" />
     </Col>
     <Col md={12} className="text-center">
      <button className="btn btn-success btn-block mb-1" onClick={() => setStage("game")}>
       Yes, let's do it.
      </button>
      &nbsp;
      <button
       className="btn btn-danger btn-block mb-1"
       onClick={() => (window.location.href = "/")}
      >
       Nope, I rather go home!
      </button>
     </Col>
    </Row>
   )}
   {stage == "game" && (
    <>
     <Row className="d-flex align-items-center mt-5">
      {gameTokens?.tokens_left && +gameTokens?.tokens_left <= 0 && (
       <Col md={12} className="m-5 text-center">
        <div className="alert-warning w-75 p-4 rounded" style={{ fontSize: "20px" }}>
         <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> There are no more
         airdrops for today. Please come back tomorrow or just keep playing for fun!
        </div>
       </Col>
      )}
      {gameTokens?.tokens_left &&
       +gameTokens?.tokens_left > 0 &&
       gameTokens?.hour_tokens &&
       +gameTokens?.hour_tokens <= 0 && (
        <Col md={12} className="m-5 text-center">
         <div className="alert-warning w-75 p-4 rounded" style={{ fontSize: "20px" }}>
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> There are no more
          airdrops for this hour. Please check back FROM {+generateUTCHour() + 1}:00 UTC.
         </div>
        </Col>
       )}
      <Col md={8} className="scratch-game">
       <p>&nbsp;</p>

       <Row>
        {boxes.map((box, index) => (
         <Col md={4} xs={6} sm={6} key={index} className="p-3">
          <div
           className={`box ${scratched[index] ? "scratched" : ""}`}
           onClick={() => handleScratch(index)}
          >
           {scratching[index] ? (
            <div className="scratching-animation">Scratching...</div>
           ) : scratched[index] ? (
            box.type === "number" ? (
             <span className="number">{box.value}</span>
            ) : (
             <img src={box.value} alt={``} className="box-image" />
            )
           ) : (
            <img src="/game/scratch.png" alt="Scratch Cover" className="scratch-cover" />
           )}
          </div>
         </Col>
        ))}
       </Row>
       <button className="restart-btn" onClick={generateBoxes}>
        Reset Game
       </button>
      </Col>
      <Col md={4}>
       <h1 style={{ textAlign: "center" }}>HOW TO PLAY</h1>
       <div>
        Click on any of the 6 boxes to reveal either a number or a model. If you get a number, this
        number will be credited to you as tokens. These tokens can be used to pay for all utilities
        (Ai model creation and face swap) on <b>modeland.ai</b>. And each token is valid for 24
        hours from the time it was earned. Earn and use them FAST!
       </div>
       <h1 style={{ textAlign: "center" }}>GAME STATS</h1>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>SEASON:</b>
        </span>
        <span>{generateDate()}</span>
       </div>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>CURRENT HOUR:</b>
        </span>
        <span>{generateUTCHour()}:00 UTC</span>
       </div>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>CLAIMABLE TOKENS TODAY:</b>
        </span>
        <span>{gameTokens?.tokens_left ? gameTokens?.tokens_left : 7200} / 7200</span>
       </div>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>CLAIMABLE TOKENS THIS HOUR:</b>
        </span>
        <span>{gameTokens?.hour_tokens ? gameTokens?.hour_tokens : 300} / 300</span>
       </div>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>YOUR TOKENS:</b>
        </span>
        <span>{gamer.credits}</span>
       </div>
       <div className="d-flex justify-content-between mb-2">
        <span>
         <b>YOUR ATTEMPTS TODAY:</b>
        </span>
        <span>{gamer.tries} / 3</span>
       </div>
      </Col>
     </Row>
    </>
   )}
   {stage == "win" && (
    <Row className="win-container">
     <Col md={12}>
      {/* GIF as background */}
      <div className="win-background">
       <h1 className="win-text">YOU WON {credits} TOKENS</h1>
       <button className="continue-button" onClick={doContinue}>
        Continue
       </button>
      </div>
     </Col>
    </Row>
   )}
  </div>
 );
};

export default ScratchGame;

import React, { useState, useContext, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Context } from "../context/contex";
import { formatNumber, shortenAddress, showAlert } from "../utils/helpers";
import axios from "axios";
import { Modal, Container, Form, Button, Image, Row, Col, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const CheckoutForm = () => {
 const [amount, setAmount] = useState("");
 const [name, setName] = useState("");
 const [wallet, setWallet] = useState("");
 const [perUSD, setPerUSD] = useState(0);

 const stripe = useStripe(); // To interact with Stripe
 const elements = useElements(); // To access UI components like CardElement
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  loading,
  setLoading,
  buyCredits,
  domain,
  tokenPrice,
  gamer,
  getGamer,
  addCredits,
 } = useContext(Context);

 const [isProcessing, setIsProcessing] = useState(false);
 const [message, setMessage] = useState("");

 useEffect(() => {
  if (!tokenPrice || tokenPrice == 0) {
   setPerUSD(0);
   return;
  }
  setPerUSD((1 / +tokenPrice).toFixed(2));
 }, [tokenPrice]);

 // Handle form submission
 const handleSubmit = async (event) => {
  event.preventDefault();

  if (!stripe || !elements) {
   return showAlert({
    title: "ERROR!",
    text: "Stripe is not loaded yet.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet first.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }

  setIsProcessing(true);

  // Get the card details entered by the user
  const cardElement = elements.getElement(CardElement);

  try {
   // Call your backend to create a PaymentIntent
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "create_stripe_intent",
     amount: Math.round(Number(amount) * 100),
    }),
   });

   const clientSecret = await response.json();
   // console.log("CLIENT", clientSecret.client_secret);
   if (!clientSecret.client_secret) {
    return showAlert({
     title: "ERROR!",
     text: "Could not initiate payment.",
     icon: "error",
     confirmButtonText: "OK",
    });
   }

   // Confirm the payment on the client side
   const result = await stripe.confirmCardPayment(clientSecret.client_secret, {
    payment_method: {
     card: cardElement,
     billing_details: {
      name: name,
     },
    },
   });

   if (result.error) {
    showAlert({
     title: "ERROR!",
     text: `Payment failed: ${result.error.message}`,
     icon: "error",
     confirmButtonText: "OK",
    });
   } else if (result.paymentIntent.status === "succeeded") {
    await addCredits(wallet, (+perUSD * +amount).toFixed(2));
    showAlert({
     title: "SUCCESS!",
     text: `Your payment was successful.`,
     icon: "success",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   showAlert({
    title: "ERROR!",
    text: `Payment failed: ${error.message}`,
    icon: "error",
    confirmButtonText: "OK",
   });
  } finally {
   setIsProcessing(false);
  }
 };

 return (
  <form onSubmit={handleSubmit}>
   <h3 style={{ textAlign: "center" }}>Pay with card</h3>
   <Container>
    <Row>
     <Col>
      <div>Name on card:</div>
      <input
       type="text"
       value={name}
       onInput={(e) => setName(e.target.value)}
       placeholder="Enter full name"
       style={{
        background: "#111",
        height: "50px",
        color: "#fff",
        width: "100%",
       }}
       required
      />
     </Col>
     <Col>
      <div>Enter Amount:</div>
      <input
       type="number"
       value={amount}
       onChange={(e) => setAmount(e.target.value)}
       placeholder="Enter amount in USD"
       min="0"
       step="0.01"
       style={{
        background: "#111",
        height: "50px",
        color: "#fff",
        width: "100%",
       }}
       required
      />
     </Col>
    </Row>
    <Row>
     <Col>
      <div>Card Details:</div>
      <CardElement
       options={{
        style: {
         base: {
          fontSize: "16px",
          height: "50px",
          backgroundColor: "#111",
          color: "#fff",
          "::placeholder": {},
         },
         invalid: {
          color: "#9e2146",
         },
        },
       }}
       hidePostalCode={true}
      />
     </Col>
    </Row>
    <Row>
     <Col md={12}>
      <div>Recipient's wallet:</div>
      <input
       type="text"
       value={wallet}
       defaultValue={address}
       onChange={(e) => setWallet(e.target.value)}
       placeholder="Paste recipient's wallet"
       min="0"
       step="0.01"
       style={{
        background: "#111",
        height: "50px",
        color: "#fff",
        width: "100%",
       }}
       required
      />
     </Col>
     <Col md={12} className="text-center">
      <button
       type="submit"
       disabled={!stripe || isProcessing || !amount || !name}
       className="btn btn-custom mt-4"
      >
       {isProcessing
        ? "Processing..."
        : `Pay $${formatNumber(amount)} for ${(+perUSD * +amount).toFixed(2)} v18plus tokens`}
      </button>
     </Col>
    </Row>
   </Container>
  </form>
 );
};

export default CheckoutForm;

import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import NewSwap from "../components/new-swap";

import { Context } from "../context/contex";
import {
 handleStopAndReset,
 playExplainer,
 shortenAddress,
 formatNumber,
 showAlert,
 searchArrayBool,
 generateNonce,
} from "../utils/helpers";
import "../pages/styles/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Carousel } from "react-bootstrap";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import AOS from "aos";
import "aos/dist/aos.css";
import { NftContract } from "../utils/readContract";
import nftABI from "../utils/nft.json";
import axios from "axios";
import { ethers } from "ethers";
import AnimationSection from "../components/animation";

import { BrowserProvider, Contract } from "ethers";
const nftContract = "0xEa3d89683BEEb93D600Ba42128f409dA899DfFe5";

function Home() {
 const [topCreators, setTopCreators] = useState([]);
 const [prompt, setPrompt] = useState("");
 const [ratio, setRatio] = useState("1:1");
 const [hideSave, setHideSave] = useState(false);
 const [imgStyle, setImgStyle] = useState("photorealism");
 const [data, setData] = useState({});
 const [name, setName] = useState("");
 const [supply, setSupply] = useState("");
 const [popularNft, setPopularNft] = useState([]);

 const [symbol, setSymbol] = useState("");
 const {
  apiURL,
  domain,
  settings,
  user,
  setLoading,
  isConnected,
  walletProvider,
  address,
  debitUser,
  tokenPrice,
 } = useContext(Context);
 const exvRef = useRef(null);
 const watchRef = useRef(null);
 const fanRef = useRef(null);
 const aiRef = useRef(null);
 const swapRef = useRef(null);
 const nftRef = useRef(null);

 const watchVid = useRef(null);
 const fanVid = useRef(null);
 const aiVid = useRef(null);
 const swapVid = useRef(null);
 const nftVid = useRef(null);

 const close = () => {
  const buttons = document.querySelectorAll(".close-btn");
  buttons.forEach((button) => button.click());
 };

 async function moveNFT(nftId, from, to, nonce, signature, price) {
  try {
   let signer;
   let cont;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   cont = new Contract(nftContract, nftABI, signer);

   const data = await cont.buyNFT(nftId, from, to, nonce, signature);
   //  console.log("APPROVED", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      await buyNft(from, nftId, price);
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("MOVE ERROR", error);

   showAlert({
    title: "ERROR!",
    text: "There was a problem moving the NFT to your wallet. Please try again or contact support.",
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 }

 const buyNft = async (from, nftId, price) => {
  // Construct form data
  const formData = new FormData();
  formData.append("from", from);
  formData.append("to", address);

  formData.append("price", price);
  formData.append("id", nftId);

  formData.append("action", "buy_nft18");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   // Handle response
   if (response.data.success) {
    close();
    // await getNewNft();
    await getPopularNft();
    // await getUser(address);
    //   await getMyNft(address);
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  setLoading(false);
 };

 const handleLike = async (nftId) => {
  if (!nftId || nftId == null) return;
  if (!isConnected) {
   return showAlert({
    title: "ERROR!",
    text: "Connect a wallet to like this NFT.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
  try {
   const details = {
    action: "like_nft18",
    nft: nftId,
    wallet: address,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // console.error("NO LIKE:", data.message);
   } else {
    //   await getUser(address);
    //  await getMyNft(address);
    //  await getNewNft();
    await getPopularNft();
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const signMessage = async (owner, nftId, price) => {
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (!nftId) {
   return showAlert({
    title: "Error!",
    text: "NFT is missing.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (+user.credits < +price) {
   return showAlert({
    title: "Error!",
    text:
     "You do not have enough v18plus in your account for this purchase. Please top up and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (owner === address) {
   return showAlert({
    title: "Error!",
    text: "You cannot buy your own NFT.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  try {
   const wallet = new ethers.Wallet(settings.project_id);
   const nonce = generateNonce();
   // Create message hash
   const messageHash = ethers.solidityPackedKeccak256(
    ["uint256", "address", "address", "string"],
    [nftId, owner, address, nonce]
   );

   // Sign the message hash
   const signature = await wallet.signMessage(ethers.toBeArray(messageHash));
   // setSignedMessage(signature);
   console.log("SIG", signature);
   // Call smart contract function with the signed message and balance
   await moveNFT(nftId, owner, address, nonce, signature, price);
  } catch (error) {
   console.error("Error signing message:", error);
  }
 };

 const getSupply = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.totalSupply();
   console.log("SSS", res.toString());

   setSupply(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSupply();
 }, []);

 const getName = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.name();
   // console.log("SSS", res.toString());

   setName(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getName();
 }, []);

 const getSymbol = async () => {
  try {
   const contract = await NftContract();

   const res = await contract.symbol();
   //  console.log("SSS", res.toString());

   setSymbol(res.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getSymbol();
 }, []);

 const getTopCreators = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_top_creators" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setTopCreators(data);
    console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getTopCreators();
 }, []);

 const refresh = () => {
  setData({});
  setPrompt("");
  setHideSave(false);
 };

 const generateImage = async () => {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (+user.credits < +settings.porn_face_fee) {
   showAlert({
    title: "Error!",
    text: "Insufficient v18plus for model creation. Please buy or earn some first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (!prompt || !imgStyle || !ratio) {
   showAlert({
    title: "Error!",
    text: "One or more required fields are missing.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const raw = JSON.stringify({
   prompt: prompt,
   style: imgStyle,
   aspect_ratio: ratio,
   output_format: "png",
   response_format: "b64",
  });
  setLoading(true);
  // setDbImage({});
  try {
   const response = await fetch("https://api.getimg.ai/v1/essential-v2/text-to-image", {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
     Authorization:
      "Bearer key-vFPcIvWuVnAH1vvMbq2jXzf5CD6xclvWMHYqgV8MK5BsbqJFsJFmz09fF6jf3AkbtmPWX07rbqzUMfqN2Q1V6HHtj7NWLIK",
    },
    body: raw,
    redirect: "follow",
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   console.log("GEN", data);
   if (data.error) {
    setLoading(false);
    showAlert({
     title: "Error!",
     text: data.error.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
   } else if (data.image) {
    const d = await debitUser(settings.porn_face_fee);
    if (d) {
     //data.output = data.url;
     setData(data);
     setHideSave(true);
     //  console.log("GEN MOD", data);
    }
    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const pollForResult = async (url) => {
  try {
   const response = await fetch(url, {
    method: "POST",
    headers: {
     "Content-Type": "application/json",
    },
    body: JSON.stringify({
     key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   // console.log("POLL RESULT", data);
   if (data.status === "processing") {
    setTimeout(() => pollForResult(url), 3000);
   } else if (data.status === "success") {
    const d = await debitUser(settings.porn_face_fee);
    if (d) {
     setData(data);
     setHideSave(true);
    }
    setLoading(false);
   }
  } catch (error) {
   console.error("Error:", error.message);
   setLoading(false);
  }
 };

 const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
   const slice = byteCharacters.slice(offset, offset + 512);
   const byteNumbers = new Array(slice.length);

   for (let i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
   }

   const byteArray = new Uint8Array(byteNumbers);
   byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
 };

 const saveImageToServer = async () => {
  if (!data.image) {
   showAlert({
    title: "Error!",
    text: "No NFT image found.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const metadata = {
   name: `${name} #${+supply + 1}`,
   symbol: symbol,
   description:
    "A unique piece of adult art created with Ai using text prompts on the ai18plus platform.",
   image: `${domain}uploads/generated/images/${+supply + 1}.png`,
   edition: +supply + 1,
   external_url: domain,
  };

  const contentType = "image/png"; // Adjust this if needed
  const imageBlob = base64ToBlob(data.image, contentType);
  console.log("Image Blob:", imageBlob);
  console.log("Blob Size:", imageBlob.size);
  console.log("Blob Type:", imageBlob.type);

  const metadataBlob = new Blob([JSON.stringify(metadata)], {
   type: "application/json",
  });

  const formData = new FormData();
  formData.append("prompt", prompt || "None");
  formData.append("wallet", address);
  formData.append("nft_id", +supply + 1);
  formData.append("action", "save_generated_images");
  formData.append("image", imageBlob, `${+supply + 1}.png`);
  formData.append("metadata", metadataBlob, `${+supply + 1}.json`);

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });

   const datas = response.data;
   console.log("DATAS:", datas);

   if (datas.success) {
    await getSupply();
    setHideSave(false);
    showAlert({
     title: "Success!",
     text: datas.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    showAlert({
     title: "Error!",
     text: datas.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
    console.log("Error:", datas.message);
   }
  } catch (error) {
   showAlert({
    title: "Error!",
    text: "An unexpected error occurred. Please try again later.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   console.error("Error:", error);
  } finally {
   setLoading(false);
  }
 };

 async function mint() {
  if (!isConnected) {
   showAlert({
    title: "Error!",
    text: "Please connect a wallet first.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  if (Object.keys(data).length === 0 || !data.image) {
   showAlert({
    title: "Error!",
    text: "No images found.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }
  setLoading(true);
  try {
   let signer;
   let contract;

   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   contract = new Contract(nftContract, nftABI, signer);

   const data = await contract.mint();
   console.log("MINT", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      await saveImageToServer();
      // await getSupply();
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("MINT ERROR", error);
   setLoading(false);

   showAlert({
    title: "ERROR!",
    text: "There was an error minting your NFT.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 }

 useEffect(() => {
  AOS.init({
   duration: 1000,
   once: false,
  });
 }, []);

 const playVideo = (videoRef) => {
  videoRef.current.play();
 };

 const pauseVideo = (videoRef) => {
  videoRef.current.pause();
  videoRef.current.currentTime = 0; // Reset video to start
 };

 const getPopularNft = async () => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_nft18" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularNft(data);
    console.log("All top:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getPopularNft();
 }, []);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div
      className="thim-banner_home-1 ai-home-banner stylish-banner"
      style={{
       backgroundImage: "url(assets/images/home.jpg)",
       backgroundAttachment: "scroll",
       borderBottomLeftRadius: "50% 40px",
       borderBottomRightRadius: "50% 40px",
       overflow: "hidden",
      }}
     >
      <div className="container-fluid">
       <div className="bp-element bp-element-st-list-videos vblog-layout-1">
        <div className="wrap-element">
         <div className="feature-item"></div>
        </div>
       </div>
      </div>
     </div>
     {/*POPULAR-*/}

     <div className="container-fluid my-5 p-sm-0 p-lg-5 p-md-5">
      <div className="row">
       <div data-aos="fade-right" className="col-12 text-center">
        <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
         POPULAR CREATORS
        </h2>
        {topCreators.length > 0 && (
         <Swiper
          navigation={true}
          modules={[Pagination, Autoplay, Navigation]}
          autoplay
          //effect="fade"
          pagination={{ clickable: true }}
          // slidesPerView={3}
          loop={true}
          breakpoints={{
           640: {
            slidesPerView: 2,
            spaceBetween: 20,
           },
           768: {
            slidesPerView: 3,
            spaceBetween: 30,
           },
           1024: {
            slidesPerView: 5,
            spaceBetween: 30,
           },
          }}
          className="my-5"
         >
          {topCreators.length > 0 &&
           topCreators.map((v, i) => (
            <SwiperSlide key={`p-creators${i}`}>
             {" "}
             <div
              className="creator-card"
              onClick={() => window.open(`profile?user=${v.username ? v.username : v.wallet}`, "")}
             >
              <div className="card-image">
               <img src={domain + v.profile_photo} className="card-img-home" alt="User image" />
               <div className="card-overlay">
                <span className="likes">
                 {Math.round(formatNumber(v.fans_count))}{" "}
                 <i className="fa fa-heart text-danger" aria-hidden="true"></i>
                </span>
                <span className="age">
                 {" "}
                 {Math.round(formatNumber(v.posts_count))}{" "}
                 <i className="fa fa-th text-warning" aria-hidden="true"></i>
                </span>
               </div>
              </div>
              <div className="card-footer">
               <div className="card-name">
                <span>{!v.username ? shortenAddress(v.wallet) : v.username}</span>

                <span className="verified">
                 {" "}
                 {v.verified === "yes" && (
                  <i className="fa fa-check-circle verified" aria-hidden="true" />
                 )}
                </span>
               </div>
              </div>
             </div>
            </SwiperSlide>
           ))}
         </Swiper>
        )}
       </div>
      </div>
     </div>
     <div className="text-center">
      <img src="/assets/images/step1.png" height={150} />
     </div>
     <section
      className="py-5 px-3 overlay-sectiona"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
       borderTopLeftRadius: "50% 40px",
       borderTopRightRadius: "50% 40px",
       overflow: "hidden",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div className="col-12">
         <div data-aos="fade-left" className="col-12 text-center my-5">
          <h2
           className="display-4"
           style={{ color: "#fff", textShadow: "2px 2px 4px red", fontWeight: 700 }}
          >
           CREATE YOUR AI MODEL
          </h2>
         </div>
         <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
           <div className="card bg-dark">
            {Object.keys(data).length === 0 && <div></div>}
            {Object.keys(data).length > 0 && (
             <div className="row">
              <div className="col-sm-12 my-2">
               <img
                src={`data:image/png;base64,${data.image}`}
                className="card-img-top"
                alt="Generated Image"
                style={{
                 width: "100%",
                 height: "auto",
                }}
               />
              </div>
             </div>
            )}

            <div className="card-body">
             <div className="text-right d-flex mb-5">
              {hideSave && (
               <div className="mr-3">
                <button className="btn btn-success mb-3" id="downloadButton" onClick={mint}>
                 Mint as NFT
                </button>
               </div>
              )}
              {Object.keys(data).length > 0 && (
               <div>
                <button className="btn btn-danger mb-3" id="downloadButton" onClick={refresh}>
                 Clear
                </button>
               </div>
              )}
             </div>
             <label className="text-white">Prompt</label>
             <textarea
              style={{ background: "#000" }}
              className="form-control"
              rows={6}
              placeholder="Type a detailed description of the image you need."
              id="promptText"
              value={prompt}
              onInput={(e) => setPrompt(e.target.value)}
              required
             />

             <div className="row my-3">
              <div className="col-md-6">
               <label>Aspect Ratio </label>
               <select onChange={(e) => setRatio(e.target.value)}>
                <option disabled>Chose aspect ratio</option>
                <option value="1:1" selected>
                 1:1
                </option>
                <option value="4:5">4:5</option>
                <option value="5:4">5:4</option>
                <option value="2:3">2:3</option>
                <option value="3:2">3:2</option>
                <option value="4:7">4:7</option>
                <option value="7:4">7:4</option>
               </select>
              </div>
              <div className="col-md-6">
               <label>Image style</label>
               <select onChange={(e) => setImgStyle(e.target.value)}>
                <option value="photorealism" selected>
                 Photorealism
                </option>
                <option value="art">Art</option>
                <option value="anime">Anime</option>
               </select>
              </div>
             </div>

             <div
              className="w-100 text-center p-3 my-3 rounded text-white"
              style={{
               backgroundColor: "#111",
               border: "1px red solid",
              }}
             >
              <i className="text-warning fa fa-info-circle" aria-hidden="true"></i> You will be
              charged <strong>{formatNumber(settings.porn_face_fee)}</strong> v18plus tokens for
              this service.
             </div>

             <div className="text-center">
              <button className="btn btn-danger mt-3" id="generateButton" onClick={generateImage}>
               Generate Image
              </button>
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className="col-md-2"></div>
        </div>
       </div>
      </div>
     </section>

     <div className="container-fluid my-5 p-sm-0 p-lg-5 p-md-5">
      <div className="text-center">
       <img src="/assets/images/step2.png" height={150} />
      </div>
      <div className="row">
       <div data-aos="fade-right" className="col-12 text-center my-5">
        <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
         FACE SWAP
        </h2>
       </div>

       <div className="col-12">
        <NewSwap />
       </div>

       <div data-aos="fade-left" className="col-12 text-center">
        <div className="text-center mb-4">
         <img src="/assets/images/step3.png" height={150} />
        </div>
        <a href="/post-fan-video" className="bubble-button mt-3">
         POST YOUR AI MODELS
        </a>
       </div>
      </div>
     </div>
     <section
      className="py-5 px-3 overlay-section"
      style={{
       backgroundImage: 'url("assets/images/nft-bg4.jpeg")',
       backgroundRepeat: "no-repeat",
       backgroundAttachment: "fixed",
       backgroundSize: "cover",
       borderTopLeftRadius: "50% 40px",
       borderTopRightRadius: "50% 40px",
       overflow: "hidden",
      }}
     >
      <div className="container">
       <div className="content row my-5">
        <div data-aos="fade-right" className="col-12 text-center my-5">
         <h2
          className="display-4"
          style={{ color: "#fff", textShadow: "2px 2px 4px red", fontWeight: 700 }}
         >
          POPULAR NFTs
         </h2>
        </div>
        <div className="col-12">
         <Swiper
          navigation={true}
          modules={[Pagination, Autoplay, Navigation]}
          autoplay
          //effect="fade"
          pagination={{ clickable: true }}
          // slidesPerView={3}
          loop={true}
          breakpoints={{
           640: {
            slidesPerView: 1,
            spaceBetween: 20,
           },
           768: {
            slidesPerView: 2,
            spaceBetween: 30,
           },
           1024: {
            slidesPerView: 3,
            spaceBetween: 30,
           },
          }}
          className="my-5"
         >
          {popularNft.length > 0 ? (
           popularNft.map((p, i) => (
            <>
             <SwiperSlide key={`nft${i}`}>
              <div className="card nft-card">
               <img src={domain + p.image_paths} className="card-img-top" alt="NFT" />
               <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                 <div>
                  <img src={domain + p.profile_photo} className="avatar" alt="Avatar" />
                  <span className="badge badge-secondary">
                   By{" "}
                   <a href={`profile?user=${!p.username ? p.wallet : p.username}`} target="_blank">
                    {!p.username ? shortenAddress(p.wallet) : p.username}
                   </a>{" "}
                   {p.verified === "yes" && (
                    <i className="fa fa-check-circle text-success" aria-hidden="true" />
                   )}
                  </span>
                 </div>
                </div>
                <h5 className="card-title">
                 {name} #{p.id}
                </h5>

                <p className="price">
                 {formatNumber(p.price)} v18plus{" "}
                 <span className="text-primary">
                  ($
                  {formatNumber(Math.round(tokenPrice * p.price))})
                 </span>
                </p>
                <div
                 className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
                >
                 <button
                  onClick={() => handleLike(p.id)}
                  className={`${searchArrayBool(p.liked_by_users, address) ? "likes" : "not-like"}`}
                 >
                  <i className="fa fa-heart" />{" "}
                  {searchArrayBool(p.liked_by_users, address) ? "Unlike" : "Like"}
                 </button>{" "}
                 <button
                  className="text-primary ml-2"
                  data-toggle="modal"
                  data-target={`#plikeModal-${i}`}
                 >
                  {Math.round(formatNumber(p.like_count))}
                 </button>
                </div>

                <div className="text-center mt-1">
                 <button
                  className="btn btn-custom"
                  onClick={() => signMessage(p.wallet, p.id, p.price)}
                  disabled={!isConnected || address === p.wallet}
                 >
                  Buy this NFT
                 </button>
                </div>
               </div>
              </div>
              {/*BUY NFT modal*/}
              <div
               className="modal fade"
               id={`pbuyModal-${i}`}
               tabIndex={-1}
               role="dialog"
               aria-labelledby={`pbuyModalLabel-${i}`}
               aria-hidden="true"
              >
               <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                 <div className="modal-header">
                  <h3 className="modal-title" id={`pbuyModalLabel-${i}`}>
                   Buy NFT
                  </h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">×</span>
                  </button>
                 </div>
                 <div className="modal-body">
                  You are buying
                  <b>
                   {" "}
                   {name} #{p.id}
                  </b>{" "}
                  for {formatNumber(p.price)} v18plus ($
                  {formatNumber(Math.round(tokenPrice * p.price))}) and your v18plus balance is{" "}
                  {formatNumber(user.credits)} ($
                  {formatNumber(Math.round(tokenPrice * user.credits))}
                  ). Hit the PROCEED button to continue.
                 </div>

                 <div className="modal-footer">
                  <button
                   type="button"
                   className="btn btn-custom"
                   onClick={() => signMessage(p.wallet, p.id, p.price)}
                  >
                   Proceed
                  </button>

                  <button
                   type="button"
                   className="btn btn-secondary close-btn"
                   data-dismiss="modal"
                  >
                   Close
                  </button>
                 </div>
                </div>
               </div>
              </div>
             </SwiperSlide>
            </>
           ))
          ) : (
           <div className="text-center">
            <img src="assets/images/fly.gif" height={100} />
            <p>No NFTs here yet...</p>
           </div>
          )}
         </Swiper>
        </div>
       </div>
      </div>
     </section>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
   {/*modals*/}
   <div
    className="modal fade"
    id="intro"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(exvRef)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={exvRef}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/intro.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="watch"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(watchVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={watchVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/watch2earn.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="fan"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(fanVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={fanVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/just-fans.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="ai"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(aiVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={aiVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/porn-face.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="swap"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(swapVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={swapVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/face-swap.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}
   <div
    className="modal fade"
    id="nft"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="intro"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-lg" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => handleStopAndReset(nftVid)}
       >
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <video
        ref={nftVid}
        controls
        width="100%"
        // height="660px"

        onError={(e) => console.error("Error loading video:", e)}
        className="mt-3"
       >
        <source src={domain + "uploads/explainer/nft.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </div>
     </div>
    </div>
   </div>
   {/*===========================*/}

   {/*END MODALS*/}
  </div>
 );
}

export default Home;

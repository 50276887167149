import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";
import "./style.css";
import SpinnerOverlay from "./Spinner";
import { formatNumber, shortenAddress, getQueryParam, formatLocalTime } from "../utils/helpers";
import { Modal, Container, Form, Button, Image, Row, Col, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useAppKit } from "@reown/appkit/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm"; // Your payment form component

const Header = () => {
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  getUser,
  loading,
  setLoading,
  buyCredits,
  domain,
  tokenPrice,
  gamer,
  getGamer,
  settings,
 } = useContext(Context);
 const { open } = useAppKit();

 const [paymentOption, setPaymentOption] = useState("crypto");
 const [wallet, setWallet] = useState("");
 const [amount, setAmount] = useState("");
 const [showSearch, setShowSearch] = useState(false);
 const [showDrops, setShowDrops] = useState(false);

 const [ageAlert, setAgeAlert] = useState(false);
 const [isChecked, setIsChecked] = useState(false);
 const [ref, setRef] = useState("");
 const [query, setQuery] = useState("");
 const [results, setResults] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const [isSticky, setIsSticky] = useState(false);
 const [unread, setUnread] = useState(0);
 const [unreadAlerts, setUnreadAlerts] = useState(0);

 const [activeSubmenu, setActiveSubmenu] = useState(null);
 const [userFans, setUserFans] = useState([]);
 const [menuOpen, setMenuOpen] = useState(false);
 const location = useLocation(); // Get the current path
 const stripePromise = loadStripe(settings.stripe_pk);
 const isActive = (path) => location.pathname === path;
 const toggleMenu = () => setMenuOpen(!menuOpen);
 const closeMenu = () => setMenuOpen(false);
 const toggleSubmenu = (menu) => {
  setActiveSubmenu(activeSubmenu === menu ? null : menu);
 };

 const getUserFans = async (wallet) => {
  if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_fans",
    wallet: wallet,
   };
   if (user.username != null) {
    details.is_username = "yes";
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUserFans(data);
    //  console.log("All fans:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserFans(address);
 }, [address]);

 useEffect(() => {
  if (getQueryParam("ref") != null) {
   localStorage.setItem("modeland_ref_by", getQueryParam("ref"));
   setRef(getQueryParam("ref"));
  } else if (localStorage.getItem("modeland_ref_by") != null) {
   setRef(localStorage.getItem("modeland_ref_by"));
  } else {
   return;
  }
 }, []);

 const addWallet = async (connection, wallet) => {
  if (!connection || !wallet) return;
  try {
   const info = {
    action: "add_user_wallet",
    wallet: wallet,
   };
   if (ref !== "") {
    info.ref = ref;
   }
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(info),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.added) {
    console.error("Error:", data.message);
   } else {
    await getUser(address);
    await getGamer(address);
    //  setRelatedVideos(data);
    console.log("Success:", data.message);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  addWallet(isConnected, address);
 }, [isConnected, address]);

 useEffect(() => {
  if (query.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", query);
    formData.append("type", "all");

    formData.append("action", "search_creators");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setResults([]);
  }
 }, [query]);

 useEffect(() => {
  const handleScroll = () => {
   const triggerPoint = document.querySelector("#start-sticky").offsetTop;

   // Check if scroll position has crossed the trigger point
   if (window.scrollY > triggerPoint) {
    setIsSticky(true);
   } else {
    setIsSticky(false);
   }
  };

  // Attach the scroll event listener
  window.addEventListener("scroll", handleScroll);
  return () => {
   // Clean up the scroll event listener
   window.removeEventListener("scroll", handleScroll);
  };
 }, []);

 useEffect(() => {
  const fetchUnread = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_chat");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     // console.log("UNREAD:", response.data);

     setUnread(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnread(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnread();
  const interval = setInterval(() => {
   fetchUnread();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  const fetchUnreadA = async () => {
   if (!isConnected) {
    return;
   }

   const formData = new FormData();
   formData.append("receiver", address);
   formData.append("action", "get_unread_notifications");

   try {
    const response = await axios.post(apiURL, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    });

    if (response.data.success) {
     // console.log("UNREAD:", response.data);

     setUnreadAlerts(response.data.unread);
    } else {
     // console.log("AUNREAD:", response.data);

     setUnreadAlerts(0);
    }
   } catch (error) {
    console.error("Error:", error);
   }
  };

  fetchUnreadA();
  const interval = setInterval(() => {
   fetchUnreadA();
  }, 3000);
  return () => clearInterval(interval);
 }, [isConnected, address]);

 useEffect(() => {
  // Check if the user has already confirmed their age
  const hasConfirmed = localStorage.getItem("ModelandAgeConfirmed");
  if (!hasConfirmed) {
   setAgeAlert(true);
  }
 }, []);

 const handleConfirm = () => {
  if (isChecked) {
   localStorage.setItem("ModelandAgeConfirmed", "true"); // Set confirmation flag in localStorage
   setAgeAlert(false); // Close the modal
  } else {
   alert("Please confirm your age by checking the box.");
  }
 };

 const handlePayChange = (event) => {
  setPaymentOption(event.target.value);
 };

 return (
  <>
   <SpinnerOverlay loading={loading} />

   <Row className="py-5 new-top-header ">
    <Col md={2}></Col>
    <Col md={8} className="text-center">
     <a href="/" className="logo">
      <img
       src={`${domain}assets/images/modeland-logo.png`}
       alt="IMG"
       style={{ height: "100px", width: "auto" }}
      />
     </a>
    </Col>
    <Col md={2} className="text-center mt-3">
     <div className="text-center">
      {!isConnected && <appkit-button balance="hide" label="SIGN IN" />}
      {isConnected && (
       <div
        className="p-1"
        style={{
         border: "1px red solid",
         borderRadius: "15px",
         fontSize: "12px",
         cursor: "pointer",
        }}
        onClick={() => open()}
       >
        <i className="fa fa-credit-card" aria-hidden="true"></i> {shortenAddress(address)}
       </div>
      )}
     </div>
     {isConnected && (
      <>
       <div className="right-icons">
        <div
         className="p-1"
         style={{ border: "1px #333 solid", borderRadius: "15px", fontSize: "12px" }}
        >
         {formatNumber(user.credits)} v18plus
        </div>
        <img
         data-toggle="modal"
         data-target="#buyModal"
         data-backdrop="static"
         src="assets/images/svg/plus-circle.svg"
        />
       </div>
       <div
        className="right-icons"
        style={{ cursor: "pointer" }}
        onClick={() => setShowDrops(true)}
       >
        <div
         className="p-1"
         style={{ border: "1px #333 solid", borderRadius: "15px", fontSize: "12px" }}
        >
         {formatNumber(gamer.credits)} Airdrop
        </div>
        <i className="fa fa-eye text-danger" aria-hidden="true" style={{ fontSize: "30px" }}></i>

        {/*
        <a href="/game">
         <img src="assets/images/svg/plus-circle.svg" />
        </a>
        */}
       </div>
      </>
     )}
    </Col>
    <Col>
     <div id="start-sticky" />
     <nav className={`new-nft-nav-container ${isSticky ? "fixed-bottom-header" : ""}`}>
      {/* Main Navigation */}
      <ul className="new-nft-nav-menu">
       <li className={`new-nft-nav-item ${isActive("/") ? "new-nft-active" : ""}`}>
        <Link to="/">HOME</Link>
       </li>
       <li className={`new-nft-nav-item ${isActive("/about") ? "new-nft-active" : ""}`}>
        <Link to="/about">ABOUT</Link>
       </li>
       <li
        className={`new-nft-nav-item new-nft-submenu-parent ${
         isActive("/creators") ? "new-nft-active" : ""
        }`}
       >
        <Link to="/creators">CREATORS</Link>
        <ul className="new-nft-submenu">
         <li>
          <Link to="/become-creator">Become a Creator</Link>
         </li>
         <li>
          <Link to="/my-ai-models">Create your Model</Link>
         </li>
         <li>
          <Link to="/face-swap">Face Swap</Link>
         </li>
        </ul>
       </li>
       <li className={`new-nft-nav-item ${isActive("/videos") ? "new-nft-active" : ""}`}>
        <Link to="/videos">VIDS</Link>
       </li>
       <li className={`new-nft-nav-item ${isActive("/live") ? "new-nft-active" : ""}`}>
        <Link to="/live">LIVE NOW</Link>
       </li>
       <li
        className={`new-nft-nav-item new-nft-submenu-parent ${
         isActive("/marketplace") ? "new-nft-active" : ""
        }`}
       >
        <Link to="/marketplace">MARKETPLACE</Link>
        <ul className="new-nft-submenu">
         <li>
          <Link to="/onchain-nft">NFT IDs</Link>
         </li>
         <li>
          <Link to="/profile-nft">Invest in a creator</Link>
         </li>
         <li>
          <Link to="/create-nft">Mint your NFT</Link>
         </li>
        </ul>
       </li>
       <li className="new-nft-nav-item">
        <a href="https://ai18pluscoin.com/" target="_blank" rel="noopener noreferrer">
         COIN18+
        </a>
       </li>
       <li className="new-nft-nav-item">
        <a href="https://blog.modeland.ai/" target="_blank" rel="noopener noreferrer">
         BLOG
        </a>
       </li>
      </ul>

      {/* Icons Section */}
      <div className="new-nft-nav-icons">
       <i className="fa fa-search" onClick={() => setShowSearch(true)}></i>

       <a href="/contact">
        <i className="fa fa-envelope"></i>
       </a>
       {isConnected && (
        <>
         <a href="/notifications">
          <i className="fa fa-bell"></i>
          {unreadAlerts > 0 && (
           <sup>
            <span className="badge badge-danger">{unreadAlerts}</span>
           </sup>
          )}
         </a>
         <a href="/chat">
          <i className="fa fa-commenting"></i>
          {unread > 0 && (
           <sup>
            <span className="badge badge-danger">{unread}</span>
           </sup>
          )}
         </a>

         <a href={`#`} onClick={toggleMenu}>
          <img src={domain + user.profile_photo} style={{ borderRadius: "100%" }} />{" "}
         </a>
        </>
       )}
      </div>
     </nav>
    </Col>
   </Row>

   {/* Overlay */}
   {menuOpen && <div className="overlay" onClick={closeMenu}></div>}

   {/* Side Menu */}
   <div className={`side-menu ${menuOpen ? "open" : ""}`} onClick={closeMenu}>
    <div className="side-menu-content" onClick={(e) => e.stopPropagation()}>
     {/* Header */}
     <div className="side-menu-header d-flex align-items-center">
      {isConnected ? (
       <>
        <img
         src={domain + user.profile_photo}
         alt="Avatar"
         className="rounded-circle menu-avatar"
        />
        <div className="ms-3">
         <h5 className="mb-0">
          {" "}
          {!user.username ? shortenAddress(user.wallet) : user.username}{" "}
          {user.verified === "yes" && (
           <i className="fa fa-check-circle text-primary" aria-hidden="true" />
          )}
         </h5>
        </div>
       </>
      ) : (
       <>
        <img
         src={domain + "assets/images/avatar.svg"}
         alt="Avatar"
         className="rounded-circle menu-avatar"
        />
        <div className="ms-3">
         <h5 className="mb-0">Guest</h5>
        </div>
       </>
      )}
      <i className="fa fa-times ms-auto close-icon" onClick={closeMenu}></i>
     </div>
     {isConnected && (
      <div className="d-flex justify-content-between">
       <div>
        <i className="fa fa-credit-card" aria-hidden="true"></i> {formatNumber(user.credits)}
       </div>
       <div>
        <i className="fa fa-heart-o" aria-hidden="true"></i> Fans {userFans.length}
       </div>
      </div>
     )}
     {/* Menu Items */}
     <ul className="menu-list mt-4">
      <li className="list">
       <a href={`profile?user=${user.username !== null ? user.username : user.wallet}`}>
        View Profile
       </a>
      </li>
      <li className="list">
       <a href="/edit-profile">Edit Profile</a>
      </li>
      <li className="list">
       <a href="/chat">
        My Chats{" "}
        {unread > 0 && (
         <sup>
          <span className="badge badge-danger">{unread}</span>
         </sup>
        )}
       </a>
      </li>
      {user.is_creator === "yes" && (
       <li className="list">
        <a href="/post-fan-video">Create A Post</a>
       </li>
      )}
      <li className="list">
       <i className="fa fa-envelope-o"></i> <a href="/contact">CONTACT</a>
      </li>
     </ul>
    </div>
   </div>

   {/*BUY CREDITS modal*/}
   <div
    className="modal fade"
    id="buyModal"
    tabIndex={-1}
    role="dialog"
    aria-labelledby="buyModalLabel"
    aria-hidden="true"
   >
    <div className="modal-dialog modal-md" role="document">
     <div className="modal-content">
      <div className="modal-header">
       <h3 className="modal-title" id="buyModalLabel">
        Buy v18plus for self or friends
       </h3>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
       </button>
      </div>
      <div className="modal-body">
       <Form className="my-4">
        <h3 style={{ textAlign: "center" }}>How do you want to pay?</h3>

        <Form.Check
         type="switch"
         id="switch-crypto"
         label="With 18plus tokens"
         name="paymentOption"
         value="crypto"
         checked={paymentOption === "crypto"}
         onChange={handlePayChange}
         inline // Display switches inline
        />

        <Form.Check
         type="switch"
         id="switch-card"
         label="With card"
         name="paymentOption"
         value="card"
         checked={paymentOption === "card"}
         onChange={handlePayChange}
         inline // Display switches inline
        />
       </Form>
       {paymentOption === "card" && (
        <div className="mb-2">
         <Elements stripe={stripePromise}>
          <CheckoutForm />
         </Elements>
        </div>
       )}
       {paymentOption === "crypto" && (
        <>
         <h3 style={{ textAlign: "center" }}>Pay with 18plus tokens</h3>
         <div className="mb-2">
          <input
           type="text"
           style={{
            background: "#111",
            height: "50px",
            color: "#fff",
            width: "100%",
           }}
           placeholder="Paste recipient's wallet"
           onInput={(e) => setWallet(e.target.value)}
           value={wallet}
          />
         </div>

         <div>
          <input
           type="number"
           style={{
            background: "#111",
            height: "50px",
            color: "#fff",
            width: "100%",
           }}
           placeholder="Amount"
           onInput={(e) => setAmount(e.target.value)}
           value={amount}
          />
         </div>
         <div className="text-center">
          {isConnected ? (
           <button
            type="button"
            className="btn btn-custom mt-4"
            onClick={() => buyCredits(wallet, amount)}
           >
            Pay {amount && formatNumber(amount)} tokens
           </button>
          ) : (
           <button disabled={true} type="button" className="btn btn-custom mt-4">
            Connect your wallet first.
           </button>
          )}
         </div>
        </>
       )}
      </div>

      <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-dismiss="modal">
        I'm done
       </button>
      </div>
     </div>
    </div>
   </div>

   <Modal show={showDrops} onHide={() => setShowDrops(false)} backdrop="static">
    <Modal.Header closeButton>
     <Modal.Title className="text-white">Your Airdrops</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-white">
     <a href="#" className="btn btn-custom my-3">
      Get more airdrops
     </a>
     {gamer && gamer?.info?.length > 0 && (
      <table className="data-table">
       <thead>
        <tr>
         <th>Amount</th>
         <th>Day</th>
         <th>Hour</th>
         <th>Expiry Date</th>
        </tr>
       </thead>
       <tbody>
        {gamer.info.map((item) => (
         <tr key={item.id}>
          <td>{formatNumber(item.credits)}</td>
          <td>{item.day}</td>
          <td>{item.hour}:00 UTC</td>
          <td>{formatLocalTime(item.expiry_date)}</td>
         </tr>
        ))}
       </tbody>
      </table>
     )}
     {gamer?.info?.length === 0 && (
      <div className="text-center">
       No airdrops to show at this time. <a href="#">Play our scratch game</a> to earn some.
      </div>
     )}
    </Modal.Body>
   </Modal>

   <Modal show={showSearch} onHide={() => setShowSearch(false)} backdrop="static">
    <Modal.Header closeButton>
     <Modal.Title className="text-white">Search Creators</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-white">
     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label>Paste creator's wallet or username</Form.Label>
      <Form.Control
       type="search"
       placeholder="Paste wallet or username"
       autoFocus
       onInput={(e) => setQuery(e.target.value)}
      />
     </Form.Group>
     {isLoading && <p>Loading...</p>}
     <ul>
      {results.length > 0 &&
       results.map((user, index) => (
        <li key={index} className="my-2 search-hover" style={{ background: "transparent" }}>
         <a
          href={`profile?user=${!user.username ? user.wallet : user.username}`}
          style={{ color: "#fff" }}
          target="_blank"
         >
          <div className="text-left ml-5">
           <img
            src={domain + user.profile_photo}
            alt="Profile"
            width="50"
            className="mr-2"
            style={{ borderRadius: "50%" }}
           />
           {!user.username ? shortenAddress(user.wallet) : user.username}{" "}
           {user.verified === "yes" && <i className="fa fa-check-circle" aria-hidden="true" />}
          </div>
         </a>
        </li>
       ))}
     </ul>
    </Modal.Body>
   </Modal>

   <Modal show={ageAlert} backdrop="static" size="lg">
    <Modal.Header>
     <Modal.Title className="text-white">Declaration of Age and Eligibility</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-white">
     <Container>
      <h3>
       By accessing and using the services provided by Modeland, you confirm and agree to the
       following:
      </h3>
      <div>
       <h5>Minimum Age Requirement:</h5>
       <p>
        I hereby declare that I am at least 18 years of age or older, as required by the laws of my
        jurisdiction.
       </p>
      </div>
      <div>
       <h5>Eligibility to View Content:</h5>
       <p>
        I acknowledge that the platform may host content intended for mature audiences, and I am
        legally permitted to access such content in my country of residence.
       </p>
      </div>
      <div>
       <h5>False Declarations:</h5>
       <p>
        I understand that providing false information regarding my age may result in the immediate
        suspension of my account and forfeiture of access to all platform features.
       </p>
      </div>
      <div>
       <h5>Parental Responsibility:</h5>
       <p>
        I confirm that I am not allowing or enabling anyone under the age of 18 to access this
        platform through my account or devices.
       </p>
      </div>
      <div>
       <h5>Binding Agreement:</h5>
       <p>
        By proceeding, I agree to comply with Modeland’s Terms and Conditions, Privacy Policy, and
        all applicable laws.
       </p>
      </div>
      <p className="text-danger mt-4 alert-danger p-2">
       Failure to meet the age requirement or to comply with this declaration will result in denial
       of access to Modeland.
      </p>
      <Form>
       <Form.Group controlId="ageConfirmation">
        <Form.Check
         type="checkbox"
         label="I confirm that I am 18 years old or older and agree to the terms above."
         onChange={(e) => setIsChecked(e.target.checked)}
        />
       </Form.Group>
       <Button variant="danger" className="my-5" onClick={handleConfirm}>
        Confirm and Proceed
       </Button>
      </Form>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
     </Container>
    </Modal.Body>
   </Modal>
  </>
 );
};

export default Header;

const Pagination2 = ({ currentPage, totalPages, onPageChange }) => {
    const generatePages = () => {
      const pages = [];
  
      // Always include the first page
      pages.push(1);
  
      // Add an ellipsis if the current page is greater than 3
      if (currentPage > 3) {
        pages.push("...");
      }
  
      // Calculate start and end pages for the middle section
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
  
      // Add the middle pages
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
  
      // Add an ellipsis if the current page is less than totalPages - 2
      if (currentPage < totalPages - 2) {
        pages.push("...");
      }
  
      // Always include the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
  
      return pages;
    };
  
    const visiblePages = generatePages();
  
    return (
      <div className="d-flex justify-content-center my-4 align-items-center">
        {/* Previous Arrow */}
        <button
          className="btn btn-outline-danger mx-1"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &laquo; Prev
        </button>
  
        {/* Page Numbers */}
        {visiblePages.map((page, index) =>
          page === "..." ? (
            <span key={`ellipsis-${index}`} className="mx-2">
              ...
            </span>
          ) : (
            <button
              key={`page-${page}`}
              onClick={() => onPageChange(page)}
              className={`btn ${
                page === currentPage ? "btn-danger" : "btn-outline-danger"
              } mx-1`}
            >
              {page}
            </button>
          )
        )}
  
        {/* Next Arrow */}
        <button
          className="btn btn-outline-danger mx-1"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next &raquo;
        </button>
      </div>
    );
  };
  
  export default Pagination2;
  
import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import { showAlert } from "../../utils/helpers";
import "../styles/post-video.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./fan.css";
import Picker from "emoji-picker-react";

function PostFanVideo() {
 const {
  address,

  isConnected,

  apiURL,
  user,
  setLoading,
  domain,
 } = useContext(Context);
 const [categories, setCategories] = useState([]);
 const [aiVideos, setAiVideos] = useState([]);
 const [aiImages, setAiImages] = useState([]);
 const [aiImage, setAiImage] = useState("");
 const [emojiPickers, setEmojiPickers] = useState(false);
 const [payment, setPayment] = useState("free");
 const [price, setPrice] = useState("");

 const [aiVideo, setAiVideo] = useState("");
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [frame, setFrame] = useState(null);

 const [selectedFrame, setSelectedFrame] = useState("");

 const [category, setCategory] = useState("");
 const [title, setTitle] = useState("");

 const [videoType, setVideoType] = useState("real");
 const [imageType, setImageType] = useState("realp");

 const [selectedVideo, setSelectedVideo] = useState(null);
 const [selectedImage, setSelectedImage] = useState(null);
 const [selectedImages, setSelectedImages] = useState([]);
 const [photoPreview, setPhotoPreview] = useState([]);

 const [description, setDescription] = useState("");
 const [tags, setTags] = useState("");
 const [coverPhoto, setCoverPhoto] = useState(null);
 const [photo, setPhoto] = useState([]);

 const [uploadVideo, setUploadVideo] = useState(null);
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [uploadVideoPreview, setUploadVideoPreview] = useState(null);
 const [videoDurationValid, setVideoDurationValid] = useState(true);

 const [uploadProgress, setUploadProgress] = useState(0);

 const getAiVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_user_swaps",
     wallet: address,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAiVideos(data.filter((item) => item.type !== "PHOTO" && item.type !== "GIF"));

    console.log("AI VIDEOS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) {
   setAiVideos([]);
   setAiVideo("");
   return;
  }
  getAiVideos();
 }, [isConnected, address]);

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleVideoChange = (e) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith("video/")) {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";
   videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;
    if (duration > 600) {
     setVideoDurationValid(false);
     setUploadVideo(null);
     setUploadVideoPreview(null);
     return showAlert({
      title: "Error!",
      text: "Video is longer than 10 minutes.",
      icon: "error",
      confirmButtonText: "Ok",
     });
    } else {
     setVideoDurationValid(true);
     setUploadVideo(file);
     setUploadVideoPreview(URL.createObjectURL(file));
    }
   };
   videoElement.src = URL.createObjectURL(file);
  }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (videoType === "real") {
   if (user.verified !== "yes") {
    return showAlert({
     title: "Error!",
     text: "You can only post real videos/photos after you pass KYC verification.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (uploadVideo == null) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (coverPhoto == null) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a cover photo first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (!videoDurationValid) {
    return showAlert({
     title: "Error!",
     text: "Please select a video that is less than 10 minutes.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (videoType === "ai") {
   if (!aiVideo) {
    return showAlert({
     title: "Error!",
     text: "Please select an Ai video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (!frame) {
    return showAlert({
     title: "Error!",
     text: "Please select a cover photo from your Ai video.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (payment === "pay" && !price) {
   return showAlert({
    title: "Error!",
    text: "Please enter content price for Pay Per View option.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const formData = new FormData();
  formData.append("title", title);
  formData.append("payment_type", payment);
  if (payment === "pay") {
   formData.append("price", price);
  }

  formData.append("isAI", videoType === "ai" ? "yes" : "no");

  formData.append(
   "coverPhoto",
   videoType === "ai" ? base64ToBlob(frame.split(",")[1], "image/png") : coverPhoto,
   "frame.png"
  );
  formData.append("uploadVideo", videoType === "ai" ? aiVideo : uploadVideo);
  formData.append("poster", address);

  formData.append("action", "post_fan_video");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });
   //console.log("Form", response);
   if (response.data.success) {
    showAlert({
     title: "Success!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Ok",
    });
    setTimeout(() => {
     window.location.reload();
    }, 2000);
   } else {
    showAlert({
     title: "ERROR!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   // alert(`Error: ${error.message}`);
   // Handle network error
  }
  setLoading(false);
  setUploadProgress(0);
 };

 const postPhotos = async (e) => {
  e.preventDefault();
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  if (imageType === "realp") {
   if (user.verified !== "yes") {
    return showAlert({
     title: "Error!",
     text: "You can only post real pictures after you pass KYC verification.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (imageType === "realp" && photo.length === 0) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a photo first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (photo.length > 10) {
    return showAlert({
     title: "Error!",
     text: "A maximum of 10 images allowed per upload.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (imageType === "aip") {
   if (selectedImages.length === 0) {
    return showAlert({
     title: "Error!",
     text: "Please select an Ai model first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (payment === "pay" && !price) {
   return showAlert({
    title: "Error!",
    text: "Please enter content price for Pay Per View option.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  // Create FormData object to send the data
  const formData = new FormData();
  formData.append("title", title);
  formData.append("isAI", imageType === "aip" ? "yes" : "no");

  if (payment === "pay") {
   formData.append("price", price);
  }

  // Append real photos if selected
  if (imageType === "realp") {
   photo.forEach((file, index) => {
    formData.append(`realPhotos[${index}]`, file);
   });
  }

  // Append AI selected images (their paths)
  if (imageType === "aip") {
   selectedImages.forEach((imagePath, index) => {
    formData.append(`selectedImages[${index}]`, imagePath);
   });
  }
  formData.append("action", "post_fan_photos");
  formData.append("is_photo", "yes");
  formData.append("poster", address);
  formData.append("payment_type", payment);

  setLoading(true);

  try {
   // Post form data to the PHP script using axios
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },

    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });

   if (response.data.success) {
    setSelectedImages([]);
    setPhoto([]);
    setPhotoPreview([]);

    showAlert({
     title: "Success!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Ok",
    });
   } else {
    showAlert({
     title: "ERROR!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   alert("An error occurred during submission.");
   console.error("Submission error", error);
  }
  setLoading(false);
  setUploadProgress(0);
 };

 const handleVideoClick = async (link, index) => {
  setLoading(true);
  setSelectedVideo(index);

  // Download the video file
  const videoFile = await downloadVideo(domain + link);
  if (!videoFile) {
   console.error("Failed to download video");
   setLoading(false);

   return;
  }

  const videoURL = URL.createObjectURL(videoFile);
  const videoElement = videoRef.current;
  videoElement.src = videoURL;
  setAiVideo(link);

  videoElement.onloadedmetadata = () => {
   extractFrames(videoElement);
  };
  setLoading(false);
 };

 const downloadVideo = async (url) => {
  try {
   const response = await fetch(url);
   if (!response.ok) {
    throw new Error("Network response was not ok");
   }
   const blob = await response.blob();
   return blob;
  } catch (error) {
   console.error("Failed to download video:", error);
   return null;
  }
 };

 const extractFrames = async (video) => {
  // const video = videoRef.current;
  if (!video) return;

  const duration = video.duration;
  if (!duration || isNaN(duration)) {
   console.error("Failed to get video duration");
   setLoading(false);
   return;
  }

  setLoading(true);
  const interval = duration / 10; // Adjust for more or fewer frames
  const framesArray = [];

  // Function to seek the video and capture a frame
  const captureFrame = (time) => {
   return new Promise((resolve) => {
    video.currentTime = time;
    video.onseeked = () => {
     const canvas = canvasRef.current;
     const context = canvas.getContext("2d");
     context.drawImage(video, 0, 0, canvas.width, canvas.height);
     const frameData = canvas.toDataURL();
     resolve(frameData); // Resolve the promise with the captured frame
    };
   });
  };

  // Capture frames at each interval
  for (let i = 0; i <= 10; i++) {
   const currentTime = i * interval;
   if (isFinite(currentTime)) {
    try {
     const frame = await captureFrame(currentTime); // Wait for the frame capture
     framesArray.push(frame);
    } catch (error) {
     console.error("Error capturing frame at time:", currentTime, error);
    }
   } else {
    console.error("Calculated currentTime is not finite", currentTime);
   }
  }

  setFrames(framesArray);
  // setFrames(null);
  setSelectedFrame("");
  setLoading(false);
 };

 const handleFrame = async (frame, index) => {
  setSelectedFrame(index);
  setFrame(frame);
 };

 const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
   const slice = byteCharacters.slice(offset, offset + sliceSize);
   const byteNumbers = new Array(slice.length);
   for (let i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
   }
   const byteArray = new Uint8Array(byteNumbers);
   byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
 };

 const getUserImages = async (wallet) => {
  // if (!wallet || wallet == null) return;
  try {
   const details = {
    action: "get_user_images_and_swaps",
    wallet: wallet,
   };

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(details),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (!data.success) {
    // refresh();
    console.error("Errors:", data.error);
   } else {
    //  const m = mergeImagePaths(data);
    setAiImages(data.records);
    //  console.log("IMAGES:", m);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) return;
  getUserImages(address);
 }, [address, isConnected]);

 const handlePhotoChange = (e) => {
  const files = Array.from(e.target.files);
  const previews = files.map((file) => URL.createObjectURL(file));
  setPhotoPreview((prev) => [...prev, ...previews]);
  setPhoto((prev) => [...prev, ...files]); // Store actual file for upload
 };

 // Remove a real photo from the preview and file array
 const handleRemoveImage = (indexToRemove) => {
  setPhotoPreview((prev) => prev.filter((_, i) => i !== indexToRemove));
  setPhoto((prev) => prev.filter((_, i) => i !== indexToRemove));
 };

 // Handle AI image selection
 const handleImageClick = (imagePath) => {
  if (selectedImages.includes(imagePath)) {
   setSelectedImages(selectedImages.filter((img) => img !== imagePath));
  } else {
   setSelectedImages([...selectedImages, imagePath]);
  }
 };

 const toggleEmojiPicker = () => {
  setEmojiPickers((prev) => !prev);
 };

 // Handle emoji selection for a specific post
 const onEmojiClick = (event, emojiObject) => {
  const emoji = event.emoji;
  setTitle((prev) => prev + emoji);
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/bg-page-title.jpg)",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1>CREATE A POST</h1>
         <div className="description">Give your active fans something to fantacise about.</div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect a wallet
           to be able to make a post.
          </div>
         ) : (
          <>
           <div className="row my-4 p-2 rounded alert-danger mx-md-5 mx-sm-1">
            <div className="col-md-12">
             <h3>IMPORTANT NOTICE</h3>
             <p>
              If the content you wish to upload contains hard nudes or pornography, such content
              must be for your paid subscribers or marked as pay-per-view. DO NOT upload nudes as
              free content otherwise your profile will be deleted.
             </p>
            </div>
           </div>
           <ul className="nav nav-tabs my-4" id="vidTab" role="tablist">
            <li className="nav-item">
             <a
              className="nav-link active"
              id="vid-tab"
              data-toggle="tab"
              href="#vid"
              role="tab"
              aria-controls="vid"
              aria-selected="true"
             >
              Post video
             </a>
            </li>

            <li className="nav-item">
             <a
              className="nav-link"
              id="pic-tab"
              data-toggle="tab"
              href="#pic"
              role="tab"
              aria-controls="pic"
              aria-selected="false"
             >
              Post picture
             </a>
            </li>
           </ul>
           <div className="tab-content" id="vidTabContent">
            <div
             className="tab-pane fade show active"
             id="vid"
             role="tabpanel"
             aria-labelledby="new-arrivals-tab"
            >
             {user.is_creator === "yes" ? (
              <form onSubmit={handleSubmit}>
               <div className="row">
                <div className="col-md-12">
                 <div className="form-group">
                  <textarea
                   className="form-control"
                   id="videoTitle"
                   placeholder="Compose new post..."
                   onChange={(e) => setTitle(e.target.value)}
                   value={title}
                   style={{ height: "100px" }}
                   maxLength={400}
                  />
                  <div
                   style={{
                    textAlign: "right",
                    marginTop: "-30px",
                    zIndex: 4,
                    cursor: "pointer",
                    fontSize: "25px",
                    marginRight: "11px",
                   }}
                   onClick={() => toggleEmojiPicker()}
                  >
                   <i className="fa fa-smile-o text-primary" aria-hidden="true"></i>
                  </div>
                  <div className="text-right mr-2">{title.length} / 400</div>
                  {emojiPickers && (
                   <div style={{ position: "relative", top: "90%", right: "10px" }}>
                    <Picker
                     onEmojiClick={(event, emojiObject) => onEmojiClick(event, emojiObject)}
                    />
                   </div>
                  )}
                 </div>
                </div>
               </div>
               <div className="row">
                <div className="col-md-12 my-3">
                 <div className="row">
                  <div className="col-md-6">
                   <label htmlFor="real" className="rcontainer">
                    I am uploading a real video of myself
                    <input
                     id="real"
                     value="real"
                     type="radio"
                     name="creator"
                     onChange={(e) => setVideoType(e.target.value)}
                     checked={videoType === "real"}
                     required
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>

                  <div className="col-md-6">
                   <label htmlFor="ai" className="rcontainer">
                    I want to upload from my Ai videos
                    <input
                     id="ai"
                     type="radio"
                     name="creator"
                     value="ai"
                     onChange={(e) => setVideoType(e.target.value)}
                     checked={videoType === "ai"}
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>
                 </div>

                 {aiVideos.length > 0 && videoType === "ai" && (
                  <Swiper
                   navigation={true}
                   modules={[Pagination, Autoplay, Navigation]}
                   // slidesPerView={3}
                   loop={true}
                   breakpoints={{
                    640: {
                     slidesPerView: 2,
                     spaceBetween: 20,
                    },
                    768: {
                     slidesPerView: 4,
                     spaceBetween: 30,
                    },
                    1024: {
                     slidesPerView: 6,
                     spaceBetween: 30,
                    },
                   }}
                   className="my-5"
                  >
                   {aiVideos.map((v, index) => (
                    <SwiperSlide key={index}>
                     <div
                      className={`image-container ${selectedVideo === index ? "selected" : ""}`}
                      onClick={() => handleVideoClick(v.link, index)}
                     >
                      <video src={domain + v.link} height={150} controls alt="video placeholder" />
                      <div className="text-center">Select</div>
                     </div>
                    </SwiperSlide>
                   ))}
                  </Swiper>
                 )}
                 {aiVideos.length === 0 && videoType === "ai" && (
                  <div className="text-center">
                   <img src="assets/images/fly.gif" height={100} alt="No images" />
                   <p>
                    No Ai videos here yet...{" "}
                    <a className="text-warning" href="/face-swap" target="_blank">
                     Swap some videos to Ai here.
                    </a>
                   </p>
                  </div>
                 )}
                 <div className=" text-center my-5">
                  {videoType === "ai" && aiVideo && frames.length > 0 && (
                   <h3 className="mb-3">Click any frame in the video to use as cover photo </h3>
                  )}
                  <canvas ref={canvasRef} width="320" height="180" style={{ display: "none" }} />
                  <div
                   style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                   }}
                  >
                   {videoType === "ai" &&
                    aiVideo &&
                    frames.length > 0 &&
                    frames.map((frame, index) => (
                     <>
                      <img
                       key={index}
                       src={frame}
                       alt={`Frame ${index}`}
                       onClick={() => handleFrame(frame, index)}
                       style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "auto",
                       }}
                       className={`frame-image ${selectedFrame === index ? "selected" : ""}`}
                      />
                     </>
                    ))}
                  </div>
                 </div>
                </div>

                {videoType !== "ai" ? (
                 <div className="col-md-6 text-center">
                  <div className="form-group">
                   <label htmlFor="coverPhoto">
                    <img
                     src={coverPhotoPreview || "./assets/images/ph-image.png"}
                     height={150}
                     alt="cover placeholder"
                    />
                   </label>
                   <div className="custom-file">
                    <input
                     type="file"
                     className="custom-file-input"
                     id="coverPhoto"
                     accept="image/*"
                     onChange={handleCoverPhotoChange}
                     required
                    />
                    <label className="custom-file-label" htmlFor="coverPhoto">
                     Choose cover photo
                    </label>
                   </div>
                  </div>
                 </div>
                ) : (
                 <div className="col-md-6 text-center">
                  <div className="form-group">
                   <label htmlFor="coverPhoto">
                    <img
                     src={frame || "./assets/images/ph-image.png"}
                     height={150}
                     alt="cover placeholder"
                    />
                   </label>
                  </div>
                 </div>
                )}
                <div className="col-md-6 text-center">
                 {videoType !== "ai" ? (
                  <div className="form-group">
                   <label htmlFor="uploadVideo">
                    <video src={uploadVideoPreview} height={150} controls alt="video placeholder" />
                   </label>
                   <div className="custom-file">
                    <input
                     type="file"
                     className="custom-file-input"
                     id="uploadVideo"
                     accept="video/mp4"
                     onChange={handleVideoChange}
                     required
                    />
                    <label className="custom-file-label" htmlFor="uploadVideo">
                     Choose video file
                    </label>
                   </div>
                  </div>
                 ) : (
                  <video ref={videoRef} height={150} controls alt="video placeholder" />
                 )}
                </div>

                {uploadProgress > 0 && (
                 <div className="col-md-12 my-4">
                  <div className="progress">
                   <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadProgress}%` }}
                    aria-valuenow={uploadProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                   >
                    {uploadProgress}%
                   </div>
                  </div>
                 </div>
                )}
                <div className="col-md-12 my-5">
                 <div className="row">
                  <div className="col-md-4">
                   <label htmlFor="subscribers" className="rcontainer">
                    Only for Subscribers
                    <input
                     id="subscribers"
                     value="subscribers"
                     type="radio"
                     name="payment"
                     onChange={(e) => setPayment(e.target.value)}
                     checked={payment === "subscribers"}
                     required
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>

                  <div className="col-md-4">
                   <label htmlFor="pay" className="rcontainer">
                    Pay to View
                    <input
                     id="pay"
                     type="radio"
                     name="payment"
                     value="pay"
                     onChange={(e) => setPayment(e.target.value)}
                     checked={payment === "pay"}
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>
                  <div className="col-md-4">
                   <label htmlFor="free" className="rcontainer">
                    Free for Everyone
                    <input
                     id="free"
                     type="radio"
                     name="payment"
                     value="free"
                     onChange={(e) => setPayment(e.target.value)}
                     checked={payment === "free"}
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>
                 </div>
                </div>
                {payment === "pay" && (
                 <div className="col-md-12 my-3">
                  <div className="form-group">
                   <input
                    className="form-control"
                    placeholder="Content price"
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                    type="number"
                   />
                  </div>
                 </div>
                )}
                <div className="col-md-12">
                 <button type="submit" className="btn btn-custom btn-block">
                  Submit
                 </button>
                </div>
               </div>
              </form>
             ) : (
              <div className="alert-warning p-3 my-3 rounded">
               <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> You must be a
               creator to use this page.
              </div>
             )}
            </div>
            <div className="tab-pane fade" id="pic" role="tabpanel" aria-labelledby="pic-tab">
             {user.is_creator === "yes" ? (
              <form onSubmit={postPhotos}>
               <div className="row">
                <div className="col-md-12">
                 <div className="form-group">
                  <textarea
                   className="form-control"
                   id="videoTitle"
                   placeholder="Compose new post..."
                   onChange={(e) => setTitle(e.target.value)}
                   value={title}
                   style={{ height: "100px" }}
                   maxLength={400}
                  />
                  <div
                   style={{
                    textAlign: "right",
                    marginTop: "-30px",
                    zIndex: 4,
                    cursor: "pointer",
                    fontSize: "25px",
                    marginRight: "11px",
                   }}
                   onClick={() => toggleEmojiPicker()}
                  >
                   <i className="fa fa-smile-o text-primary" aria-hidden="true"></i>
                  </div>
                  <div className="text-right mr-2">{title.length} / 400</div>
                  {emojiPickers && (
                   <div style={{ position: "relative", top: "90%", right: "10px" }}>
                    <Picker
                     onEmojiClick={(event, emojiObject) => onEmojiClick(event, emojiObject)}
                    />
                   </div>
                  )}
                 </div>
                </div>
               </div>

               <div className="row">
                <div className="col-md-12 my-3">
                 <div className="row">
                  <div className="col-md-6">
                   <label htmlFor="realp" className="rcontainer">
                    I am uploading real pictures
                    <input
                     id="realp"
                     value="realp"
                     type="radio"
                     name="creatorp"
                     onChange={(e) => setImageType(e.target.value)}
                     checked={imageType === "realp"}
                     required
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>

                  <div className="col-md-6">
                   <label htmlFor="aip" className="rcontainer">
                    I want to choose from my AI models
                    <input
                     id="aip"
                     type="radio"
                     name="creatorp"
                     value="aip"
                     onChange={(e) => setImageType(e.target.value)}
                     checked={imageType === "aip"}
                    />
                    <span className="rcheckmark"></span>
                   </label>
                  </div>
                 </div>

                 {/* If AI images are selected */}
                 {aiImages.length > 0 && imageType === "aip" && (
                  <>
                   <Swiper
                    navigation={true}
                    modules={[Pagination, Autoplay, Navigation]}
                    loop={true}
                    breakpoints={{
                     640: { slidesPerView: 2, spaceBetween: 20 },
                     768: { slidesPerView: 4, spaceBetween: 30 },
                     1024: { slidesPerView: 6, spaceBetween: 30 },
                    }}
                    className="my-5"
                   >
                    {aiImages.map((v, index) => (
                     <SwiperSlide key={index}>
                      <div
                       className={`image-container ${
                        selectedImages.includes(v.path) ? "selected" : ""
                       }`}
                       onClick={() => handleImageClick(v.path)}
                      >
                       <img src={domain + v.path} height={150} alt="AI model" />
                       <div className="text-center">Select</div>
                      </div>
                     </SwiperSlide>
                    ))}
                   </Swiper>

                   {/* Preview selected AI images */}
                   <div className="row">
                    {selectedImages.length > 0 && (
                     <div className="col-md-12">
                      <h5>Selected AI Images:</h5>
                      <div className="preview-grid">
                       {selectedImages.map((image, idx) => (
                        <img
                         key={idx}
                         src={domain + image}
                         alt="Selected AI"
                         className="img-thumbnail"
                         height={100}
                        />
                       ))}
                      </div>
                     </div>
                    )}
                   </div>
                  </>
                 )}

                 {aiImages.length === 0 && imageType === "aip" && (
                  <div className="text-center">
                   <img src="assets/images/fly.gif" height={100} alt="No images" />
                   <p>
                    No AI models here yet...{" "}
                    <a className="text-warning" href="/my-ai-models" target="_blank">
                     create a model.
                    </a>
                   </p>
                  </div>
                 )}

                 {/* Real photo upload */}
                 {imageType === "realp" && (
                  <div className="col-md-12 text-center">
                   <div className="form-group">
                    <label htmlFor="realPhotos">
                     <img
                      src={photoPreview[0] || "./assets/images/ph-image.png"}
                      height={150}
                      alt="photo placeholder"
                     />
                    </label>
                    <div className="custom-file">
                     <input
                      type="file"
                      className="custom-file-input"
                      id="realPhotos"
                      accept="image/*"
                      multiple
                      onChange={handlePhotoChange}
                      required
                     />
                     <label className="custom-file-label" htmlFor="realPhotos">
                      Choose photos (up to 10 images)
                     </label>
                    </div>

                    {/* Preview selected real photos */}
                    <div className="preview-grid mt-3">
                     {photoPreview.map((preview, index) => (
                      <div key={index} className="preview-container">
                       <img
                        src={preview}
                        alt="Selected real"
                        className="img-thumbnail"
                        height={100}
                       />
                       <button
                        type="button"
                        className="remove-button btn btn-danger p-1"
                        onClick={() => handleRemoveImage(index)}
                       >
                        &times;
                       </button>
                      </div>
                     ))}
                    </div>
                   </div>
                  </div>
                 )}
                </div>
               </div>

               {uploadProgress > 0 && (
                <div className="col-md-12 my-4">
                 <div className="progress">
                  <div
                   className="progress-bar"
                   role="progressbar"
                   style={{ width: `${uploadProgress}%` }}
                   aria-valuenow={uploadProgress}
                   aria-valuemin="0"
                   aria-valuemax="100"
                  >
                   {uploadProgress}%
                  </div>
                 </div>
                </div>
               )}

               <div className="col-md-12 my-5">
                <div className="row">
                 <div className="col-md-4">
                  <label htmlFor="subscribersp" className="rcontainer">
                   Only for Subscribers
                   <input
                    id="subscribersp"
                    value="subscribers"
                    type="radio"
                    name="paymentp"
                    onChange={(e) => setPayment(e.target.value)}
                    checked={payment === "subscribers"}
                    required
                   />
                   <span className="rcheckmark"></span>
                  </label>
                 </div>

                 <div className="col-md-4">
                  <label htmlFor="payp" className="rcontainer">
                   Pay to View
                   <input
                    id="payp"
                    type="radio"
                    name="paymentp"
                    value="pay"
                    onChange={(e) => setPayment(e.target.value)}
                    checked={payment === "pay"}
                   />
                   <span className="rcheckmark"></span>
                  </label>
                 </div>
                 <div className="col-md-4">
                  <label htmlFor="freep" className="rcontainer">
                   Free for Everyone
                   <input
                    id="freep"
                    type="radio"
                    name="paymentp"
                    value="free"
                    onChange={(e) => setPayment(e.target.value)}
                    checked={payment === "free"}
                   />
                   <span className="rcheckmark"></span>
                  </label>
                 </div>
                </div>
               </div>

               {payment === "pay" && (
                <div className="col-md-12 my-3">
                 <div className="form-group">
                  <input
                   className="form-control"
                   placeholder="Content price"
                   onChange={(e) => setPrice(e.target.value)}
                   value={price}
                   type="number"
                  />
                 </div>
                </div>
               )}

               <div className="col-md-12 mt-5">
                <button type="submit" className="btn btn-custom btn-block">
                 Submit
                </button>
               </div>
              </form>
             ) : (
              <div className="alert-warning p-3 my-3 rounded">
               <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> You must be a
               creator to use this page.
              </div>
             )}
            </div>
           </div>
          </>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default PostFanVideo;

import { createContext, useEffect, useState } from "react";
import { BrowserProvider, Contract, formatUnits, parseUnits, JsonRpcProvider } from "ethers";
import { message } from "antd";
import abi from "../utils/controller.json";
import tokenAbi from "../utils/token.json";
import { bsc } from "@reown/appkit/networks";

import {
 createAppKit,
 useAppKit,
 useAppKitProvider,
 useAppKitAccount,
 useAppKitNetwork,
} from "@reown/appkit/react";
import { EthersAdapter } from "@reown/appkit-adapter-ethers";
import { Allowance } from "../utils/allowance";
import axios from "axios";

import { formatNumber, showAlert, searchArray } from "../utils/helpers";
const projectId = "f281e191c62ba243116180d84740a04f";
// 3. Create a metadata object
const metadata = {
 name: "Modeland",
 description: "The first real adult coin",
 url: "https://modeland.ai", // origin must match your domain & subdomain
 icons: ["https://modeland.ai/assets/images/icon.png"],
};

const bscMainnet = {
 chainId: 56,
 name: "Binance Smart Chain",
 currency: "BNB",
 explorerUrl: "https://bscscan.com",
 rpcUrl: "https://bsc-dataseed1.binance.org",
};

createAppKit({
 adapters: [new EthersAdapter()],
 networks: [bsc],
 metadata,
 projectId,
 features: {
  analytics: true,
  socials: ["google", "apple", "x"],
  email: false,
  swaps: false,
  onramp: false,
 },
});

const PANCAKE_ROUTER_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const PANCAKE_ROUTER_ABI = [
 // Minimal ABI to interact with the PancakeSwap V2 Router
 "function getAmountsOut(uint amountIn, address[] calldata path) external view returns (uint[] memory amounts)",
];
const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"; // USDT
const WBNB_ADDRESS = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // WBNB

export const Context = createContext();
export const ContextProvider = ({ children }) => {
 const { isConnected, allAccounts } = useAppKitAccount();
 //const { open } = useAppKit();
 const { walletProvider } = useAppKitProvider("eip155");
 const { chainId } = useAppKitNetwork();

 const [tokenBalance, setTokenBalance] = useState("0");
 const [settings, setSettings] = useState([]);
 const [user, setUser] = useState({});
 const [gamer, setGamer] = useState({});
 const [address, setAddress] = useState(null);

 const [loading, setLoading] = useState(false);
 const [isFan, setIsFan] = useState(false);
 const [isPlaying, setIsPlaying] = useState(false);
 const [activeScene, setActiveScene] = useState("gallery");
 const [videoId, setVideoId] = useState(null);
 const [videoOwner, setVideoOwner] = useState("");
 const [currentVideo, setCurrentVideo] = useState(null);
 const [intro, setIntro] = useState(true);
 const [tokenPrice, setTokenPrice] = useState(0);
 const domain = "https://modeland.ai/";
 //const domain = "https://ai18pluspresale.com/";

 const token = "0x0F800f2894780e3AbB881c19a3c2040d5BcB4f91";
 const controlContract = "0xdC18E784E74612bFef87Da3a2ebda8415d446A5f";
 const nftContract = "0x2Bc080E9A75d84382ED6b931EB7F93B14F8510fF";

 const apiURL = "https://modeland.ai/api.php";

 //const apiURL = "http://localhost/watch/api.php";
 const getImgKey =
  "key-4BqsmyP3Xav1loTSIzMwzWn4nAAcSPZHeUgpyYDFikaIvsoNW00rIFIIrYfrsagwd9GYdLrgV8RCbPDMX9vPiuuRIJlnXRE1";

 const generateDate = () => {
  const now = new Date(); // Current date/time
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(now.getUTCDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
 };

 const getGamer = async (wallet) => {
  //  console.log("ALL ACCT", wallet);
  setLoading(true);
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "get_gamer_info",
     wallet: wallet,
     day: generateDate(),
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setGamer(data);
    //  console.log("Fetched:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 //GET EOA ADDRESS
 useEffect(() => {
  if (!isConnected || allAccounts.length === 0) {
   setAddress(null);
  } else {
   const obj = allAccounts.find((a) => a.type === "eoa");
   setAddress(obj?.address || null);
  }
 }, [isConnected, allAccounts]);

 useEffect(() => {
  if (!isConnected) {
   setGamer({});
  } else {
   getGamer(address);
  }
 }, [isConnected, address]);

 useEffect(() => {
  const fetchTokenPrice = async () => {
   if (!settings.token || !settings.token_decimal) return;

   try {
    // Initialize provider
    const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org");

    // Initialize router contract
    const routerContract = new Contract(PANCAKE_ROUTER_ADDRESS, PANCAKE_ROUTER_ABI, provider);

    // Define path for price calculation
    const path = [settings.token, WBNB_ADDRESS, USDT_ADDRESS];
    const amountIn = parseUnits("1", +settings.token_decimal); // 1 unit of the token

    // Get amounts out
    const amountsOut = await routerContract.getAmountsOut(amountIn, path);
    //  console.log("AMOUNT", amountsOut);
    const tokenPriceInUsdt = formatUnits(amountsOut[2], 18); // USDT has 18 decimals

    //  console.log("USDT PRICE", parseFloat(tokenPriceInUsdt).toFixed(6));
    setTokenPrice(parseFloat(tokenPriceInUsdt).toFixed(3));
   } catch (error) {
    console.error("Error fetching token price:", error);
   }
  };

  fetchTokenPrice();
 }, [settings]);

 const handlePosterClick = async (videoUrl, id, owner) => {
  // console.log("CLICKED VIDEO", videoUrl);
  if (id === "0") {
   return showAlert({
    title: "Error!",
    text: "There are no videos in this category yet.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  setIntro((prevIntro) => false);

  setActiveScene("video");
  setVideoId(`${id}`);
  setVideoOwner(owner);
  setCurrentVideo(
   typeof videoUrl === "string" && videoUrl.startsWith("http") ? videoUrl : domain + videoUrl
  );
 };

 const togglePlay = (videoId) => {
  const video = document.getElementById(videoId);
  if (video) {
   if (isPlaying) {
    video.pause();
   } else {
    video.play();
   }
   setIsPlaying(!isPlaying);
  }
 };

 useEffect(() => {
  if (!token || !address) return;
  const getTokenBalance = async () => {
   try {
    let abi = ["function balanceOf(address _owner) public view returns (uint256)"];

    //  const provider = new JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545");
    const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

    let contract = new Contract(token, abi, provider);
    const result = await contract.balanceOf(address);
    setTokenBalance(formatUnits(result.toString(), 18));
    // console.log(result);
   } catch (error) {
    console.log(error);
   }
  };
  getTokenBalance();
 }, [address]);

 const getSettings = async () => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_settings" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setSettings(data[0]);

    //console.log("Fetched settings:", data[0]);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getSettings();
 }, []);

 const getUser = async (wallet) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_user_info", wallet: wallet }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setUser(data[0]);

    //  console.log("Fetched user:", data[0]);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  if (!isConnected) {
   setUser({});
  } else {
   getUser(address);
  }
 }, [isConnected, address]);

 const checkAllowanceForCredits = async (recipient, amount) => {
  if (!isConnected) {
   // Handle not connected case
   return message.error("Please connect a wallet.");
  }
  //setLoading(true);
  try {
   const allowance = await Allowance(token, controlContract, address);
   if (!Number(allowance) || parseFloat(formatUnits(allowance, 18)) < parseFloat(amount)) {
    approveForCredits(recipient, amount);
    message.info("Please Approve to spend tokens!");
   } else {
    handlePayTokenForCredits(recipient, amount);
   }
  } catch (error) {
   console.error(error);
   // setLoading(false);
   message.error("Error checking allowance");
  }
 };

 const handlePayTokenForCredits = async (recipient, amount) => {
  if (!isConnected) return;
  try {
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(controlContract, abi, signer);
   const data = await contract.buyCredits(
    // recipient,
    parseUnits(amount, 18),

    { from: address }
   );
   //
   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      const added = await addCredits(recipient, amount);
      if (added) {
       await getUser(address);
       showAlert({
        title: "Purchase Successful!",
        text: "Your v18plus top up was successful!",
        icon: "success",
        confirmButtonText: "Sounds good",
       });
       // setIsFan(true);
      } else {
       showAlert({
        title: "Error",
        text:
         "Your payment was received but there was an error adding v18plus to your account. Please contact support.",
        icon: "error",
        confirmButtonText: "OK",
       });
       // setIsFan(true);
      }
      setLoading(false);
     }
    } catch (error) {
     console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   showAlert({
    title: "ERROR!",
    text: errorMessage,
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 };

 async function approveForCredits(recipient, amount) {
  try {
   let signer;
   let contract;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   contract = new Contract(token, tokenAbi, signer);

   const data = await contract.approve(controlContract, parseUnits(amount, 18));
   // console.log("APPROVED", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      handlePayTokenForCredits(recipient, amount);
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("APPROVE ERROR", error);
   let errorMessage;
   if (error.code == 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   showAlert({
    title: "ERROR!",
    text: errorMessage,
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 }

 const addCredits = async (wallet, amount) => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_credits",
     wallet: wallet,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
    return false;
   } else {
    await getUser(address);
    return true;
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const checkAllowance = async (recipient, amount) => {
  if (!isConnected) {
   // Handle not connected case
   return message.error("Please connect a wallet.");
  }
  //setLoading(true);
  try {
   const allowance = await Allowance(token, controlContract, address);
   if (!Number(allowance) || parseFloat(formatUnits(allowance, 18)) < parseFloat(amount)) {
    approve(recipient, amount);
    message.info("Please Approve to spend tokens!");
   } else {
    handlePayToken(recipient, amount);
   }
  } catch (error) {
   console.error(error);
   // setLoading(false);
   message.error("Error checking allowance");
  }
 };

 const handlePayToken = async (recipient, amount) => {
  if (!isConnected) return;
  try {
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(controlContract, abi, signer);
   const data = await contract.payCreator(
    recipient,
    parseUnits(amount, 18),

    { from: address }
   );
   //
   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      const added = await addFan(recipient, amount);
      if (added) {
       showAlert({
        title: "Subscription Successful!",
        text: "Your subscription was successful!",
        icon: "success",
        confirmButtonText: "Sounds good",
       });
       setIsFan(true);
      } else {
       showAlert({
        title: "Error",
        text:
         "Your payment was received but there was an error adding you as a fan. Please contact support.",
        icon: "error",
        confirmButtonText: "OK",
       });
       // setIsFan(true);
      }
      setLoading(false);
     }
    } catch (error) {
     console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   showAlert({
    title: "ERROR!",
    text: errorMessage,
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 };

 async function approve(recipient, amount) {
  try {
   let signer;
   let contract;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   contract = new Contract(token, tokenAbi, signer);

   const data = await contract.approve(controlContract, parseUnits(amount, 18));
   // console.log("APPROVED", data);
   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      handlePayToken(recipient, amount);
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("APPROVE ERROR", error);
   let errorMessage;
   if (error.code == 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   showAlert({
    title: "ERROR!",
    text: errorMessage,
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 }

 const handleSubscribe = (recipient, amount) => {
  if (!amount || !recipient) return message.error("Invalid amount or wallet address!");

  checkAllowance(recipient, amount.toString());
 };

 const buyCredits = (recipient, amount) => {
  if (!amount || !recipient) return message.error("Invalid amount or wallet address!");

  checkAllowanceForCredits(recipient, amount.toString());
 };

 const addFan = async (creator, amount) => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "add_fan",
     creator: creator,
     fan: address,
     amount: amount,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    return true;
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 const payForService = async (amount) => {
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "pay_for_service",
     wallet: address,
     amount: amount,
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;
   if (!data.success) {
    console.error("Error:", data);
    return false;
   } else {
    getUser(address);
    getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: "",
     icon: "success",
     confirmButtonText: "OK",
    });
    return true;
   }
  } catch (error) {
   console.error("Error:", error.message);
   return false;
  }
 };

 const debitUser = async (amount, isService = "no") => {
  try {
   const response = await axios.post(
    apiURL,
    new URLSearchParams({
     action: "withdraw",
     wallet: address,
     amount: amount,
     isService: isService,
    }),
    {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    }
   );

   const data = response.data;
   if (!data.success) {
    console.error("Error:", data);
    return false;
   } else {
    getUser(address);
    getGamer(address);
    showAlert({
     title: "SUCCESSFUL!",
     text: "",
     icon: "success",
     confirmButtonText: "OK",
    });
    return true;
   }
  } catch (error) {
   console.error("Error:", error.message);
   return false;
  }
 };

 const withdraw = async (signature, nonce) => {
  if (!isConnected) return;
  try {
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(controlContract, abi, signer);
   const data = await contract.withdraw(
    address,
    parseUnits(user.credits, 18),
    nonce,
    signature,

    { from: address }
   );

   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      await debitUser(user.credits);
      setLoading(false);
     }
    } catch (error) {
     //  console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   //  console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   showAlert({
    title: "ERROR!",
    text: errorMessage,
    icon: "error",
    confirmButtonText: "OK",
   });
   setLoading(false);
  }
 };

 return (
  <Context.Provider
   value={{
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    settings,
    user,
    token,
    controlContract,
    handleSubscribe,
    buyCredits,
    isFan,
    setIsFan,
    getUser,
    withdraw,
    loading,
    setLoading,
    debitUser,
    domain,
    getImgKey,
    togglePlay,
    isPlaying,
    setIsPlaying,
    videoId,
    setVideoId,
    videoOwner,
    setVideoOwner,
    activeScene,
    setActiveScene,
    currentVideo,
    setCurrentVideo,
    handlePosterClick,
    intro,
    setIntro,
    tokenPrice,
    getSettings,
    nftContract,
    gamer,
    getGamer,
    generateDate,
    payForService,
    addCredits,
   }}
  >
   {children}
  </Context.Provider>
 );
};

import React, { useState, useContext, useEffect, useRef } from "react";
import { Context } from "../context/contex";

const Explainers = ({ video }) => {
 const { domain } = useContext(Context);

 return (
  <div className="container my-5">
   <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
     <video className={`d-block w-100`} src={domain + "uploads/explainer/" + video} controls />
    </div>
    <div className="col-md-2"></div>
   </div>
  </div>
 );
};
export default Explainers;

import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";

const TermsAndConditions = () => {
 return (
  <>
   <Header />
   <div className="container my-5 terms-list">
    <h1>Terms and Conditions of Use for Modeland</h1>
    <p>
     Modeland is committed to providing an innovative and secure platform for its users while
     ensuring community protection and compliance with applicable laws. Below are the terms and
     conditions you must acknowledge and accept when using the platform.
    </p>

    <h2>1. User Responsibility</h2>
    <ul>
     <li>
      <strong>Exclusive Responsibility:</strong> Users are solely responsible for the content they
      create, upload, or share on the platform. Modeland assumes no liability for any illegal,
      offensive, or non-consensual content uploaded by users.
     </li>
     <li>
      <strong>Compliance with Laws:</strong> Users must ensure their content complies with the laws
      of their country and international regulations, including data protection and intellectual
      property rights.
     </li>
     <li>
      <strong>Protection of Third-Party Rights:</strong> Strictly prohibited is the uploading of
      content that infringes on third-party rights, including copyrights, image rights, and privacy.
     </li>
     <li>
      <strong>Prohibition of Illegal Content:</strong> Any content involving minors, non-consensual
      acts, or activities promoting violence, hatred, or discrimination is strictly forbidden.
     </li>
    </ul>

    <h2>2. Reporting and Content Management</h2>
    <ul>
     <li>
      <strong>Reporting Mechanism:</strong> Modeland provides an easy-to-use system for reporting
      content. Users can flag any content they find offensive, illegal, or in violation of the terms
      of use.
     </li>
     <li>
      <strong>Handling Reports:</strong> All reports are reviewed by Modeland moderators in
      conjunction with the platform’s AI CheckHub system. Actions to remove content will be taken
      within a reasonable timeframe.
     </li>
     <li>
      <strong>Content Removal:</strong> If content is found to violate platform rules or laws,
      Modeland reserves the right to remove it immediately without prior notice to the creator.
     </li>
    </ul>

    <h2>3. Anonymity and Account Management</h2>
    <ul>
     <li>
      <strong>User Anonymity:</strong> Modeland offers an anonymous environment by linking the
      user's account solely to their wallet. This ensures privacy while enabling participation in
      financial transactions.
     </li>
     <li>
      <strong>Anonymity Restrictions:</strong> If repeated violations of the terms or reports
      against content are identified, Modeland reserves the right to impose penalties such as
      suspending or blocking the account. In such cases, users will lose access to their content
      without the possibility of recovery.
     </li>
    </ul>

    <h2>4. Modeland Obligations and Rights</h2>
    <ul>
     <li>
      <strong>Regulatory Compliance:</strong> Modeland reserves the right to adjust its policies to
      comply with applicable laws.
     </li>
     <li>
      <strong>User Notification:</strong> Users will be informed of any changes to the terms of use
      via a relevant announcement on the platform. Continued use of Modeland implies acceptance of
      the revised terms.
     </li>
     <li>
      <strong>Content Security:</strong> Modeland takes measures to protect hosted content. However,
      it is not responsible for loss or unauthorized access to content due to security breaches.
     </li>
    </ul>

    <h2>5. Users with KYC (Know Your Customer)</h2>
    <ul>
     <li>
      <strong>Verified Users:</strong> Users who complete the KYC process enjoy additional
      privileges, such as increased transaction security and higher withdrawal limits.
     </li>
     <li>
      <strong>Non-KYC Users:</strong> Users who choose to remain fully anonymous may have limited
      access to certain features, as per platform regulations.
     </li>
    </ul>

    <h2>6. Final Terms</h2>
    <p>
     Using Modeland implies acceptance of all the above terms and conditions. Any violation of the
     terms may result in the suspension or deletion of the user account.
    </p>
    <p>
     For questions or issues regarding the terms of use, please contact the Modeland support team
     through the contact form on our website.
    </p>
    <p>
     By registering on the platform, you acknowledge that Modeland is a decentralized platform
     providing creative freedom while requiring responsibility and adherence to community policies.
    </p>
   </div>
   <Footer />
  </>
 );
};

export default TermsAndConditions;

import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, text, icon, confirmButtonText }) => {
 return MySwal.fire({
  title,
  text,
  icon,
  confirmButtonText,
 });
};

export function getPercentage(number, percent) {
 return (number * percent) / 100;
}

export const formatNumber = (number) => {
 return Number(number).toLocaleString("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
 });
};

export const formatLocalTime = (timestamp) => {
 const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
 const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  // timeZoneName: "short",
 };
 return date.toLocaleString(undefined, options);
};

export const shortenAddress = (address) => {
 if (!address) return "";
 return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export const getQueryParam = (param) => {
 const urlParams = new URLSearchParams(window.location.search);
 return urlParams.get(param);
};

export const searchArray = (likedByUsers, wallet) => {
 if (!likedByUsers) return false;
 const user = likedByUsers.find((user) => user.wallet === wallet && user.is_active === 1);
 return !!user;
};

export const searchArrayBool = (likedByUsers, wallet) => {
 if (!likedByUsers) return false;
 const user = likedByUsers.find((user) => user.wallet === wallet);
 return !!user;
};

export const getPaginatedData = (data, currentPage, itemsPerPage) => {
 const start = currentPage * itemsPerPage;
 const end = start + itemsPerPage;
 return data.slice(start, end);
};

export const generateNonce = () => {
 return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const copy = (text) => {
 if (text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
   navigator.clipboard.writeText(text).then(
    () => {
     //  console.log("Token copied to clipboard!");
     alert(`Copied to clipboard`);
    },
    (err) => {
     alert(`Could not copy text: ${err}`);
    }
   );
  } else {
   // Fallback method for older browsers
   const textArea = document.createElement("textarea");
   textArea.value = text;
   document.body.appendChild(textArea);
   textArea.focus();
   textArea.select();
   try {
    document.execCommand("copy");
    alert(`Copied to clipboard`);
   } catch (err) {
    alert(`Could not copy text: ${err}`);
   }
   document.body.removeChild(textArea);
  }
 }
};

export const removeDecimals = (num) => {
 return Number(num).toLocaleString("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
 });
};

export const handleStopAndReset = (exvRef) => {
 if (exvRef.current) {
  exvRef.current.pause(); // Pause the video
  exvRef.current.currentTime = 0; // Reset time to 0
 }
};

export const playExplainer = (exvRef) => {
 if (exvRef.current) {
  setTimeout(() => {
   exvRef.current.play();
  }, 500);
 }
};

export const calculateTokenDeduction = (usdPrice, usdFee, durationInSeconds = 0) => {
 let costInUSD = usdFee;

 // Handle video-specific cost calculation
 if (durationInSeconds > 0) {
  const secondsPerUnit = 15; // Each unit is 15 seconds
  const costPerUnit = usdFee; // Use usdFee as the per-unit cost
  costInUSD = Math.ceil(durationInSeconds / secondsPerUnit) * costPerUnit;
 }

 // Calculate the number of tokens to deduct
 let tokensToDeduct = costInUSD / usdPrice;

 // Round to 2 decimal places
 tokensToDeduct = Math.round(tokensToDeduct * 100) / 100;

 return tokensToDeduct;
};

export const scrollTo = (id) => {
 const element = document.getElementById(id);
 if (element) {
  element.scrollIntoView({ behavior: "smooth", block: "start" });
 } else {
  console.error("Element with ID 'justify-tab-example' not found.");
 }
};

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Button from "react-bootstrap/Button";
import Pagination2 from "./Pagination2";
import {
 formatNumber,
 formatLocalTime,
 shortenAddress,
 showAlert,
 searchArray,
 searchArrayBool,
 generateNonce,
} from "../utils/helpers";
import Picker from "emoji-picker-react";

const Post = ({
 post,
 handleDelete,
 handleLike,
 handleCommentChange,
 comments,
 deleteComment,
 type,
 pages,
 toggleEmojiPicker,
 handleCommentSubmit,
 emojiPickers,
 onEmojiClick,
 creditGift,
 giftChange,
 sendGift,
 handleSubscribe,
 getAllPosts,
 payCreator,
}) => {
 const {
  address,

  isConnected,
  tokenBalance,
  apiURL,
  user,
  setLoading,
  settings,
  withdraw,
  domain,
  tokenPrice,
  getUser,
  gamer,
  getGamer,
 } = useContext(Context);
 return (
  <>
   {post.length > 0 &&
    post.map((v, i) => (
     <div key={type + i} className="timeline-post my-3">
      <div className="post-header">
       <div className="user-info">
        <a href={`profile?user=${!v.username ? v.poster : v.username}`} target="_blank">
         {" "}
         <img src={domain + v.profile_photo} alt="User Avatar" />
        </a>
        <div>
         <p className="user-name">
          <a
           href={`profile?user=${!v.username ? v.poster : v.username}`}
           style={{ color: "#111" }}
           target="_blank"
          >
           {!v.username ? shortenAddress(v.poster) : v.username}
          </a>{" "}
          {v.verified === "yes" && (
           <i className="fa fa-check-circle text-primary" aria-hidden="true" />
          )}
         </p>
         <p className="post-date">
          {formatLocalTime(v.post_date)}{" "}
          {!v.unlock ? (
           <i className="fa fa-lock text-danger" aria-hidden="true" />
          ) : (
           <i className="fa fa-globe text-success" aria-hidden="true" />
          )}
         </p>
        </div>
       </div>
       {address === v.poster && (
        <div className="post-options">
         {/*v.is_photo === "no" && (
          <i
           style={{ cursor: "pointer" }}
           className={`fa fa-edit text-primary mr-2`}
           onClick={() => window.open(`/edit-post?vid=${v.id}`, "_blank", "noopener,noreferrer")}
          />
         )*/}
         <i
          style={{ cursor: "pointer" }}
          className={`fa fa-trash mr-2 text-danger`}
          onClick={() => handleDelete(v.id)}
         />
        </div>
       )}
      </div>
      <div className="post-content">
       <h5>{v.title || ""}</h5>
       <div className="carousel-item-wrapper">
        {v.is_photo === "no" ? (
         <video
          className={`post-media d-block w-100 carousel-media ${!v.unlock ? "blurred" : ""}`}
          src={domain + v.video}
          controls={v.unlock}
          muted
         />
        ) : (
         <div className="text-center">
          <Swiper
           navigation={false}
           modules={[Pagination, Autoplay, Navigation]}
           autoplay
           pagination={{ clickable: true }}
           loop={true}
           breakpoints={{
            640: {
             slidesPerView: 1,
             spaceBetween: 20,
            },
            768: {
             slidesPerView: 1,
             spaceBetween: 30,
            },
            1024: {
             slidesPerView: 1,
             spaceBetween: 30,
            },
           }}
           className="my-5a"
          >
           {v.photos.split(",").map((photo, index) => (
            <SwiperSlide key={`p-photo${index + type}`}>
             <Zoom
              overlayBgColorEnd="rgba(0, 0, 0, 0.85)" // Dark overlay when zoomed
              overlayBgColorStart="rgba(0, 0, 0, 0)"
             >
              <div className="d-flex justify-content-center align-items-center">
               <img
                src={`${domain + photo.trim()}`} // Ensure the photo URL is clean
                alt={v.title}
                className={`d-block ${!v.unlock ? "blurred" : ""}`}
                style={{
                 cursor: "pointer",
                 height: "auto",
                 width: "50%",
                }}
               />
              </div>
             </Zoom>
            </SwiperSlide>
           ))}
          </Swiper>
         </div>
        )}
        {!v.unlock && (
         <>
          {v.payment_type === "subscribers" && (
           <div className="overlay">
            <i className="fa fa-lock fa-3x text-white"></i>
            <Button
             variant="primary"
             className="mt-3"
             data-toggle="modal"
             data-target={`#subModal-${i + type}`}
            >
             Subscribe to unlock
            </Button>
           </div>
          )}
          {v.payment_type === "pay" && (
           <div className="overlay">
            <i className="fa fa-lock fa-3x text-white"></i>
            <Button
             variant="danger"
             className="mt-3"
             onClick={() => payCreator(v.id, v.price, v.poster)}
            >
             Pay {formatNumber(v.price)} v18plus to unlock
            </Button>
           </div>
          )}
         </>
        )}
       </div>
      </div>
      <div className="post-actions">
       <div className="action-buttons">
        <button className="text-primary" data-toggle="modal" data-target={`#likeModal-${i + type}`}>
         {Math.round(formatNumber(v.like_count))}
        </button>
        <button style={{ color: "red" }} onClick={() => handleLike(v.id)}>
         <i
          className={`fa ${searchArrayBool(v.liked_by_users, address) ? "fa-heart" : "fa-heart-o"}`}
         />{" "}
        </button>
        <span> &nbsp;</span>
        <button
         className="text-secondary"
         data-toggle="modal"
         data-target={`#commentModal-${i + type}`}
         back-drop="static"
        >
         {Math.round(formatNumber(v.comments.length))}{" "}
         <i className="fa fa-comments-o" aria-hidden="true"></i>
        </button>
        <span> &nbsp;</span>
        <button
         className="text-secondary"
         data-toggle="modal"
         data-target={`#giftModal-${i + type}`}
        >
         <span>
          {" "}
          <img src="assets/images/svg/dollar.svg" height={20} /> Send tip
         </span>
        </button>
       </div>
       {v.is_photo === "no" && (
        <div className="comment-count">{Math.round(formatNumber(v.views))} Views</div>
       )}
      </div>
      {/*LIKES modal*/}
      <div
       className="modal fade"
       id={`likeModal-${i + type}`}
       tabIndex={-1}
       role="dialog"
       aria-labelledby={`likeModalLabel-${i}`}
       aria-hidden="true"
      >
       <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
         <div className="modal-header">
          <h3 className="modal-title" id={`likeModalLabel-${i}`}>
           Post Likes
          </h3>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">×</span>
          </button>
         </div>
         <div className="modal-body">
          {v.liked_by_users.length > 0 &&
           v.liked_by_users.map((u, index) => (
            <div key={index} className="d-flex align-items-center p-2">
             <img
              src={domain + u.profile_photo}
              style={{
               height: "30px",
               width: "30px",
               borderRadius: "50%",
              }}
             />
             <span className="ml-1">
              {" "}
              <a
               href={`profile?user=${!u.username ? u.wallet : u.username}`}
               style={{ color: "#fff" }}
               target="_blank"
              >
               {!u.username ? shortenAddress(u.wallet) : u.username}
              </a>{" "}
             </span>
            </div>
           ))}
         </div>

         <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
           Close
          </button>
         </div>
        </div>
       </div>
      </div>
      {/*COMMENTS modal*/}
      <div
       className="modal fade"
       id={`commentModal-${i + type}`}
       tabIndex={-1}
       role="dialog"
       aria-labelledby={`cModalLabel-${i + type}`}
       aria-hidden="true"
       data-backdrop="static"
      >
       <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
         <div className="modal-header">
          <h3 className="modal-title text-white" id={`cModalLabel-${i + type}`}>
           Comments
          </h3>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">×</span>
          </button>
         </div>
         <div className="modal-body" style={{ maxHeight: "300px", overflow: "auto" }}>
          {v.comments.length > 0 &&
           v.comments.map((u, index) => (
            <div key={index} className="comment-container">
             <div className="comment-header">
              <a
               href={`profile?user=${!u.username ? u.wallet : u.username}`}
               style={{ color: "#fff" }}
               target="_blank"
              >
               <img src={domain + u.profile_photo} alt="User Avatar" className="avatar" />
              </a>
              <div className="user-info">
               <a
                href={`profile?user=${!u.username ? u.wallet : u.username}`}
                style={{ color: "#fff" }}
                target="_blank"
               >
                <span className="username">
                 {" "}
                 {!u.username ? shortenAddress(u.wallet) : u.username}
                </span>
               </a>
               {u.verified === "yes" && (
                <i className="fa fa-check-circle text-primary" aria-hidden="true" />
               )}

               <span className="comment-time">{formatLocalTime(u.add_date)}</span>
              </div>
              {isConnected && u.wallet === address && (
               <button onClick={() => deleteComment(u.id)} className="delete-btn">
                <i className="fa fa-trash text-danger" aria-hidden="true" />
               </button>
              )}
             </div>
             <div className="comment-text">{u.comment}</div>
            </div>
           ))}
          {v.comments.length === 0 && (
           <div className="text-center text-white">Be the first to leave a comment.</div>
          )}
         </div>

         <div className="modal-footer">
          {/* Comment Input */}
          <div style={{ position: "relative", width: "100%" }}>
           <div
            style={{
             display: "flex",
             alignItems: "center",
             border: "1px solid #ddd",
             borderRadius: "25px",
             padding: "5px 10px",
            }}
           >
            <input
             type="text"
             value={comments[v.id] || ""}
             onChange={(e) => handleCommentChange(v.id, e.target.value)}
             placeholder={`Add a comment`}
             style={{
              flexGrow: 1,
              border: "none",
              outline: "none",
              fontSize: "16px",
              padding: "5px",
             }}
            />
            <button
             onClick={() => toggleEmojiPicker(v.id)}
             style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "20px",
              marginRight: "10px",
             }}
            >
             😊
            </button>
            <button
             onClick={() => handleCommentSubmit(v.id)}
             style={{
              background: "#1DA1F2",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              cursor: "pointer",
             }}
            >
             ➤
            </button>
           </div>

           {/* Emoji Picker */}
           {emojiPickers[v.id] && (
            <div style={{ position: "absolute", top: "90%", right: "10px" }}>
             <Picker
              onEmojiClick={(event, emojiObject) => onEmojiClick(v.id, event, emojiObject)}
             />
            </div>
           )}
          </div>
         </div>
        </div>
       </div>
      </div>

      {/*GIFT modal*/}
      <div
       className="modal fade"
       id={`giftModal-${i + type}`}
       tabIndex={-1}
       role="dialog"
       aria-labelledby={`gModalLabel-${i}`}
       aria-hidden="true"
       data-backdrop="static"
      >
       <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
         <div className="modal-header">
          <h3 className="modal-title text-white" id={`gModalLabel-${i}`}>
           Send A Tip
          </h3>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">×</span>
          </button>
         </div>
         <div className="modal-body text-white">
          Send tip from your v18plus or airdrop balance (if you have enough). Enter the amount you
          want to gift to {!v.username ? shortenAddress(v.wallet) : v.username} and hit the PROCEED
          button.{" "}
          <b>
           We will charge from your airdrop token balance if you have enough to cover the cost.
          </b>
          <div className="my-4">
           <input
            placeholder="Amount"
            className="form-control my-5"
            style={{
             backgroundColor: "#000",
             color: "#fff",
             width: "100%",
             height: "60px",
             border: "#aaa 1px solid",
            }}
            type="number"
            value={creditGift[v.id] || ""}
            onInput={(e) => giftChange(v.id, e.target.value)}
           />
           {creditGift[v.id] && (
            <>
             {formatNumber(creditGift[v.id])} v18plus = $
             {formatNumber(Math.round(tokenPrice * creditGift[v.id]))}
            </>
           )}
          </div>
         </div>

         <div className="modal-footer">
          {creditGift[v.id] && (
           <>
            <button
             type="button"
             className="btn btn-danger"
             onClick={() => sendGift(v.id, v.wallet)}
            >
             Proceed
            </button>
           </>
          )}
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
           Close
          </button>
         </div>
        </div>
       </div>
      </div>

      {/*SUBSCRIBE MODAL*/}
      <div
       className="modal fade text-white"
       id={`subModal-${i + type}`}
       tabIndex={-1}
       role="dialog"
       aria-labelledby="myModalLabel"
       aria-hidden="true"
      >
       <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
         <div className="modal-header">
          <h3 className="modal-title text-white" id="myModalLabel">
           Subscribe to {!v.username ? shortenAddress(v.wallet) : v.username}
           {"'s "} Secret Corner
          </h3>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">×</span>
          </button>
         </div>
         <div className="modal-body">
          {!v.username ? shortenAddress(v.wallet) : v.username} charges{" "}
          <b>
           {formatNumber(v.content_price)} v18plus ($
           {formatNumber(Math.round(tokenPrice * v.content_price))}){" "}
          </b>{" "}
          monthly. In turn, you will have unlimited access to all of their private content and also
          be able to chat with them while your subscription is still active.{" "}
          <b>
           We will charge from your airdrop token balance if you have enough to cover the cost.
          </b>
         </div>
         {!v.unlocked && (
          <div className="modal-footer">
           <button type="button" className="btn btn-secondary" data-dismiss="modal">
            No, thanks
           </button>
           {isConnected ? (
            <button
             type="button"
             className="btn btn-danger"
             onClick={() => handleSubscribe(v.poster, v.content_price)}
            >
             Yes, let's do it!
            </button>
           ) : (
            <button disabled={true} type="button" className="btn btn-custom">
             Connect your wallet first.
            </button>
           )}
          </div>
         )}
        </div>
       </div>
      </div>
     </div>
    ))}
   {post.length === 0 && (
    <div className="text-center">
     <img src="assets/images/fly.gif" height={100} />
     <p>No posts here yet...</p>
    </div>
   )}
   {post.length > 0 && (
    <Pagination2
     currentPage={pages.current_page}
     totalPages={pages.total_pages}
     onPageChange={(page) => getAllPosts(type, page)}
    />
   )}
  </>
 );
};

export default Post;

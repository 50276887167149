import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import { showAlert } from "../../utils/helpers";
import "../styles/post-video.css";

function BecomeCreator() {
 const {
  address,

  isConnected,

  apiURL,
  user,
  getUser,
  domain,
 } = useContext(Context);

 const [creatorType, setCreatorType] = useState("real");

 const [coverPhoto, setCoverPhoto] = useState(null);
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [instagram, setInstagram] = useState("");
 const [x, setX] = useState("");
 const [tiktok, setTiktok] = useState("");

 const [bio, setBio] = useState("");
 const [contentPrice, setContentPrice] = useState("");
 const [dob, setDob] = useState("");
 const [email, setEmail] = useState("");
 const [gender, setGender] = useState("");
 const [profilePhoto, setProfilePhoto] = useState(null);
 const [profilePhotoPrev, setProfilePhotoPrev] = useState(null);

 const [username, setUsername] = useState("");
 const [country, setCountry] = useState("");
 const [firstName, setFirstName] = useState("");
 const [surname, setSurname] = useState("");

 const [uploadProgress, setUploadProgress] = useState(0);
 const [uploadProgressKYC, setUploadProgressKYC] = useState(0);
 const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

 useEffect(() => {
  if (Object.keys(user).length > 0) {
   setBio(user.bio);
   setContentPrice(user.content_price);
   setEmail(user.email);
   setGender(user.gender);
   setUsername(user.username);
   setSurname(user.surname);
   setFirstName(user.first_name);
   setDob(user.dob);
   setCountry(user.country);
   setContentPrice(user.content_price);
   setInstagram(user.instagram != "null" ? user.instagram : "");
   setTiktok(user.tiktok != "null" ? user.tiktok : "");
   setX(user.x != "null" ? user.x : "");
  }
 }, [user, address]);

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleProfilePhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setProfilePhoto(file);
   setProfilePhotoPrev(URL.createObjectURL(file));
  }
 };

 const handleUpdate = async (e) => {
  e.preventDefault();

  // Check if wallet is connected
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  // Validate date of birth format
  if (!dobRegex.test(dob)) {
   return showAlert({
    title: "Error!",
    text: "Please enter the date of birth in the format DD-MM-YYYY.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  // Construct form data
  const formData = new FormData();
  formData.append("wallet", address);
  formData.append("bio", bio);
  formData.append("content_price", contentPrice);
  formData.append("country", country);
  formData.append("first_name", firstName);
  formData.append("surname", surname);
  formData.append("dob", dob);
  formData.append("email", email);
  formData.append("current_email", user.email);
  formData.append("gender", gender);
  formData.append("instagram", instagram);
  formData.append("tiktok", tiktok);
  formData.append("x", x);

  if (user.username == null) {
   formData.append("username", username);
  }
  if (coverPhoto != null) {
   formData.append("coverPhoto", coverPhoto);
  }
  if (profilePhoto != null) {
   formData.append("profilePhoto", profilePhoto);
  }
  formData.append("action", "become-creator");
  formData.append("is_creator", "yes");
  formData.append("creator_type", creatorType);

  try {
   // Debug FormData contents
   console.log("FormData:", [...formData]);

   // Make the API call
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });

   // Handle response
   if (response.data.success) {
    getUser(address);
    showAlert({
     title: "Successful!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Sounds good",
    });
   } else {
    console.log("Error Response:", response);
    showAlert({
     title: "Error!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "OK",
    });
   }
  } catch (error) {
   console.error("Request Error:", error);
   showAlert({
    title: "Error!",
    text: "An error occurred while updating the details. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
   });
  }
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="carousel-container">
       <img
        className="d-block w-100 carousel-image"
        src={domain + "assets/images/slides/creators.jpg"}
       />
      </div>

      <div className="text-center my-5 container">
       <h1>WHAT TYPE OF CREATOR ARE YOU?</h1>
       <div className="row mt-4 px-5 mx-5" style={{ backgroundColor: "#eee" }}>
        <div className="col-md-6">
         <label htmlFor="real" className="rcontainer">
          Real creator
          <input
           id="real"
           value="real"
           type="radio"
           name="creator"
           onChange={(e) => setCreatorType(e.target.value)}
           checked={creatorType === "real"}
           required
          />
          <span className="rcheckmark"></span>
         </label>
        </div>

        <div className="col-md-6">
         <label htmlFor="ai" className="rcontainer">
          Ai creator
          <input
           id="ai"
           type="radio"
           name="creator"
           value="ai"
           onChange={(e) => setCreatorType(e.target.value)}
           checked={creatorType === "ai"}
          />
          <span className="rcheckmark"></span>
         </label>
        </div>
       </div>
      </div>
      {creatorType === "real" ? (
       <>
        <div className="container text-center become-creator-text">
         <div className="alert-info p-2 my-4 rounded">
          <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
          <b>Real creators must pass KYC verification to be able to post real contents.</b>
         </div>
         <p>
          It’s time to make your mark. Step into the spotlight and become a real creator on our
          platform.
         </p>

         <p>
          Whether you're starting your journey or looking to elevate your content game, this is your
          opportunity to shine. Build your fan base, earn rewards, and bring your creative vision to
          life.
         </p>

         <p>
          With powerful tools, secure blockchain technology, and the freedom to express yourself,
          the possibilities are endless.
         </p>
        </div>

        <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
         <div className="col-md-4">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
         <div className="col-md-4 px-5">
          <div className="text-center">
           <h1 style={{ fontWeight: "700", fontSize: "70px", fontFamily: "Jura" }}>
            BECOME <br /> A REAL <br />
            CREATOR
           </h1>
          </div>
         </div>
         <div className="col-md-4">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
        </div>
        <div className="new-nft-grid-container">
         {/* Monetization Options */}
         <div className="new-nft-box new-nft-box-top-left">
          <h3>MONETIZATION OPTIONS</h3>
          <p>
           <strong>Subscription-Based Income:</strong> Set your own subscription rates and earn
           monthly.
          </p>
          <p>
           <strong>Pay-Per-View Content:</strong> Offer exclusive content for one-time payments.
          </p>
          <p>
           <strong>Tips and Donations:</strong> Fans can show their love by tipping directly.
          </p>
          <p>
           <strong>AI-Powered Earning:</strong> Integrate AI models to diversify your revenue
           streams.
          </p>
         </div>

         {/* Platform Tools */}
         <div className="new-nft-box new-nft-box-top-right">
          <h3>PLATFORM TOOLS</h3>
          <p>
           <strong>Analytics Dashboard:</strong> Track your growth, earnings, and audience behavior.
          </p>
          <p>
           <strong>Custom Branding:</strong> Tailor your profile to reflect your unique style.
          </p>
         </div>

         {/* Collaborations */}
         <div className="new-nft-box new-nft-box-center">
          <h3>COLLABORATIONS</h3>
          <p>
           <strong>Collaborative Features:</strong> Partner with other creators for unique projects.
          </p>
          <p>
           <strong>Partnerships:</strong> Partner with your most loyal fans, and get financial
           support for your page.
          </p>
         </div>

         {/* Security & Privacy */}
         <div className="new-nft-box new-nft-box-middle-right">
          <h3>SECURITY & PRIVACY</h3>
          <p>
           <strong>Blockchain-Backed Payments:</strong> Enjoy secure, transparent transactions.
          </p>
          <p>
           <strong>Identity Protection:</strong> Options for anonymity for those who prefer privacy.
          </p>
          <p>
           <strong>Anti-Piracy Measures:</strong> Advanced tools to protect your content from theft.
          </p>
         </div>

         {/* Support & Resources */}
         <div className="new-nft-box new-nft-box-bottom-left">
          <h3>SUPPORT & RESOURCES</h3>
          <p>
           <strong>Dedicated Creator Support:</strong> Our team is here to assist with any issues or
           questions.
          </p>
          <p>
           <strong>Educational Resources:</strong> Tutorials and guides to help you maximize your
           earnings.
          </p>
          <p>
           <strong>Community Forums:</strong> Connect with other creators to share tips and ideas.
          </p>
         </div>

         {/* Special Rewards */}
         <div className="new-nft-box new-nft-box-bottom-right">
          <h3>SPECIAL REWARDS</h3>
          <p>
           <strong>Exclusive Bonuses:</strong> Early creators receive platform-specific perks.
          </p>
          <p>
           <strong>Token Incentives:</strong> Earn Coin18+ tokens through milestones and
           achievements.
          </p>
         </div>

         {/* Join Us */}
         <div className="new-nft-box new-nft-box-footer">
          <h3>JOIN US</h3>
         </div>
        </div>
       </>
      ) : (
       <>
        <div className="container text-center become-creator-text">
         <p>
          Whether you're a seasoned creator ready to explore a second identity or someone who's
          never stepped into the spotlight, becoming an AI creator gives you the ultimate control
          and privacy. With cutting-edge AI technology, you can reach fans worldwide without ever
          showing your face.
         </p>
        </div>

        <div>
         <img className="d-block w-100" src={domain + "assets/images/ai-create.png"} />
        </div>

        <section className="new-nft-how-it-works">
         <h2 className="new-nft-title">HOW IT WORKS</h2>
         <div className="new-nft-step-container">
          {/* Step 1 */}
          <div className="new-nft-step">
           <div className="new-nft-step-number">01</div>
           <div className="new-nft-step-content">
            <h3>Design Your AI Model:</h3>
            <p>
             Start with a simple prompt and refine its features with advanced customization tools.
            </p>
           </div>
          </div>

          {/* Step 2 */}
          <div className="new-nft-step">
           <div className="new-nft-step-number">02</div>
           <div className="new-nft-step-content">
            <h3>Launch & Earn:</h3>
            <p>Make your AI model live, set subscription plans, and start earning.</p>
           </div>
          </div>

          {/* Step 3 */}
          <div className="new-nft-step">
           <div className="new-nft-step-number">03</div>
           <div className="new-nft-step-content">
            <h3>Evolve with Insights:</h3>
            <p>
             Track performance with our analytics dashboard and improve your model to attract even
             more fans.
            </p>
           </div>
          </div>
         </div>
        </section>

        <div className="row my-5" style={{ display: "flex", alignItems: "center" }}>
         <div className="col-md-4">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
         <div className="col-md-4 px-5">
          <div className="text-center">
           <h1 style={{ fontWeight: "700", fontSize: "70px", fontFamily: "Jura" }}>
            BECOME <br /> AN AI <br />
            CREATOR
           </h1>
          </div>
         </div>
         <div className="col-md-4">
          <div style={{ border: "2px #000 solid" }}></div>
         </div>
        </div>
        <div className="new-nft-grid-container">
         {/* Monetization Options */}
         <div className="new-nft-box new-nft-box-top-left">
          <h3>MONETIZATION OPTIONS</h3>
          <p>
           <strong>Subscription-Based Income:</strong> Set your own subscription rates and earn
           monthly.
          </p>
          <p>
           <strong>Pay-Per-View Content:</strong> Offer exclusive content for one-time payments.
          </p>
          <p>
           <strong>Tips and Donations:</strong> Fans can show their love by tipping directly.
          </p>
          <p>
           <strong>AI-Powered Earning:</strong> Integrate AI models to diversify your revenue
           streams.
          </p>
         </div>

         <div className="new-nft-box new-nft-box-top-right">
          <h3>PLATFORM TOOLS</h3>
          <p>
           <strong>Analytics Dashboard:</strong> Track your growth, earnings, and audience behavior.
          </p>
          <p>
           <strong>Custom Branding:</strong> Tailor your profile to reflect your unique style.
          </p>
         </div>

         <div className="new-nft-box new-nft-box-top-right">
          <h3>SEEMLESS CONTENT INTEGRATION</h3>
          <p>
           <strong>Combine AI and Real Content:</strong> Blend your human presence with your AI
           persona for a multi-dimensional experience.
           <b>Collaboration Features:</b>
           Your AI model can collaborate with other creators’ AI personas.
          </p>
         </div>

         <div className="new-nft-box new-nft-box-top-right">
          <h3>EFFORTLESS AI MODEL CREATION</h3>
          <p>
           <strong>Simple Prompt-Based Design:</strong> Create your AI model by just describing your
           vision.
           <b>Advanced Customization Filters:</b>
           Fine-tune every detail, from voice to personality traits.
          </p>
          <p>
           <strong>No Coding Needed:</strong> The platform does the heavy lifting while you unleash
           your creativity.
          </p>
          <p>
           <strong>Multiple Personas:</strong> Build and monetize as many AI models as you want
          </p>
         </div>

         {/* Collaborations */}
         <div className="new-nft-box new-nft-box-center">
          <h3>COLLABORATIONS</h3>
          <p>
           <strong>Collaborative Features:</strong> Partner with other creators for unique projects.
          </p>
          <p>
           <strong>Partnerships:</strong> Partner with your most loyal fans, and get financial
           support for your page.
          </p>
         </div>

         {/* Security & Privacy */}
         <div className="new-nft-box new-nft-box-middle-right">
          <h3>SECURITY & PRIVACY</h3>
          <p>
           <strong>Blockchain-Backed Payments:</strong> Enjoy secure, transparent transactions.
          </p>
          <p>
           <strong>Identity Protection:</strong> Options for anonymity for those who prefer privacy.
          </p>
          <p>
           <strong>Anti-Piracy Measures:</strong> Advanced tools to protect your content from theft.
          </p>
         </div>

         {/* Support & Resources */}
         <div className="new-nft-box new-nft-box-bottom-left">
          <h3>SUPPORT & RESOURCES</h3>
          <p>
           <strong>Dedicated Creator Support:</strong> Our team is here to assist with any issues or
           questions.
          </p>
          <p>
           <strong>Educational Resources:</strong> Tutorials and guides to help you maximize your
           earnings.
          </p>
          <p>
           <strong>Community Forums:</strong> Connect with other creators to share tips and ideas.
          </p>
         </div>

         {/* Special Rewards */}
         <div className="new-nft-box new-nft-box-bottom-right">
          <h3>UNIQUE REWARDS & INCENTIVES</h3>
          <p>
           <strong>Early Adopter Bonuses:</strong> Receive exclusive perks as one of the first AI
           creators on the platform.
          </p>
          <p>
           <strong>Tokenized Income:</strong> Earn Coin18+ tokens as a reward for your AI model's
           success.
          </p>
          <p>
           <strong>Platform Contests:</strong> Participate in creator competitions to boost
           visibility and earnings.
          </p>
         </div>

         {/* Join Us */}
         <div className="new-nft-box new-nft-box-footer">
          <h3>JOIN US</h3>
         </div>
        </div>
       </>
      )}
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect your
           wallet to be able to become a creator.
          </div>
         ) : (
          <>
           <form onSubmit={handleUpdate}>
            {user.is_creator !== "yes" ? (
             <div className="row">
              <div className="col-md-4">
               <div className="form-group">
                <label>Surname (hidden from public)</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="John"
                 onChange={(e) => setSurname(e.target.value)}
                 value={surname}
                 required
                />
               </div>
              </div>
              <div className="col-md-4">
               <div className="form-group">
                <label>First Name (hidden from public)</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="Jane"
                 onChange={(e) => setFirstName(e.target.value)}
                 value={firstName}
                 required
                />
               </div>
              </div>
              <div className="col-md-4">
               <div className="form-group">
                <label htmlFor="username">Display name (public)</label>
                <input
                 type="text"
                 className="form-control"
                 id="username"
                 placeholder="Pick a username"
                 onChange={(e) => setUsername(e.target.value)}
                 value={username}
                 required
                 readOnly={user.username != null}
                />
               </div>
              </div>
              <div className="col-md-4">
               <div className="form-group">
                <label>Date of Birth (DD-MM-YYYY)</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="DD-MM-YYYY"
                 onChange={(e) => setDob(e.target.value)}
                 value={dob}
                 required
                />
               </div>
              </div>
              <div className="col-md-4">
               <div className="form-group">
                <label>Country/Nationality</label>
                <input
                 type="text"
                 className="form-control"
                 placeholder="Type country name"
                 onChange={(e) => setCountry(e.target.value)}
                 value={country}
                 required
                />
               </div>
              </div>
              <div className="col-md-4">
               <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                 type="email"
                 className="form-control"
                 id="email"
                 placeholder="Enter your email"
                 onChange={(e) => setEmail(e.target.value)}
                 value={email}
                 required
                />
               </div>
              </div>

              <div className="col-md-4">
               <div className="form-group">
                <label>How many v18plus tokens to charge your fans per month?</label>
                <input
                 type="number"
                 className="form-control"
                 placeholder="Enter amount"
                 onChange={(e) => setContentPrice(e.target.value)}
                 value={contentPrice}
                 required
                />
               </div>
              </div>

              <div className="col-md-4">
               <div className="form-group">
                <label>Instagram link (optional)</label>
                <input
                 type="url"
                 className="form-control"
                 placeholder="Instagram link"
                 onChange={(e) => setInstagram(e.target.value)}
                 value={instagram}
                />
               </div>
              </div>

              <div className="col-md-4">
               <div className="form-group">
                <label>X (Twitter) link (optional)</label>
                <input
                 type="url"
                 className="form-control"
                 placeholder="x link"
                 onChange={(e) => setX(e.target.value)}
                 value={x}
                />
               </div>
              </div>

              <div className="col-md-4">
               <div className="form-group">
                <label>Tiktok link (optional)</label>
                <input
                 type="url"
                 className="form-control"
                 placeholder="tiktok link"
                 onChange={(e) => setTiktok(e.target.value)}
                 value={tiktok}
                />
               </div>
              </div>

              <div className="col-md-12">
               <label>How do you identify?</label>
               <div className="row">
                <div className="col-md-4">
                 <label htmlFor="Gmale" className="rcontainer">
                  Male
                  <input
                   id="Gmale"
                   type="radio"
                   name="gender"
                   value="male"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "male"}
                   required
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>
                <div className="col-md-4">
                 <label htmlFor="Gfemale" className="rcontainer">
                  Female
                  <input
                   id="Gfemale"
                   type="radio"
                   name="gender"
                   value="female"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "female"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>

                <div className="col-md-4">
                 <label htmlFor="Gcouple" className="rcontainer">
                  Couple
                  <input
                   id="Gcouple"
                   type="radio"
                   name="gender"
                   value="couple"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "couple"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>

                <div className="col-md-4">
                 <label htmlFor="Gtrans" className="rcontainer">
                  Trans
                  <input
                   id="Gtrans"
                   type="radio"
                   name="gender"
                   value="trans"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "trans"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>

                <div className="col-md-4">
                 <label htmlFor="Gnb" className="rcontainer">
                  Non-Binary
                  <input
                   id="Gnb"
                   type="radio"
                   name="gender"
                   value="non-binary"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "non-binary"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>

                <div className="col-md-4">
                 <label htmlFor="Gprivate" className="rcontainer">
                  Private
                  <input
                   id="Gprivate"
                   type="radio"
                   name="gender"
                   value="private"
                   onChange={(e) => setGender(e.target.value)}
                   checked={gender === "private"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>
               </div>
              </div>

              <div className="col-md-12">
               <div className="form-group">
                <label htmlFor="videoDescription">About Me</label>
                <textarea
                 className="form-control"
                 id="videoDescription"
                 style={{ height: "120px" }}
                 placeholder="Tell fans something about you."
                 value={bio}
                 required
                 onChange={(e) => setBio(e.target.value)}
                 maxLength={300}
                />
                {bio?.length} / 300.
               </div>
              </div>
              <div className="col-md-6 text-center">
               <div className="form-group">
                <label htmlFor="profilePhoto">
                 <img
                  src={profilePhotoPrev || domain + user.profile_photo}
                  height={150}
                  alt="profile placeholder"
                 />
                </label>
                <div className="custom-file">
                 <input
                  type="file"
                  className="custom-file-input"
                  id="profilePhoto"
                  accept="image/*"
                  onChange={handleProfilePhotoChange}
                 />
                 <label className="custom-file-label" htmlFor="profilePhoto">
                  Choose profile photo
                 </label>
                </div>
               </div>
              </div>

              <div className="col-md-6 text-center">
               <div className="form-group">
                <label htmlFor="coverPhoto">
                 <img
                  src={coverPhotoPreview || domain + user.cover_photo}
                  height={150}
                  alt="cover placeholder"
                 />
                </label>
                <div className="custom-file">
                 <input
                  type="file"
                  className="custom-file-input"
                  id="coverPhoto"
                  accept="image/*"
                  onChange={handleCoverPhotoChange}
                 />
                 <label className="custom-file-label" htmlFor="coverPhoto">
                  Choose cover photo
                 </label>
                </div>
               </div>
              </div>

              {uploadProgress > 0 && (
               <div className="col-md-12 my-4">
                <div className="progress">
                 <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                 >
                  {uploadProgress}%
                 </div>
                </div>
               </div>
              )}

              <div className="col-md-12 my-3 alert-info p-3">
               By pressing on the <b>SUBMIT</b> button you confirm that you have read, understood
               and agree to our{" "}
               <a href="/terms" target="_blank">
                Terms and Conditions
               </a>
               .
              </div>

              <div className="col-md-12 text-center my-2">
               <button type="submit" className="btn btn-primary btn-block">
                Submit
               </button>
              </div>
             </div>
            ) : (
             <div className="alert-danger p-3 my-3 rounded">
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Sorry, you are
              already a creator.
             </div>
            )}
           </form>
          </>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default BecomeCreator;

// src/index.js or src/main.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import TermsAndConditions from "./pages/Terms";

import About from "./pages/about";

import Contact from "./pages/contact";

import CreateNFT from "./pages/NFT/create-nft";
import ProfileNFT from "./pages/NFT/profile-nft";
import OnChainNFT from "./pages/NFT/onchain-nft";
import Marketplace from "./pages/NFT/marketplace";
import Videos from "./pages/watch/videos";

import Live from "./pages/watch/live";
import WatchVR from "./pages/watch/watch-vr";
import VRFull from "./pages/VRFull";
import Admin from "./pages/admin18";
import WatchCategories from "./pages/watch/categories";
import Category from "./pages/watch/category";
import Profile from "./pages/Profile/profile";
import Notifications from "./pages/Profile/notifications";

import EditVideo from "./pages/watch/edit-post";
import EditProfile from "./pages/Profile/edit-profile";

import PostVideo from "./pages/watch/post-video";
import PostFanVideo from "./pages/fan/post-fan-video";

import SingleVideo from "./pages/watch/single-video";
import FanVideo from "./pages/fan/fan-video";

import Fans from "./pages/fan/fan-real";
import Creators from "./pages/fan/creators";
import BecomeCreator from "./pages/fan/become-creator";
import PornFace from "./pages/services/porn-face";
import FaceSwap from "./pages/services/face-swap";
import Shop from "./pages/services/shop";
import Frame from "./pages/frame";
import Timeline from "./pages/Timeline/Timeline";
import FreePosts from "./pages/Timeline/Free";
import TrendingPosts from "./pages/Timeline/Trending";

import Inbox from "./pages/Profile/Inbox";
import ScratchGame from "./pages/Game/Scratch";
const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Timeline />} />
            <Route path="contact" element={<Contact />} />
            <Route path="airdrop" element={<ScratchGame />} />
            <Route path="free-posts" element={<FreePosts />} />
            <Route path="trending-posts" element={<TrendingPosts />} />
            <Route path="videos" element={<Videos />} />
            <Route path="live" element={<Live />} />

            <Route path="notifications" element={<Notifications />} />
            <Route path="vr-full" element={<VRFull />} />
            <Route path="watch-categories" element={<WatchCategories />} />
            <Route path="categories" element={<Category />} />
            <Route path="profile" element={<Profile />} />
            <Route path="edit-post" element={<EditVideo />} />
            <Route path="shop" element={<Shop />} />

            <Route path="edit-profile" element={<EditProfile />} />

            <Route path="post-video" element={<PostVideo />} />
            <Route path="post-fan-video" element={<PostFanVideo />} />
            <Route path="single-video" element={<SingleVideo />} />
            <Route path="fan-video" element={<FanVideo />} />
            <Route path="fans" element={<Fans />} />
            <Route path="creators" element={<Creators />} />
            <Route path="become-creator" element={<BecomeCreator />} />
            <Route path="create-nft" element={<CreateNFT />} />
            <Route path="profile-nft" element={<ProfileNFT />} />
            <Route path="onchain-nft" element={<OnChainNFT />} />
            <Route path="marketplace" element={<Marketplace />} />
            <Route path="my-ai-models" element={<PornFace />} />
            <Route path="face-swap" element={<FaceSwap />} />
            <Route path="admin18" element={<Admin />} />
            <Route path="frame" element={<Frame />} />
            <Route path="about" element={<About />} />
            <Route path="terms" element={<TermsAndConditions />} />
            <Route path="timeline" element={<Timeline />} />
            <Route path="chat" element={<Inbox />} />
        </Routes>
    </Router>
);

export default App;
